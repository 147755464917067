"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var _ = require('underscore');
angular.module('backstage.controllers.widgets').controller('TargetsExceptionsTagListCtrl', /* @ngInject */["$scope", "eventService", "metadataService", function ($scope, eventService, metadataService) {
  var eventId = eventService.getCurrentEvent()._id;
  var originalRuleset = [];
  $scope.editingRuleIdx = -1;
  $scope.rulesetMetadata = [];
  $scope.originalRuleset = originalRuleset;
  var metadataForPerson = metadataService.getCachedMetadataForType(eventId, 'person', true);

  // replace field key with `in_app` in dynamic extension fields
  metadataForPerson = metadataService.displaceInAppFieldsAsFieldKeys(metadataForPerson);

  // add fp_ext_id as a field here
  metadataForPerson.fp_ext_id = {
    label: 'Unique Identifier'
  };

  // metadata processing methods

  // the post processing routine will ensure all fields of external kind have the single_doc flag set
  var postProcessMetadataFieldDescriptors = function postProcessMetadataFieldDescriptors(fieldDescriptors) {
    return fieldDescriptors.map(function (descriptor) {
      if (!_.isObject(descriptor)) {
        descriptor = {};
      }
      var kindOptions = _.isObject(descriptor.kind_options) ? descriptor.kind_options : {};
      if (descriptor.kind !== 'external' || kindOptions.single_doc) {
        return descriptor;
      }

      // _.clone is shallow, need to do both hashes
      var descriptorClone = _.clone(descriptor);
      descriptorClone.kind_options = _.clone(kindOptions);
      descriptorClone.kind_options.single_doc = true;
      return descriptorClone;
    });
  };

  // move ruleset into rulesetMetadata
  this.primeRulesetEditorMetadata = function () {
    var _originalRuleset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    // prime ruleset metadata with empty arrays
    $scope.rulesetMetadata.length = 0;
    for (var i = 0; i < _originalRuleset.length; i += 1) {
      $scope.rulesetMetadata.push([]);
    }
    _originalRuleset.forEach(function (ruleObj, ruleIdx) {
      var fields = _.chain(metadataForPerson).pick(_.keys(ruleObj)).tap(metadataService.convertMetadataHashToOrderedList).values().value();
      fields = postProcessMetadataFieldDescriptors(fields);
      return angular.copy(fields, $scope.rulesetMetadata[ruleIdx]);
    });

    // fill the currently displayed metadata
    // ⚑
    // TODO: this stops a metadata reload on every char entry
    var _editingFields = _.pluck($scope.editingFields, 'field');
    var _newMetadata = _.pluck($scope.rulesetMetadata[$scope.editingRuleIdx], 'field');
    if (JSON.stringify(_editingFields) !== JSON.stringify(_newMetadata)) {
      $scope.editingFields = ($scope.rulesetMetadata[$scope.editingRuleIdx] || []).map(function (field, index) {
        return _objectSpread(_objectSpread({}, field), {}, {
          // clone the object because we want to modify the oder
          order: index // order fields by how rules were added
        });
      });
    }
  };

  // manipulation methods

  $scope.addRule = function () {
    originalRuleset.push({});
    return originalRuleset.length - 1;
  };
  $scope.removeRule = function (ruleIdx) {
    if (ruleIdx === -1) {
      return ruleIdx;
    }
    if (!confirm('Are you sure you want to remove this rule?')) {
      return ruleIdx;
    }
    originalRuleset.splice(ruleIdx, 1);
    return -1;
  };
  $scope.hasRuleConditions = function (ruleIdx) {
    var ruleConditions = originalRuleset[ruleIdx];
    return ruleConditions && _.keys(ruleConditions).length > 0;
  };
  $scope.showConditionSummaryOverflowEllipsis = function (conditions) {
    return _.keys(conditions).length > 3;
  };
  $scope.getTagFieldLabelForCondition = function (conditionKey) {
    return (metadataForPerson[conditionKey] ? metadataForPerson[conditionKey].label : undefined) || conditionKey;
  };
  $scope.getTagValueLabelForCondition = function (conditionVal) {
    if (_.isArray(conditionVal)) {
      return '[...]';
    } else {
      return conditionVal ? conditionVal.toString() : undefined;
    }
  };
}]);