/** @module Workspaces */

// Utils
import { convertTimestampToHumanForm, dateIsAfter, dateIsBefore, dateIsBetween, now, tsToFormat, unixToDate } from 'libs/utils/time';
import { localizeLabel } from 'libs/utils/locales';
import { pick, get } from 'lodash';

// Constants
import { DEFAULT_DATETIME_FORMAT, UNKNOWN_DEFAULT_NODE } from 'libs/utils/constants';

/**
 * Given a workspace this method returns its correct title.
 *
 * @param {Object} workspace the workspace to get the title from
 * @param {String} [locale] the locale to use for fallback name
 *
 * @returns {String} the correct workspace name
 */
export function getWorkspaceTitle(workspace, locale) {
    let title = workspace.eventname;

    if (workspace.is_webinar || workspace.is_content_hub) {
        title = workspace.name;
    }

    if (!title) {
        title = localizeLabel(get(workspace, 'description.title'), locale);
    }

    return title;
}

/**
 * Given a workspace this method returns its correct environment or stage.
 *
 * @param {Object} workspace the workspace to get the environment
 *
 * @returns {String} the correct workspace environment/stage
 */
export function getWorkspaceEnvironment(workspace) {
    let environment = workspace.stage;

    if (workspace.is_template) {
        environment = 'template';
    }
    if (workspace.test_event) {
        environment = 'test';
    }
    return environment;
}

/**
 * Builds event role
 *
 * @param {Object} event
 * @param {Object} user
 */
export function buildEventRole(event, user) {
    event.role = ((user.roles || []).find(
        role => role.event_id === event._id
    ) || {}).role;
}

/**
 * Gets the root url of the event nodes
 * Not currently used: could be fully deprecated as we move to using node name instead of URLs
 *
 * @param {Workspace} event The Event object
 *
 * @returns {String} first root node or default
 */
export function getRootNodeUrl(event) {
    const cloudnodes = get(event, 'cloudnodes');

    if (cloudnodes) {
        return cloudnodes[0].instanceurl;
    }

    const instanceurl = get(event, 'instanceurl');
    if (instanceurl) {
        return instanceurl;
    }

    const nodes = get(event, 'nodes');
    const rootNode = Object.values(nodes || {}).find(e => e.is_root);

    if (!rootNode) {
        return UNKNOWN_DEFAULT_NODE;
    }

    return rootNode.url;
}

/**
 * Gets the name of the event root node
 *
 * @param {Workspace} event The Event object
 *
 * @returns {String} root node name
 */
export function getRootNodeName(event) {
    if (event.node) {
        return event.node;
    }

    const nodes = event.nodes || {};
    const rootNodeName = Object.keys(nodes).find(name => nodes[name].is_root);

    return rootNodeName || UNKNOWN_DEFAULT_NODE;
}

/**
 * Normalizes time from description
 *
 * @param {Object} event
 * @param {String} descriptionKey
 * @param {String} eventKey
 *
 * @returns {String}
 */
export function normalizeTimeFromDescription(event, descriptionKey, eventKey) {

    if (event.description && event.description[descriptionKey]) {
        return convertTimestampToHumanForm(
            event.description[descriptionKey],
            event.timezone,
            DEFAULT_DATETIME_FORMAT
        );
    }
    return event[eventKey];
}

/**
 * Extends and transforms the event attributes
 *
 * @param {Object} event
 *
 * @returns {Object}
 */
export function transformEventAttributes(event) {
    if (!event._id && !event.id) {
        return null;
    }
    const eventId = event._id || event.id;

    // normalized attributes
    const name = ((event.description || {}).title || {}).en || event.eventname;
    const stage = (event.lifecycle ? event.lifecycle.stage : undefined) || event.stage;

    return Object.assign(
        pick(
            event,
            '_attachments',
            'fp_attachments_s3',
            'allow_non_unique_email',
            'appDomainSuffix',
            'based_on_template',
            'branded_app',
            'branding_whitelist',
            'category_id',
            'cloudnodes',
            'description',
            'app_activation_from_direct_login',
            'hide_cancelled',
            'entitlements_enabled',
            'entitlements_v2',
            'enable_google_calendar',
            'hasIcon',
            'i_calendar',
            'instanceOf',
            'is_anonymized',
            'anonymization_domains',
            'is_webinar',
            'languages',
            'legal_documents',
            'lifecycle',
            'localnodes',
            'locked',
            'lockedBy',
            'native',
            'networks',
            'nodes',
            'parent_event',
            'participant_access_expiration',
            'role',
            'show_access_as',
            'show_cookie_banner',
            'show_activation_codes',
            'show_payment_section',
            'show_rtmps_slides',
            'status',
            'test_event',
            'timezone',
            'webapp',
            'live_streams',
            'use_shared_player',
            'traits',
            'white_label_domain',
            // Template specific
            'is_template',
            'template_category',
            'template_description',
            'template_long_description',
            'template_order',
            'visibility',
            // Feature flags
            'analytics_v2_enabled',
            'theme_editor_v2_enabled',
            'attendance_tracking',
            'attendance_tracking_enabled',
            'use_editor_for_designs',
            // Info panel
            'created',
            'created_by',
            'mail_provider',
            // Metadata config
            'metadata_fields_limit',
            // Video-call feature flag
            'video_calls_with_acs'
        ),
        // normalized attributes
        {
            _id: eventId,
            id: eventId,
            name,
            eventname: event.eventname || name,
            node: getRootNodeName(event),
            instanceurl: getRootNodeUrl(event),
            is_webinar: event.is_webinar || false,
            stage,
            production: event.production || stage === 'production',
            venue: event.venue || {},
            org: event.org || {},
            startdate: normalizeTimeFromDescription(event, 'start_time', 'startdate'),
            enddate: normalizeTimeFromDescription(event, 'end_time', 'enddate'),
            versionEventDoc: event.fp_version_eventdoc
        },
        // renamed attributes
        {
            ownerId: event.bs_owner_id
        }
    );
}

/**
 * Given a servers list object, this method transforms it
 * into an array suitable for select boxes.
 *
 * @param {object} servers a list of server nodes
 *
 * @returns {object[]} an array of server objects {label: string, value: string}
 */
export function getServersLocation(servers) {
    return Object.keys(servers).map(key => ({
        label: servers[key],
        value: key
    }));
}

/**
 * Given a list of events, this method groups them based on their
 * stage: live, future, past, and latest.
 *
 * @param {object[]} events the list of events to group
 * @param {string[]} [latest] an optional list of last accessed event IDs
 * @param {Date} [start] an optional start Date that overwrites event's start
 * @param {Date} [end] an optional end Date that overwrites event's end
 *
 * @returns {object} the events grouped by stage
 */
export function groupByStage(events, latest, start, end) {
    const today = now();
    const groups = {
        latest: [],
        future: [],
        live: [],
        past: []
    };

    const begining = start || today;
    const ending = end || today;

    for (const event of events) {
        event.readableStartTime = tsToFormat(event.start_time);

        if (event.current_stage) {
            groups[event.current_stage].push(event);
            continue;
        }

        if (event.description) {
            event.start_time = event.description.start_time;
            event.end_time = event.description.end_time;
        }

        const starts = unixToDate(event.start_time);
        const ends = unixToDate(event.end_time);
        const isLive = dateIsAfter(begining, starts) && dateIsBefore(ending, ends);

        if (dateIsAfter(starts, begining)) {
            event.current_stage = 'future';
            groups.future.push(event);

        } else if (isLive) {
            event.current_stage = 'live';
            groups.live.push(event);

        } else if (dateIsBefore(ends, ending)) {
            event.current_stage = 'past';
            groups.past.push(event);
        }

        if (latest && latest.includes(event._id)) {
            groups.latest.push(event);
        }
    }

    groups.all = events;

    // Sort future events ascending
    groups.future.sort((a, b) => a.start_time - b.start_time);

    // Sort live and past events descending
    groups.live.sort((a, b) => b.start_time - a.start_time);
    groups.past.sort((a, b) => b.start_time - a.start_time);

    // Sort latest events as provided by the incoming array
    groups.latest.sort((a, b) => latest.indexOf(a._id) - latest.indexOf(b._id));

    return groups;
}

/**
 * Given an event this method checks if it's in the future
 *
 * @param {Workspace} event the workspace to check
 *
 * @returns {boolean} whether the event is in the future
 */
export function notStarted(event) {
    const start = get(event, 'description.start_time', event.startdate);
    return dateIsBefore(now(), typeof start === 'number' ? unixToDate(start) : event.startdate);
}

/**
 * Given an event this method checks if it's running live
 *
 * @param {Workspace} event the workspace to check
 *
 * @returns {boolean} whether the event is live
 */
export function isLive(event) {
    let start = get(event, 'description.start_time');
    let end = get(event, 'description.end_time');

    start = typeof start === 'number' ? unixToDate(start) : event.startdate;
    end = typeof end === 'number' ? unixToDate(end) : event.enddate;

    return dateIsBetween(now(), start, end, { includeStart: true, includeEnd: true });
}

/**
 * Given an event this method checks if it's over
 *
 * @param {Workspace} event the workspace to check
 *
 * @returns {boolean} whether the event is over
 */
export function isOver(event) {
    let end = get(event, 'description.end_time');
    end = typeof end === 'number' ? unixToDate(end) : event.enddate;
    return dateIsAfter(now(), end);
}

/**
 * Given an event this method returns it's time state based
 * on `now()`.
 *
 * @param {Workspace} event the workspace to check
 *
 * @returns {'past'|'present'|'future'} the state based `now()`
 */
export function workspaceState(event) {
    if (notStarted(event)) {
        return 'future';
    }

    if (isLive(event)) {
        return 'present';
    }

    if (isOver(event)) {
        return 'past';
    }
}
