"use strict";

var _getLeafPaths = function getLeafPaths(obj) {
  return _.chain(obj).map(function (value, key) {
    if (_.isString(value)) return key;
    if (_.isObject(value)) return _getLeafPaths(value).map(function (rest) {
      return "".concat(key, ".").concat(rest);
    });
  }).flatten().value();
};

// define how report results are handled
var reportProcessors = {
  data: function data(_ref) {
    var _ref$stats = _ref.stats,
      stats = _ref$stats === void 0 ? {} : _ref$stats,
      fpType = _ref.fpType,
      docs = _ref.docs;
    return {
      type: 'data',
      category: fpType,
      overrides: false,
      stats: stats,
      changes: docs
    };
  },
  assets: function assets(_ref2) {
    var files = _ref2.files,
      data = _ref2.data,
      fpType = _ref2.fpType;
    var changes = files ? files : data.successes;
    var created = changes.length;
    return {
      type: 'asset',
      category: fpType,
      overrides: false,
      stats: {
        created: created,
        updated: 0
      },
      changes: changes
    };
  },
  bits: function bits(_ref3) {
    var type = _ref3.type,
      changes = _ref3.changes,
      data = _ref3.data;
    if (type === 'nav') {
      return {
        category: 'App configuration',
        overrides: true,
        changes: changes,
        stats: {
          changed: changes.length
        }
      };
    } else if (type === 'settings') {
      changes = Object.keys(data);
      return {
        category: 'Preferences',
        overrides: true,
        stats: {
          changed: changes.length
        },
        changes: changes
      };
    } else if (type === 'metadata') {
      return {
        category: 'Metadata',
        overrides: true,
        changes: changes,
        stats: {
          changed: changes.length
        }
      };
    } else if (type === 'fstg-pages') {
      changes = Object.keys(data).reduce(function (memo, key) {
        if (data[key]) {
          memo.push(data[key].name || key);
        }
        return memo;
      }, []);
      return {
        category: 'Display templates (Frontstage pages)',
        overrides: true,
        stats: {
          changed: changes.length
        },
        changes: changes
      };
    } else if (type === 'theme') {
      return {
        category: 'Theme (without event icon)',
        overrides: true,
        changes: changes,
        stats: {
          changed: changes.length
        }
      };
    } else if (type === 'i18n') {
      // data is compiled i18n document
      changes = _getLeafPaths(data);
      return {
        category: 'Translations',
        overrides: true,
        stats: {
          changed: changes.length
        },
        changes: changes
      };
    } else {
      console.log("[EventCopy] Type ".concat(type, " is not implemented"));
    }
  },
  custom: function custom(_ref4) {
    var exporter = _ref4.exporter,
      stats = _ref4.stats,
      changes = _ref4.changes;
    return {
      category: exporter.category,
      overrides: exporter.overrides,
      stats: stats,
      changes: changes
    };
  }
};
var EventCopyService = /* @ngInject */function EventCopyService($q, eventService) {
  var eventCopyService = {
    /**
     * Retrieve and compile a report of a copy
     *
     * @param {Object} event
     * @param {String} reportId
     * @return {Promise} resolves with the report
     */
    getReport: function getReport(event, reportId) {
      return eventService.getEventDocById(event, reportId).catch(function (err) {
        // read old report which are not returned properly by cradle
        if (err && err.data && err.data.error && err.data.error.resultDocument) {
          return {
            data: err.data.error
          };
        }
        // otherwise continue
        return $q.reject(err);
      }).then(function (response) {
        var _data = (response || {}).data,
          _id = _data._id,
          options = _data.options,
          state = _data.state,
          error = _data.error,
          copyError = _data.copyError,
          results = _data.results;
        var dataChanges = [];

        // handle error state
        if (state !== 'complete') {
          return {
            _id: _id,
            options: options,
            state: state,
            error: copyError || error
          };
        }
        if (state === 'complete') {
          (results || []).forEach(function (_ref5) {
            var type = _ref5.type,
              _ref5$results = _ref5.results,
              results = _ref5$results === void 0 ? [] : _ref5$results;
            var processor = reportProcessors[type];
            if (processor) {
              results.forEach(function (result) {
                return dataChanges.push(processor(result));
              });
            }
          });
        }
        return {
          options: options,
          state: state,
          changes: _.chain(dataChanges).compact().each(function (_ref6) {
            var stats = _ref6.stats;
            return stats.changed = stats.changed || (stats.created || 0) + (stats.updated || 0);
          }).filter(function (_ref7) {
            var stats = _ref7.stats;
            return stats.changed > 0;
          }).sortBy(function (_ref8) {
            var changed = _ref8.stats.changed;
            return -changed;
          }).value()
        };
      });
    },
    /**
     * Retrieve a list of cloning report
     * @param {Object} event
     * @param {Boolean} imports if true, retrieve report of imports
     * @return {Promise} result with reports
     */
    getReports: function getReports(event) {
      var imports = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      return eventService.getEventDocsByType(event, imports ? 'event_cloning_import' : 'event_cloning_report').then(function (_ref9) {
        var data = _ref9.data;
        // cleanup to align different type of reports, and add missing values
        var reports = _.chain(data || []).map(function (_ref10) {
          var _ref10$state = _ref10.state,
            state = _ref10$state === void 0 ? 'failed' : _ref10$state,
            startedAt = _ref10.startedAt,
            endedAt = _ref10.endedAt,
            user = _ref10.user,
            targetEvent = _ref10.targetEvent,
            sourceEvent = _ref10.sourceEvent,
            resultDocument = _ref10.resultDocument,
            _ref10$options = _ref10.options,
            options = _ref10$options === void 0 ? {} : _ref10$options;
          return {
            state: state,
            duration: endedAt && startedAt ? endedAt - startedAt : 0,
            startedAt: startedAt,
            endedAt: endedAt,
            resultDocument: resultDocument,
            // they have different format for imports (just a simple summary) and exports
            user: user || options.user,
            targetEvent: targetEvent || options.targetEvent,
            sourceEvent: sourceEvent || options.sourceEvent
          };
        }).sortBy(function (_ref11) {
          var startedAt = _ref11.startedAt;
          return -startedAt;
        }).value();
        return reports;
      });
    }
  };
  return eventCopyService;
};
EventCopyService.$inject = ["$q", "eventService"];
EventCopyService.$inject = ["$q", "eventService"];
angular.module('backstage.services').factory('eventCopyService', EventCopyService);