import { getBrowserTimezone } from 'libs/utils/time';
import BaseService from '../base-service';

// Utils
import { isArray } from 'lodash';
import { getBackstageURL } from 'libs/utils/url';
import { groupByStage } from 'libs/utils/workspaces';

// Constants
import { API_BASE_PATH, WEP_APP_LOGIN_URL_ENDPOINT, ASSET_DOWNLOAD_URL } from 'libs/utils/constants';

/**
 * The key under which we save the last accessed content hubs in cookie/localstorage
 * @constant {string} LAST_ACCESSED_CONTENT_HUBS_KEY
 * @private
 */
const LAST_ACCESSED_CONTENT_HUBS_KEY = 'last_accessed_content_hubs';

/**
 * How many content hubs can be saved in the last accessed key
 * @constant {number} LAST_ACCESSED_CONTENT_HUBS_SAVED_COUNT
 * @private
 */
const LAST_ACCESSED_CONTENT_HUBS_SAVED_COUNT = 20;

/**
 * Content hubs API endpoint
 * @constant {string} CONTENT_HUB_CREATION_ENDPOINT
 * @private
 */
const CONTENT_HUB_CREATION_ENDPOINT = `${API_BASE_PATH}/orgs/{{orgName}}/content-hubs`;

/**
 * Content hubs API endpoint
 * @constant {string} CONTENT_HUB_ENDPOINT
 * @private
 */
const CONTENT_HUB_ENDPOINT = `${API_BASE_PATH}/content-hubs/{{contentHubId}}`;

/**
 * Content hubs API endpoint for uploading assets
 * @constant {string} CONTENT_HUB_ASSETS_ENDPOINT
 * @private
 */
const CONTENT_HUB_ASSETS_ENDPOINT = `${CONTENT_HUB_ENDPOINT}/assets/{{assetName}}`;

/**
 * Content hub API endpoint to save the theme
 * @constant {string} CONTENT_HUB_THEME_ENDPOINT
 * @private
 */
const CONTENT_HUB_THEME_ENDPOINT = `${CONTENT_HUB_ENDPOINT}/theme`;

/**
 * Content hub workspaces API endpoint
 * @constant {string} CONTENT_HUB_WORKSPACES_ENDPOINT
 * @private
 */
const CONTENT_HUB_WORKSPACES_ENDPOINT = `${CONTENT_HUB_ENDPOINT}/workspaces`;

/**
 * Content hub webinars API endpoint
 * @constant {string} CONTENT_HUB_WEBINARS_ENDPOINT
 * @private
 */
const CONTENT_HUB_WEBINARS_ENDPOINT = `${CONTENT_HUB_ENDPOINT}/webinars`;

/**
 * Content hub analytics export API endpoint
 * @constant {string} CONTENT_HUB_ANALYTICS_EXPORTS_ENDPOINT
 * @private
 */
const CONTENT_HUB_ANALYTICS_EXPORTS_ENDPOINT = `${CONTENT_HUB_ENDPOINT}/analytics/export`;

/**
 * Content hub analytics export job API endpoint
 * @constant {string} CONTENT_HUB_ANALYTICS_EXPORT_ENDPOINT
 * @private
 */
const CONTENT_HUB_ANALYTICS_EXPORT_ENDPOINT = `${CONTENT_HUB_ANALYTICS_EXPORTS_ENDPOINT}/{{jobId}}`;

/**
 * Content hub analytics export progress API endpoint
 * @constant {string} CONTENT_HUB_ANALYTICS_EXPORT_PROGRESS_ENDPOINT
 * @private
 */
const CONTENT_HUB_ANALYTICS_EXPORT_PROGRESS_ENDPOINT = `${CONTENT_HUB_ANALYTICS_EXPORT_ENDPOINT}/progress`;

/**
 * Content hub analytics start date API endpoint
 * @constant {string} CONTENT_HUB_ANALYTICS_EXPORT_START_DATE_ENDPOINT
 * @private
 */
const CONTENT_HUB_ANALYTICS_EXPORT_START_DATE_ENDPOINT = `${CONTENT_HUB_ENDPOINT}/analytics/start-date`;

/**
 * The endpoint on which we sync the specified event's users
 * @constant {string} CONTENT_HUB_SYC_USERS
 * @private
 */
const CONTENT_HUB_SYC_USERS = `${CONTENT_HUB_ENDPOINT}/sync-users`;

/**
 * Provide service related to Content Hubs
 *
 * @example
 * import ContentHubService from 'libs/services/content-hub/content-hub';
 * ...
 * const contentHubService = new ContentHubService();
 */
export default class ContentHubService extends BaseService {

    /**
     * Gets the content hub list of the current user.
     *
     * @param {Array} [latestIds=[]] Ids in the local storage
     *
     * @returns {Promise<{latest:Object[];all:Object[]}>} An object with all the content-hubs for the user + the latest ones in a separate array.
     */
    async getUserContentHubs(latestIds = []) {
        const { data } = await this.get(this.buildUrl(CONTENT_HUB_ENDPOINT, { contentHubId: '' }));
        const latest = data
            .filter(contentHub => latestIds.includes(contentHub.id))
            .sort((a, b) => latestIds.indexOf(a.id) - latestIds.indexOf(b.id));
        return {
            latest,
            all: data
        };
    }

    /**
     * Adds a contenthub to the list of latest accessed content hubs
     * @param {string} contentHubId the ID of the content hub to add
     * @param {import('../storage').default} storage the storage service
     */
    addToLastAccessed(contentHubId, storage) {
        let storageContentHubs = storage.get(LAST_ACCESSED_CONTENT_HUBS_KEY);
        if (!isArray(storageContentHubs)) {
            storageContentHubs = [];
        }

        if (storageContentHubs.includes(contentHubId)) {
            storageContentHubs.splice(storageContentHubs.indexOf(contentHubId), 1);
        }
        storageContentHubs.unshift(contentHubId);

        if (storageContentHubs.length > LAST_ACCESSED_CONTENT_HUBS_SAVED_COUNT) {
            const elementsToDelete = storageContentHubs.length - LAST_ACCESSED_CONTENT_HUBS_SAVED_COUNT;
            storageContentHubs.splice(-elementsToDelete, elementsToDelete);
        }
        storage.set(LAST_ACCESSED_CONTENT_HUBS_KEY, storageContentHubs);
    }

    /**
     * Gets the list of user's last accessed content hubs
     *
     * @param {object} storage interface for accessing local storage
     *
     * @returns {Array} content hubs
     */
    getLastAccessed(storage) {
        let storageContentHubs = storage.get(LAST_ACCESSED_CONTENT_HUBS_KEY);

        if (!isArray(storageContentHubs)) {
            storageContentHubs = [];
        }

        return storageContentHubs;
    }

    /**
     * Creates a new content hub
     *
     * @param {string} orgName the name of the organization to create the content hub for
     * @param {ContentHub} data the content hub creation payload
     *
     * @returns {Promise<import('axios').AxiosResponse>} the result of the content hub creation
     */
    create(orgName, data) {
        const url = this.buildUrl(CONTENT_HUB_CREATION_ENDPOINT, { orgName });

        return this.post(url, data);
    }

    /**
     * Given a content hub ID, this method returns its URL
     *
     * @param {string} id the ID of the content hub
     * @param {string} node content hub node
     * @param {string} [path=''] the optional path to go
     *
     * @returns {string} the content hub URL
     */
    getUrl(id, node, path = '') {
        return getBackstageURL(node, `/content-hub/${id}${path}`);
    }

    /**
     * Gets the url to download an asset
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} docId id of the source doc
     * @param {string} fileName the name of the asset
     *
     * @returns {string} the download url
     */
    getDownloadUrl(eventId, docId, fileName) {
        return this.buildUrl(ASSET_DOWNLOAD_URL, { eventId, docId, fileName });
    }

    /**
     * Load all the data for one content hub
     * @param {string} contentHubId the ID of the content hub
     * @returns {Promise<ContentHub>}
     */
    async getContentHub(contentHubId) {
        const url = this.buildUrl(CONTENT_HUB_ENDPOINT, { contentHubId });
        const { data } = await this.get(url);
        return data;
    }

    /**
     * Update a content-hub
     * @param {string} contentHubId the ID of the content hub
     * @param {Object} targetData
     * @returns {Promise<ContentHub>}
     */
    async update(contentHubId, targetData) {
        const url = this.buildUrl(CONTENT_HUB_ENDPOINT, { contentHubId });

        const { data } = await this.put(url, targetData);
        return data;
    }

    /**
     * Uploads a single asset
     *
     * @param {string} contentHubId the ID of the content hub
     * @param {File} file the file to upload
     * @param {string} assetName the name of the asset
     *
     * @returns {Promise<import('axios').AxiosResponse>} the server response
     */
    async uploadAsset(contentHubId, file, assetName) {
        const url = this.buildUrl(CONTENT_HUB_ASSETS_ENDPOINT, { contentHubId, assetName });
        const bodyFormData = new FormData();
        const token = await this.getCsrfToken();

        bodyFormData.set('file', file);
        bodyFormData.set('_csrf', token);

        return this.put(url, bodyFormData, { withCredentials: true });
    }

    /**
     * Deletes an asset
     *
     * @param {string} contentHubId
     * @param {string} assetName
     *
     * @returns {Promise<import('axios').AxiosResponse>}
     */
    async deleteAsset(contentHubId, assetName) {
        const url = this.buildUrl(CONTENT_HUB_ASSETS_ENDPOINT, { contentHubId, assetName });

        return this.delete(url);
    }

    /**
     * Returns the path of the requested asset
     *
     * @param {string} contentHubId the ID of the content hub
     * @param {string} assetName the name of the asset
     *
     * @returns {string} the path of the desired asset
     */
    getAssetPath(contentHubId, assetName) {
        return this.buildUrl(CONTENT_HUB_ASSETS_ENDPOINT, { contentHubId, assetName });
    }

    /**
     * Gets the content hub theme
     *
     * @param {string} contentHubId the ID of the content hub
     *
     * @returns {Promise<ContentHubTheme>} the content hub theme object
     */
    async getTheme(contentHubId) {
        const { data } = await this.get(this.buildUrl(CONTENT_HUB_THEME_ENDPOINT, { contentHubId }));
        return data;
    }

    /**
     * Updates the content hub theme
     *
     * @param {string} contentHubId the ID of the content hub
     * @param {ContentHubTheme} data the theme model
     *
     * @returns {Promise<import('axios').AxiosResponse>} the server response
     */
    saveTheme(contentHubId, data) {
        const url = this.buildUrl(CONTENT_HUB_THEME_ENDPOINT, { contentHubId });
        return this.put(url, data);
    }

    /**
     * Get workspaces connected to the content hub
     *
     * @param {string} contentHubId the ID of the content hub
     *
     * @returns {Promise<import('axios').AxiosResponse>} the server response
     */
    async getGroupedWorkspaces(contentHubId) {
        const url = this.buildUrl(CONTENT_HUB_WORKSPACES_ENDPOINT, { contentHubId });
        const { data } = await this.get(url);
        return groupByStage(data);
    }

    /**
     * Get webinars connected to the content hub
     *
     * @param {string} contentHubId the ID of the content hub
     *
     * @returns {Promise<import('axios').AxiosResponse>} the server response
     */
    async getGroupedWebinars(contentHubId) {
        const url = this.buildUrl(CONTENT_HUB_WEBINARS_ENDPOINT, { contentHubId });
        const { data } = await this.get(url);
        return groupByStage(data);
    }

    async getLoginUrl(contentHubId) {
        const url = this.buildUrl(WEP_APP_LOGIN_URL_ENDPOINT, { eventId: contentHubId });
        const { data } = await this.post(url);
        return data.url;
    }

    /**
     * Fetch the start date for the analytics
     * @param {String} contentHubId
     * @returns {Promise<Number>} Timestamp of the start date.
     */
    async getAnalyticsStartDate(contentHubId) {
        const url = this.buildUrl(CONTENT_HUB_ANALYTICS_EXPORT_START_DATE_ENDPOINT, { contentHubId });
        const { data } = await this.get(url);
        return data.start_date;
    }

    /**
     * Start an analytic job export
     * @param {Number} contentHubId
     * @param {Object} payload
     * @param {String} payload.start
     * @param {String} payload.end
     * @returns {Promise<Object>}
     */
    async startAnalytics(contentHubId, payload) {
        const url = this.buildUrl(CONTENT_HUB_ANALYTICS_EXPORTS_ENDPOINT, { contentHubId });
        const timezone = getBrowserTimezone();
        const { data } = await this.post(url, { ...payload, timezone });
        return {
            jobId: data.analyticsExportId,
            resultPath: this.buildUrl(CONTENT_HUB_ANALYTICS_EXPORT_PROGRESS_ENDPOINT, { contentHubId }),
            statusPath: this.buildUrl(CONTENT_HUB_ANALYTICS_EXPORT_PROGRESS_ENDPOINT, { contentHubId })
        };
    }

    /**
     * Return the urls to download an analytic export
     * @param {String} contentHubId
     * @param {String} jobId
     * @returns {String}
     */
    downloadAnalyticsExport(contentHubId, jobId) {
        return this.buildUrl(CONTENT_HUB_ANALYTICS_EXPORT_ENDPOINT, { contentHubId, jobId });
    }

    getPublishedFromFilter(filterValue) {
        switch (filterValue) {
            case 'published':
                return true;
            case 'unpublished':
                return false;
            default:
                return undefined;
        }
    }

    /**
     * Starts the users sync of the given event ID
     *
     * @param {string} contentHubId the ID of the content hub
     * @param {string} eid the ID of the event to sync the users from
     *
     * @returns {Promise<Object>} the server response
     */
    async syncEventUsers(contentHubId, eid) {
        const url = this.buildUrl(CONTENT_HUB_SYC_USERS, { contentHubId });
        const { data } = await this.post(url, { eid });
        return data;
    }
}
