// Utils
import ServicePrototype from './service-prototype';

// Constants

/**
 * @constant {string} UPLOAD_MEDIA_ENDPOINT The upload endpoint. Interpolation {{eventId}}.
 */
const GET_UPLOAD_MEDIA_ENDPOINT = '/api/v1/eid/{{eventId}}/contents/get-upload-url';

/**
 * @constant {string} GET_UPLOAD_FP_TYPE_ENDPOINT The upload endpoint. Interpolation {{eventId}}, {{fpType}}.
 */
const GET_UPLOAD_FP_TYPE_ENDPOINT = '/api/v1/eid/{{eventId}}/contents/{{fpType}}/get-upload-url';

/**
 * @constant {string} UPDATE_CONTENT_ENDPOINT The update content endpoint. Interpolations: {{eventId}}, {{contentId}}
 */
const UPDATE_CONTENT_ENDPOINT = '/api/v1/eid/{{eventId}}/contents/{{contentId}}';

/**
 * @constant {Number} MEDIA_MAX_FILE_SIZE_IN_BYTES The maximum size in bytes of any media uploaded
 */
export const MEDIA_MAX_FILE_SIZE_IN_BYTES = 4 * Math.pow(8, 10);

/**
 * Provides methods necessary for handling media files
 *
 * @example
 *
 * import MediaService from 'libs/services/media';
 * ...
 * const media = new MediaService();
 */
export default class MediaService extends ServicePrototype {

    /**
     * Given an event ID and a file this method provides
     * an upload URL to the CDN.
     *
     * @param {string} eventId the ID of the event
     * @param {File} file the file to upload
     * @param {object} [params] an optional object containing extra parameters to send to the BE
     * @param {string} [params.fpType] an optional type that will be used to create the URL
     * @param {string} [params.id] if passed, this will be the ID of the uploaded asset's document
     *
     * @returns {Promise<Object>}
     */
    async getUploadDocument(eventId, file, params) {
        if (params && params.fpType) {
            const fpType = params.fpType;
            delete params.fpType;
            return this.getFpTypeUploadDocument(eventId, fpType, file, params);
        }

        const url = this.buildUrl(GET_UPLOAD_MEDIA_ENDPOINT, { eventId });
        const payload = {
            fileName: file.name,
            contentType: file.type
        };

        if (typeof params === 'object') {
            Object.assign(payload, params);
        }

        const { data } = await this.post(url, payload);
        return data;
    }

    /**
     * Given an event ID, a known fp_type, and a file, this method
     * provides a cloud storage upload URL.
     *
     * @param {string} eventId the ID of the event
     * @param {File} file the file to upload
     * @param {object} [params] an optional object containing extra parameters to send to the BE
     * @param {string} [params.id] the desired ID for the created asset's document
     *
     * @returns {Promise<{id: string, upload_url: string}>}
     */
    async getFpTypeUploadDocument(eventId, fpType, file, params) {
        const url = this.buildUrl(GET_UPLOAD_FP_TYPE_ENDPOINT, { eventId, fpType });
        const payload = {
            fileName: file.name,
            contentType: file.type
        };

        if (typeof params === 'object') {
            Object.assign(payload, params);
        }

        const { data } = await this.post(url, payload);
        return data;
    }

    /**
     * Uploads the file on the BE bucket
     *
     * @param {string} uploadUrl the URL to perform the upload against
     * @param {File} file the file to upload
     * @param {AbortSignal} signal the abort signal to interrupt the upload
     * @param {Function} onUploadProgress a function that is called on upload progress
     *
     * @returns {Promise<import('axios').AxiosResponse>} the remote server response
     */
    upload(uploadUrl, file, signal, onUploadProgress) {
        return this.put(uploadUrl, file, {
            onUploadProgress,
            signal,
            withCredentials: false,
            headers: {
                'Content-Type': file.type
            }
        });
    }

    /**
     * Marks the upload document for successful uploaded file
     *
     * @param {string} eventId the ID of the event
     * @param {string} contentId the ID of the content document to update
     *
     * @returns {Promise<boolean>} whether the update succeeds or not
     */
    async markAsUploaded(eventId, contentId) {
        const url = this.buildUrl(UPDATE_CONTENT_ENDPOINT, { eventId, contentId });

        const { data } = await this.put(url, { upload_finished: true });
        return data.length && data[0].id === contentId;
    }
}
