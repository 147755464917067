"use strict";

var _underscore = require("underscore");
var _tinycolor = _interopRequireDefault(require("tinycolor2"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
module.exports = /* @ngInject */["$scope", "$routeParams", "eventService", "modalService", "themeService", "userService", function ($scope, $routeParams, eventService, modalService, themeService, userService) {
  var eventId = $routeParams.eventId;
  var event = eventService.decorateScope(eventId);
  if (event.theme_editor_v2_enabled) {
    $scope.themeEditorV2 = true;
    $scope.event = event;
    $scope.user = userService.getCurrentUser();
    return;
  }
  var themeDoc = {};
  $scope.eventId = eventId;
  $scope.assetPath = "/api/v1/events/".concat(eventId, "/assets");

  // for undo manager
  var ignoreNextThemeChange = true;

  // TODO in error handler, check if document is missing or it was something else
  // if it was something else then the theme editor should be disabled
  var getEventTheme = function getEventTheme() {
    return themeService.getEventTheme(eventId, event.node).then(function (theme) {
      themeDoc = theme;
      $scope.theme = themeService.compactTheme(themeDoc);
      $scope.themeLoaded = true;
    });
  };
  var refreshTheme = function refreshTheme() {
    return themeService.compileTheme(eventId, event.node).then(getEventTheme);
  };
  $scope.refreshTheme = refreshTheme;

  // / Save ///
  $scope.hasThemeChanged = function () {
    return true;
  };
  $scope.saving = false;
  $scope.save = function () {
    if ($scope.themeForm && $scope.themeForm.$invalid) {
      return;
    }
    ignoreNextThemeChange = $scope.saving = true;
    themeService.saveThemeBit(eventId, event.node, $scope.theme).then(function () {
      return $scope.setFlashMessage('Theme has been saved.');
    }, function () {
      return modalService.openAlert({
        title: 'Error',
        message: 'There was an error while saving your changes. Please try again.'
      });
    }).then(getEventTheme).finally(function () {
      return $scope.saving = false;
    });
  };

  // / Importer integration ///
  $scope.openImporter = function () {
    modalService.openModal({
      templateUrl: '/static/partials/theme-editor/theme-editor-importer.html',
      scope: {}
    }).then(function () {
      return $scope.setFlashMessage('Import complete.');
    }).finally(refreshTheme);
  };

  // / Theme wizard integration ///
  $scope.showWizardBanner = false;
  $scope.navigateToThemeWizard = function (confirmNavigation) {
    if (confirmNavigation === true) {
      return modalService.openAlert({
        title: 'Warning',
        message: 'Saving a theme from the wizard will overwrite your current theme.',
        okLabel: 'Continue',
        cancelLabel: 'Cancel'
      }).then($scope.navigateToThemeWizard);
    }
    $scope.navigate("/event/".concat(eventId, "/modules/theme-editor/wizard"));
  };
  themeService.getThemeBit(eventId, event.node).then(function (bit) {
    return $scope.showWizardBanner = (0, _underscore.size)(bit) === 0;
  });

  // / Advanced mode
  $scope.toggleAdvanced = function () {
    return $scope.showAdvanced = !$scope.showAdvanced;
  };

  // / Linked properties & overrides ///
  // primary color
  $scope.$watch('theme.toolbar.backgroundColor', function (newValue, oldValue) {
    if (!newValue) return;
    $scope.primaryColor = newValue;
    $scope.theme.primaryColor = newValue;
    var theme = $scope.theme;
    if (theme.mainTabBar.activeColor === oldValue) {
      theme.mainTabBar.activeColor = newValue;
    }
  });

  // Count badge
  $scope.$watch('theme.spotman.view', function (newValue, prevValue) {
    if (!$scope.theme || !newValue || !prevValue) return;
    var badgeTheme = $scope.theme.countBadge;
    if (prevValue.backgroundColor === badgeTheme.color && prevValue.color === badgeTheme.backgroundColor) {
      badgeTheme.color = newValue.backgroundColor;
      badgeTheme.backgroundColor = newValue.color;
    }
  }, true);
  $scope.$watch('theme.countBadge', function (value) {
    if (!$scope.theme) return;
    var spotmanTheme = $scope.theme.spotman.view;
    $scope.countBadgeIsLinked = spotmanTheme.backgroundColor === value.color && spotmanTheme.color === value.backgroundColor;
  }, true);
  $scope.resetCountBadge = function () {
    var spotmanTheme = $scope.theme.spotman.view;
    var badgeTheme = $scope.theme.countBadge;
    badgeTheme.color = spotmanTheme.backgroundColor;
    badgeTheme.backgroundColor = spotmanTheme.color;
  };

  // Main menu
  $scope.$watch('theme.spotman.view', function (newValue, prevValue) {
    if (!$scope.theme || !newValue || !prevValue) return;
    var view = $scope.theme.spotman.view;
    var selectedTheme = view.selected;
    if (newValue.backgroundColor !== prevValue.backgroundColor) {
      var bgIsDark = (0, _tinycolor.default)(newValue.backgroundColor).getBrightness() < 180;
      selectedTheme.backgroundColor = bgIsDark ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.3)';
    }
    if (newValue.color !== prevValue.color) {
      selectedTheme.color = (0, _tinycolor.default)(newValue.color).setAlpha(0.7).toRgbString();
    }
    if (newValue.iconsColorEnabled !== prevValue.iconsColorEnabled) {
      selectedTheme.iconsColorEnabled = newValue.iconsColorEnabled;
    }
    view.iconsColor = newValue.iconsColor;
    if (!newValue.iconsColorEnabled) {
      view.iconsColor = prevValue.color;
    }
    var profileTheme = $scope.theme.spotman.profile;
    if (prevValue.backgroundColor === profileTheme.backgroundColor && prevValue.color === profileTheme.title.color) {
      profileTheme.backgroundColor = newValue.backgroundColor;
      profileTheme.title.color = newValue.color;
    }
  }, true);
  $scope.$watch('theme.spotman.alt_view', function (newValue, prevValue) {
    if (!$scope.theme || !newValue || !prevValue) return;
    var profileTheme = $scope.theme.spotman.profile;
    if (prevValue.color === profileTheme.subtitle.color) {
      profileTheme.subtitle.color = newValue.color;
    }
  }, true);
  // Tab bar
  $scope.$watch('theme.mainTabBar.activeColor', function (newValue) {
    if (!newValue) return;
    $scope.mainTabBarIsLinked = newValue === $scope.primaryColor;
  });
  $scope.resetMainTabBar = function () {
    $scope.theme.mainTabBar.activeColor = $scope.primaryColor;
  };

  // / Assets uploader integration
  // (this controller is also used in the assets uploader)
  var previewHeights = {
    phone: 350,
    tablet: 355
  };
  $scope.loadingViewStyle = function (type) {
    if (!$scope.theme) return;
    var theme = $scope.theme.splashscreen;
    var backgroundColor = (0, _tinycolor.default)(theme.backgroundColor).setAlpha(theme.backgroundColorOpacity / 100).toRgbString();
    var marginTop = previewHeights[type] * theme.margin[type] / 100 + 'px';
    return {
      backgroundColor: backgroundColor,
      marginTop: marginTop
    };
  };

  // / Undo/redo
  var history = [];
  var currentRev = 0;
  $scope.$watch('theme', function (value) {
    if (ignoreNextThemeChange) {
      ignoreNextThemeChange = false;
      return;
    }
    if (!value) return;

    // making a change while in a previous revison forks the history
    if (currentRev !== history.length - 1) {
      history = history.slice(0, currentRev);
    }
    history.push(angular.copy(value));
    currentRev = history.length - 1;
  }, true);
  $scope.undo = function () {
    ignoreNextThemeChange = true;
    $scope.theme = history[--currentRev];
  };
  $scope.redo = function () {
    ignoreNextThemeChange = true;
    $scope.theme = history[++currentRev];
  };
  $scope.canUndo = function () {
    return currentRev !== 0;
  };
  $scope.canRedo = function () {
    return history[currentRev + 1] !== undefined;
  };

  // / init ///
  refreshTheme();
}];