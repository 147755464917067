// Utils
import { clone, get, groupBy, isNil, isObject, pickBy, uniqueId } from 'lodash';
import { humanize } from 'libs/utils/string';
import * as decorators from 'libs/utils/helpers/control-decorators';

// Constants
const GENERATED_DEFAULT_VALUE_PLACEHOLDER = '{{bstg:random}}';

/**
 * @typedef {Object} ControlConfig
 * @property {boolean} noTip whether to show the tooltip
 * @property {boolean} noLabel whether to show the label
 * @property {object} $i18n the i18n framework
 * @property {*} value the current control value
 * @property {string} [context] the context of the control
 * @property {object} [event] the workspace's object
 * @property {object} [parentModel] an optional model to pass to the control
 * @property {object} [settings] the settings of the workspace
 */

/**
 * @typedef {Object} ParsedServerError
 * @property {string} detail the error message
 * @property {string} [field] the field that caused the error
 * @property {string} [idField] the field that identifies the ID of the object
 * @property {string} [id] the id of the object that caused the error
 */

/**
 * Sorts the given fields by orders
 *
 * @param {FieldDescriptor[]} fields the fields to sort
 * @param {string} defaultKey the default category
 * @param {boolean} editing if we're in edition mode
 *
 * @returns {Record<string,FieldDescriptor[]>} the grouped and sorted matrix
 */
export function sortAndGroupFields(fields, defaultKey, editing = false) {
    if (!editing) {
        fields = fields.filter(f => !f.hidden);
    }

    let i = Math.max(...fields.filter(f => f.order).map(f => f.order));
    fields.forEach(f => f.order = Number.isFinite(f.order) ? f.order : Number.parseFloat(f.order || ++i));
    fields.sort((a, b) => a.order - b.order);

    const categories = groupBy(fields, c => {
        if (c.no_category) return uniqueId('_no_category_');
        return c.category || defaultKey;
    });

    const sortedCat = Object.keys(categories)
        // Sort by field order
        .sort((a, b) => {
            const a1 = categories[a][0].hasOwnProperty('order') ? categories[a][0].order : Infinity;
            const b1 = categories[b][0].hasOwnProperty('order') ? categories[b][0].order : Infinity;

            return a1 - b1;
        });

    const fieldsets = {};
    for (const cat of sortedCat) {
        const groups = groupBy(categories[cat], g => g.group);
        delete groups.undefined;

        for (const [groupName, groupFields] of Object.entries(groups)) {
            const replacementIndex = categories[cat].findIndex(f => f.field === groupFields[0].field && f.group === groupName);
            if (Number.isFinite(replacementIndex)) {
                categories[cat][replacementIndex] = groups[groupName];
            }
        }

        categories[cat] = categories[cat].filter(f => !f.group);
        fieldsets[cat] = categories[cat];
    }

    return fieldsets;
}

/**
 * Filters fields by the given placement
 *
 * @param {FieldDescriptor[]} fields a list of field descriptors
 * @param {string} placement the position by which filtering the fields with
 *
 * @return {object[]} a new list of fields filtered by placement
 */
export function fieldsByPlacement(fields = [], placement = 'left') {
    return fields.filter(field => getFieldPlacement(field) === placement);
}

/**
 * Given a field descriptor this method determines the place of the input in the form
 *
 * @param {object} field the field descriptor
 * @param {'right'|'left'|undefined} [field.placement] the side field placement in the form
 * @param {string} field.kind the type of the field
 *
 * @return {'right'|'left'|string} the field placement
 */
export function getFieldPlacement(field) {
    if (field.placement) {
        return field.placement;
    } else if (['external', 'targets-exceptions'].includes(field.kind)) {
        return 'right';
    } else {
        return 'left';
    }
}

/**
 * Builds the attributes for a control
 *
 * @param {FieldDescriptor} control the control to build the attributes for
 * @param {ControlConfig} config the control's configuration
 * @param {FieldDescriptor} [overwrites = {}] extra attributes to add to the control
 *
 * @returns {object} the attributes for the control
 */
export function getControlAttributes(control, config, overwrites = {}) {
    // Apply defaults
    const attrs = {
        name: control.field,
        disabled: control.disabled,
        placeholder: control.placeholder || control.example,
        hasDefault: control.hasOwnProperty('default'),
        legacy: control,
        hint: config.$i18n.te(control.hint) ? config.$i18n.t(control.hint) : control.hint,
        hintAfterLabel: config.hintAfterLabel
    };

    if (!config.noTip) {
        attrs.tip = config.$i18n.te(control.tooltip) ? config.$i18n.t(control.tooltip) : control.tooltip;
    }

    if (!config.noLabel) {
        attrs.label = control.label || humanize(control.field, true);
        attrs.label = config.$i18n.te(attrs.label) ? config.$i18n.t(attrs.label) : attrs.label;

        if (get(control, 'validations.required')) {
            attrs.label = `${attrs.label} *`;
        }
    }

    // Decoration
    decorators.getKindSpecificAttributes(control, config, attrs);
    decorators.getKindOptionsAttributes(control, config, attrs);

    if (control.depends_on) {
        for (const field of Object.keys(control.depends_on)) {
            attrs.dependant = control.depends_on[field]?.includes('indent');
            break;
        }
    }

    if (control.validations?.maxlength) {
        attrs.maxlength = control.validations.maxlength;
    }

    applyOverwrites(control, overwrites, attrs);

    // Cleanup
    removeNilValues(attrs);
    cleanUpAttributes(attrs);

    return attrs;
}

/**
 * Applies overwrites for control attributes
 *
 * @param {FieldDescriptor} control the control object to decorate.
 * @param {Partial<ControlConfig>} overwrites the configuration overwirtes object.
 * @param {object} attrs the attributes object to decorate.
 */
function applyOverwrites(control, overwrites, attrs) {
    if (control.kind === 'display-only') {
        overwrites.disabled = true;
    }

    if (attrs.dependant) {
        overwrites.dependant = true;
    }

    Object.assign(attrs, overwrites);
}

/**
 * Cleans the attributes object from nil values.
 *
 * @param {object} attrs the attributes object to remove nil values from
 */
function removeNilValues(attrs) {
    Object.keys(attrs).forEach(key => {
        if (isNil(attrs[key])) {
            delete attrs[key];
        }
    });
}

function cleanUpAttributes(attrs) {
    if (attrs.multiple) {
        delete attrs.single_doc;
    }

    if (attrs.component) {
        delete attrs.component;
    }

    if (attrs.notBefore) {
        delete attrs.not_before;
    }

    if (attrs.notAfter) {
        delete attrs.not_after;
    }
}

/**
 * Retrieves the validators for a given control.
 *
 * @param {FieldDescriptor} control - The control object.
 * @param {Object} [dependencies] - The validator's dependencies object.
 *
 * @returns {string} - The validators for the control.
 */
export function getControlValidators(control, dependencies) {
    if (control.kind === 'colour') {
        control.validations = control.validations || {};
        control.validations['hex_color'] = true;
    }

    if (control.kind === 'custom') {
        const validators = {};
        for (const validator of Object.keys(control.validations || {})) {
            validators[validator] = dependencies || true;
        }
        return Object.keys(validators).length ? validators : control.validations || '';
    }

    if (control.kind === 'external' && control.kind_options?.type === 'any') {
        control.validations = control.validations || {};
        control.validations['foreign_reference'] = true;
    }

    return Object.keys(control.validations || {}).reduce((validators, validator) => {
        if (['pattern', 'unique'].includes(validator)) return validators;
        if (validators.length) validators += '|';
        if (validator === 'required') {
            if (control.validations[validator]) {
                validators += validator;
            }
        } else if (control.kind === 'number' && (validator === 'max' || validator === 'min')) {
            validators += `${validator}_value:${control.validations[validator]}`;
        } else if (control.kind === 'text' && validator === 'maxlength') {
            validators += `max:${control.validations[validator]}`;
        } else {
            validators += `${validator}:${control.validations[validator]}`;
        }
        return validators;
    }, '');
}

/**
 * Returns a document of type fp_type where all values are the default values.
 *
 * @param {FieldDescriptor} fields the fields to extract the defaults from
 *
 * @return {Object} the document with the default values
 */
export function extractDefaults(fields, $services) {
    const defaults = {};

    for (const [key, value] of Object.entries(fields)) {
        if (!value) {
            continue;
        }

        const field = value.field || key;

        if (value.kind === 'timestamp') {
            continue;
        }

        if (value.kind === 'custom') {
            defaults[field] = value.hasOwnProperty('default') ? value.default : [];
            continue;
        }

        if (value.kind === 'choice' && value.kind_options?.list_style === 'multi-choice') {
            defaults[field] = value.default;
            continue;
        }

        if (
            value.kind !== 'nested-object' ||
            value.hasOwnProperty('default') ||
            !value.kind_options?.fields
        ) {
            defaults[field] = clone(value.default);
            continue;
        }

        const optionsFields = value.kind_options.fields;

        if (optionsFields) {
            const nestedDefaults = extractDefaults(optionsFields, $services);
            defaults[field] = nestedDefaults;
        }
    }

    const processedDefaults = {};

    for (const [field, defaultValue] of Object.entries(defaults)) {
        const processedValue = replacePlaceholdersInTree(defaultValue, $services);
        processedDefaults[field] = processedValue;
    }

    return pickBy(processedDefaults, (i) => (typeof i !== 'undefined'));
}

/**
 * Replaces placeholders in a tree-like structure with generated values.
 *
 * @param {any} val - The value to process.
 * @param {Services} $services - The services object.
 *
 * @returns {any} - The processed value with placeholders replaced.
 */
export function replacePlaceholdersInTree(val, $services) {
    if (!val) {
        return val;
    }

    const genRegEx = new RegExp(GENERATED_DEFAULT_VALUE_PLACEHOLDER, 'g');

    if (genRegEx.exec(val)) {
        return val.replace(genRegEx, $services.documents.generateAutoGeneratedValueString());
    }

    // handle object recursively
    if (isObject(val) && !Array.isArray(val)) {
        for (const [key, value] of Object.entries(val)) {
            val[key] = replacePlaceholdersInTree(value, $services);
        }
    }

    return val;
}

/**
 * @param {object} args
 * @param {FieldDescriptor[]} args.fields a list of field descriptors
 * @param {Record<string,unknown>} args.settings
 * @param {string} args.defaultKey
 * @param {boolean} [args.userIsInvitedByLink]
 * @param {boolean} [args.editing] whether the fields will be used for editing an existing doc or not

 * @returns {[Record<string,FieldDescriptor[]>, Record<string,FieldDescriptor[]>]}
 */
export function sortFieldsByColumnAndCategory({ fields, settings, defaultKey, userIsInvitedByLink, editing }) {
    /** @type {(field: FieldDescriptor) => boolean} */
    const shouldBeVisible = (field) => {
        if (field.hidden || userIsInvitedByLink && field.hideForInvited) {
            return false;
        }
        const settingsCond = field.conditions?.settings;
        if (Array.isArray(settingsCond)) {
            let visible = false;
            for (const { path, value } of settingsCond) {
                visible = get(settings, path) === value;
                if (!visible) {
                    break;
                }
            }
            return visible;
        }
        return true;
    };

    const visibleFields = fields.filter(field => shouldBeVisible(field));
    const fieldsLeft = fieldsByPlacement(visibleFields, 'left');
    const fieldsRight = fieldsByPlacement(visibleFields, 'right');
    return [
        sortAndGroupFields(fieldsLeft, defaultKey, editing),
        sortAndGroupFields(fieldsRight, defaultKey, editing),
    ];
}
