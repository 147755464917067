import * as importExportUtils from 'libs/utils/import-export';

angular.module('backstage.services').factory('fieldFormatterService',
    ['eventService', 'metadataService', (eventService, metadataService) => {
        const event = eventService.getCurrentEvent();
        const eventMetadata = metadataService.getCachedMetadata(event._id) || {};

        return {
            applyFormatter(val, fieldDescriptor) {
                const { kind, kind_options, name } = fieldDescriptor;
                const formatter = importExportUtils.getFormatterForKind(kind);
                const options = kind_options || {};
                const formatted = formatter(val, options, eventMetadata, name);
                return formatted;
            }
        };
    }]);
