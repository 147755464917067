"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var _ = require('underscore');
angular.module('backstage.controllers.widgets').controller('TargetsExceptionsFloatingRuleEditorCtrl', /* @ngInject */["$scope", "eventService", "metadataService", function ($scope, eventService, metadataService) {
  var eventId = eventService.getCurrentEvent()._id;
  var participantMetadata = metadataService.getCachedMetadataForType(eventId, 'person', true);
  var participantFields = metadataService.displaceInAppFieldsAsFieldKeys(metadataService.getCachedMetadataForTypeAsArray(eventId, 'person', true));
  $scope.participantFields = participantFields;

  // add fp_ext_id as a field here
  participantFields.unshift({
    label: 'Unique Identifier',
    field: 'fp_ext_id'
  });
  if (!$scope.editingFields) {
    $scope.editingFields = [];
  }
  $scope.$watch('editingRuleIdx', function (newEditingRuleIdx, lastRuleIdx) {
    if (!_.isNumber(newEditingRuleIdx)) {
      return;
    }
    $scope.editingModel = $scope.originalRuleset[newEditingRuleIdx];
    $scope.editingFields = ($scope.rulesetMetadata[newEditingRuleIdx] || []).map(function (field, index) {
      return _objectSpread(_objectSpread({}, field), {}, {
        // clone the object because we want to modify the oder
        order: index // order fields by how rules were added
      });
    });

    // empty rule removal logic
    if (newEditingRuleIdx !== -1 || newEditingRuleIdx === lastRuleIdx || !_.isEmpty($scope.originalRuleset[lastRuleIdx])) {
      return;
    }

    // delete empty element
    $scope.originalRuleset.splice(lastRuleIdx, 1);
  });
  $scope.addConditionToRule = function (ruleIdx, ruleConditionKey) {
    if (!ruleConditionKey) {
      return;
    }
    var fieldDescriptor = participantMetadata[ruleConditionKey] || {};
    if (fieldDescriptor.kind === 'boolean') {
      $scope.originalRuleset[ruleIdx][ruleConditionKey] = false;
    } else {
      $scope.originalRuleset[ruleIdx][ruleConditionKey] = null;
    }
    return $scope.originalRuleset[ruleIdx][ruleConditionKey];
  };
  $scope.removeConditionFromRule = function (ruleIdx, ruleConditionKey) {
    return delete $scope.originalRuleset[ruleIdx][ruleConditionKey];
  };
  $scope.getAvailableParticipantFields = function (ruleIdx) {
    var conditions = $scope.originalRuleset[ruleIdx] || {};
    return _.reject(participantFields, function (fd) {
      return conditions[fd.field] || conditions[fd.field] === null || fd.is_dynamic_ext && fd.field !== '_registered_sessions';
    });
  };
}]);