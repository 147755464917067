module.exports = [() => ({
    scope: {
        id: '=',
        type: '=',
        eventId: '='
    },
    templateUrl: '/static/partials/components/dynamic-type-extensions-display-widget.html',
    controller: [
        '$scope', '$route', 'metadataService', 'dynamicExtensionsService',
        function($scope, $route, metadataService, dynamicExtensionsService) {
            let fieldsToPromisesLabelsMap;
            const TEXT_RENDERABLE_KINDS = ['text', 'number', 'boolean', 'html'];

            let {
                eventId
            } = $scope;
            const route = ($route.current || {}).$$route || {};
            const extensions = route.extensions || {};

            const fieldLabelsMap = {};
            const textRenderableFields = [];

            $scope.isFieldTextRenderable = field => Array.from(textRenderableFields).includes(field);

            $scope.isFieldExtensionRenderable = field => {
                return _.isString((extensions[field] || {}).templateUrl);
            };

            $scope.getLabelTextForField = field => fieldLabelsMap[field] || field;

            $scope.getDynamicTypeExtensionTemplateUrl = field => {
                return (extensions[field] || {}).templateUrl;
            };

            $scope.handleClick = function(field) {
                if (!$scope.isFieldExtensionRenderable(field)) {
                    return;
                }
                let descriptor = fieldsToPromisesLabelsMap[field];

                return descriptor._expanded = !descriptor._expanded;
            };

            let mdFields = metadataService.getCachedMetadataForTypeAsArray($scope.eventId, $scope.type, true);

            // labels come from metadata
            mdFields.forEach(field => fieldLabelsMap[field.field] || (fieldLabelsMap[field.field] = field.label || field.field));

            // a field is considered a dynamic extension if it has a handler_path
            mdFields = mdFields.filter(field => {
                return field.handler_path !== null &&
                    field.handler_path !== undefined;
            });

            mdFields.forEach(function(field) {
                if (!field.kind || Array.from(TEXT_RENDERABLE_KINDS).includes(field.kind)) {
                    return textRenderableFields.push(field.field);
                }
            });

            $scope.isHeaderField = field => {
                const fieldWithMetadata = mdFields.find(fld => fld.field === field);
                return fieldWithMetadata.section_header;
            };

            // consider extensions that were not declared originally as _dynamic_exts in metadata
            _.chain(extensions).pairs().reject(extPair => {
                // a way to get unique fields
                return mdFields.some(field => field.field === extPair[0]);
            }).forEach(function(extPair) {
                let field = extPair[0];
                let label = extPair[1].label || extPair[0];

                if (!fieldLabelsMap[field]) {
                    fieldLabelsMap[field] = label;
                }
                return mdFields.push({
                    field,
                    label,
                    _expanded: (extPair[1] || {}).expanded
                });
            });

            fieldsToPromisesLabelsMap = ($scope.fieldsToPromisesLabelsMap =
                _.chain(mdFields)
                    .map(function(mdField) {
                        let id;

                        [id, eventId] = Array.from([$scope.id, $scope.eventId]);
                        var [field, label] = Array.from([mdField.field, mdField.label || field]);
                        const deleteFieldToPromiseMappingIfNoTemplate = function(data) {
                            // is this renderable?
                            if (_.isObject(data) && !extensions[field]) {
                                delete fieldsToPromisesLabelsMap[field];
                            }
                            return data;
                        };

                        let descriptor = {
                            label,
                            _expanded: mdField.expanded || mdField._expanded
                        };

                        if (!id || !eventId) {
                            return [field, descriptor];
                        }

                        descriptor.promise = dynamicExtensionsService.getDynamicExtensionPropertyRetrievalPromise(mdField, id, eventId)
                            .then(deleteFieldToPromiseMappingIfNoTemplate, deleteFieldToPromiseMappingIfNoTemplate)
                            .then(data => descriptor.value = data);

                        return [field, descriptor];
                    })
                    .object()
                    .value());
        }
    ]
})];
