angular.module('backstage.directives', [
    'siyfion.sfTypeahead',
    'jsTag'
])
    .directive('ngHref', require('./ngHref'))
    .directive('iconSpinner', require('./iconSpinner'))
    .directive('smartTablePaginationPersist', require('./smartTablePaginationPersist'))
    .directive('bsFullscreenRequestElement', require('./bsFullscreenRequestElement'))
    .directive('bsDynamicExtensionsDisplay', require('./bsDynamicExtensionsDisplay'))
    .directive('bsCustomActionButtons', require('./bsCustomActionButtons'))
    .directive('bsFadeInAsap', require('./bsFadeInAsap'))
    .directive('bsFadeInOnChange', require('./bsFadeInOnChange'))
    .directive('bsImgPaxPhoto', require('./bsImgPaxPhoto'))
    .directive('bsImgNavItemIcon', require('./bsImgNavItemIcon'))
    .directive('bsActivityWidget', require('./bsActivityWidget'))
    .directive('bsAssetManagerModalTrigger', require('./bsAssetManagerModalTrigger'))
    .directive('bsNewAssetManagerModalTrigger', require('./bsNewAssetManagerModalTrigger'))
    .directive('bsOpenableInNewWindow', require('./bsOpenableInNewWindow'))
    .directive('bsExcelImportIndeterminateCheckbox', require('./bsExcelImportIndeterminateCheckbox'))
    .directive('bsTaskExecutionProgressModal', require('./bsTaskExecutionProgressModal'))
    .directive('bsHelpContentImageSourceRewriter', require('./bsHelpContentImageSourceRewriter'))
    .directive('wizard', require('./wizard'))
    .directive('bsOnclickPropagationCatcher', require('./bsOnclickPropagationCatcher'))
    .directive('bsFileUpload', require('./bsFileUpload'))
// # # # Bootstrap workarounds
    .directive('strapModal', require('./strapModal'))
    .directive('modalBackdrop', require('./modalBackdrop'))
    .directive('strapTooltip', require('./strapTooltip'))
    .directive('columnFilter', require('./columnFilter'))
    .directive('overwriteEmailValidator', require('./overwriteEmailValidator'))
    .directive('submitParentFormOnChange', require('./submitParentFormOnChange'));
