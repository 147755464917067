// Classes
import BaseService from './base-service';

// Utils
import { get, omit } from 'lodash';
import { sortByOrder } from 'libs/utils/collections';
import * as metaUtils from 'libs/utils/metadata';
import { translateWithContext } from 'libs/utils/locales';

/**
 * @const {String} METADATA_API_ENDPOINT the metadata API endpoint. Interpolations: `{{eventId}}`.
 * @private
 */
const METADATA_API_ENDPOINT = '/api/v1/events/{{eventId}}/metadata';

/**
 * @const {String} METADATA_COMPILE_API_ENDPOINT the metadata compilation API endpoint. Interpolations: `{{eventId}}`.
 * @private
 */
const METADATA_COMPILE_API_ENDPOINT = '/api/v1/eid/{{eventId}}/compile/metadata';

/**
 * @constant {String} FETCH_METADATA_BIT_ENDPOINT Interpolations: `{{eventId}}`, `{{fpType}}`.
 * @private
 */
const FETCH_METADATA_BIT_ENDPOINT = '/api/v1/events/{{eventId}}/docbyid/bstg-metadata-bit-{{fpType}}';

/**
 * @constant {String} SAVE_METADATA_BIT_ENDPOINT Interpolations: `{{eventId}}`.
 * @private
 */
const SAVE_METADATA_BIT_ENDPOINT = '/api/v1/eid/{{eventId}}/data/metadata-bit';

/**
 * @constant {(name: string, label: string, kind?: string)[]} REGISTRATION_DEFAULT_FIELDS The default registration fields.
 */
const REGISTRATION_DEFAULT_FIELDS = [
    {
        name: 'email',
        kind: 'email',
        label: 'Email address',
        removable: false,
        required: true
    },
    {
        name: 'fname',
        label: 'First name',
        removable: false,
        required: true
    },
    {
        name: 'lname',
        label: 'Last name',
        removable: false,
        required: true
    },
    { name: 'city', label: 'City', removable: true },
    { name: 'company', label: 'Company', removable: true },
    { name: 'country', label: 'Country', kind: 'choice-list', removable: true },
    { name: 'linkedin_url', label: 'LinkedIn', removable: true },
    { name: 'phone', label: 'Phone', kind: 'phone', removable: true },
    { name: 'position', label: 'Job', removable: true },
    { name: 'website', label: 'Website', removable: true }
];

/**
 * @constant {string[]} REGISTRATION_ALLOWED_KINDS The kinds allowed for the registration fields
 */
const REGISTRATION_ALLOWED_KINDS = [
    'boolean', // Checkbox
    'choice-list', // Select
    'choice', // Radio
    'email', // Text
    'formatted-datetime', // Datetime picker
    'hidden', // Hidden
    'locale', // Select
    'number', // Text
    'text-multiline', // Textarea
    'text', // Text,
];

const EDITABLE_KINDS = [
    'boolean',
    'choice-list',
    'choice',
    'colour',
    'content-page',
    'email',
    'embed',
    'external',
    'formatted-datetime',
    'hidden',
    'html',
    'list',
    'number',
    'password',
    'text-multiline',
    'text',
    'timestamp'
];

const ALL_KINDS = [
    'auto',
    'boolean',
    'choice-list',
    'choice',
    'colour',
    'content-page',
    'custom',
    'display-only',
    'email',
    'embed',
    'external',
    'formatted-datetime',
    'hidden',
    'html',
    'list',
    'nested-object',
    'number',
    'password',
    'targets-exceptions',
    'text-multiline',
    'text',
    'timestamp',
    'video-call'
];

/**
 * Provides a facility to handle metadata records.
 *
 * @example
 * import MetadataService from 'libs/services/metadata';
 * ...
 * const metadata = new MetadataService();
 */
export default class MetadataService extends BaseService {
    /**
     * Constant representing all kinds.
     * @readonly
     * @type {Array<string>}
     */
    get ALL_KINDS() {
        return ALL_KINDS;
    }

    /**
     * Gets the editable kinds.
     * @readonly
     * @type {Array<string>} The list of editable kinds.
     */
    get EDITABLE_KINDS() {
        return EDITABLE_KINDS;
    }

    /**
     * Gets the allowed kinds for registration.
     * @readonly
     * @type {Array<string>} An array of allowed registration kinds.
     */
    get REGISTRATION_ALLOWED_KINDS() {
        return REGISTRATION_ALLOWED_KINDS;
    }

    /**
     * Array of supported targeting kinds.
     * @readonly
     * @type {Array<string>}
     */
    get TARGETING_SUPPORTED_KINDS() {
        return ['boolean', 'choice', 'choice-list', 'email', 'hidden', 'locale', 'text', 'formatted-datetime'];
    }

    /**
     * Asks the server to compile a fresh version of the metadata
     *
     * @param {String} eventId the ID of the event to compile the metadata for
     * @param {Boolean} [dryRun=false] whether to perform a dry run or not
     *
     * @return {Promise<import('axios').AxiosResponse>} the server response
     */
    compileMetadata(eventId, dryRun = false) {
        const method = dryRun ? 'get' : 'post';
        const url = METADATA_COMPILE_API_ENDPOINT.replace('{{eventId}}', eventId);

        return this[method](url);
    }

    /**
     * Retrieves metadata for a given event.
     *
     * @param {string} eventId - The ID of the event to retrieve metadata for.
     * @param {number} [maxDuration=60000] - The maximum duration in milliseconds to cache the metadata.
     * @param {boolean} [skipCompile=true] - Whether to skip the compilation of metadata.
     *
     * @returns {Promise<Object>} The metadata object for the event.
     */
    async getMetadata(eventId, maxDuration = 60000, skipCompile = true) {
        try {
            if (!skipCompile) {
                await this.compileMetadata(eventId);
                maxDuration = 0;
            }

            const url = this.buildUrl(METADATA_API_ENDPOINT, { eventId });
            const { data } = await this.getCached(url, { withCredentials: true }, maxDuration);

            return data || { _id: 'metadata' };
        } catch (error) {
            console.error('[MetadataService] Could not retrieve metadata, providing stub document.', error.message);
            return { _id: 'metadata' };
        }
    }

    /**
     * Gets the metadata for the given FP Type
     *
     * @param {String} eventId the ID of the event
     * @param {String} fpType the fp type to get the metadata for
     * @param {Boolean} [mergeDynamicExtensions=false] if true it tries to merge dynamic extensions
     * @param {Boolean} [filterPrivate=false] if true it will strip all the private fields
     * @param {string[]} [filterPrivateExceptions] exceptions to filterPrivate
     *
     * @return {Object} the metadata for the given FP type
     */
    async getMetadataForType(eventId, fpType, mergeDynamicExtensions = false, filterPrivate = false, filterPrivateExceptions = []) {
        const metadata = await this.getMetadata(eventId);
        return metaUtils.buildMetadataForType(metadata, fpType, mergeDynamicExtensions, filterPrivate, filterPrivateExceptions);
    }

    /**
     * Retrieves metadata for a given type and event ID, and returns it as an ordered array.
     *
     * @param {string} eventId - The ID of the event for which metadata is being retrieved.
     * @param {string} fpType - The type of metadata to retrieve.
     * @param {boolean} [mergeDynamicExtensions=false] - Whether to merge dynamic extensions into the metadata.
     *
     * @returns {Array} An ordered array of metadata.
     */
    async getMetadataForTypeAsArray(eventId, fpType, mergeDynamicExtensions = false, filterPrivate = false) {
        const mdHash = await this.getMetadataForType(eventId, fpType, mergeDynamicExtensions, filterPrivate);
        return metaUtils.convertMetadataHashToOrderedList(mdHash);
    }

    /**
     * Compiles a list of available fields to be eventually added
     * to the event's registration page
     *
     * @param {string} [eventId] the ID of the event
     * @param {boolean} [onlyPublic] to filter out the private fields
     *
     * @returns {Promise<Object[]>} a list of fields for the event's registration page
     */
    async getUserRegistrationFields(eventId, onlyPublic = false) {
        if (!eventId) {
            return REGISTRATION_DEFAULT_FIELDS;
        }

        const metadata = await this.getMetadataForType(eventId, 'person');
        const descriptors = metaUtils.pickDescriptorsByKind(metadata, REGISTRATION_ALLOWED_KINDS);
        const fields = [];

        for (const desc of sortByOrder(descriptors)) {
            desc.removable = !get(desc, 'validations.required', false);
            const field = omit(desc, [
                '_bulk_edit',
                '_editable',
                'crypt',
                'example',
                'order',
                'searchable',
                'sortable',
                'validations',
            ]);

            if (['position', 'company'].includes(field.name)) {
                field._default = true;
            }

            if (!onlyPublic || onlyPublic && !desc.crypt) {
                // crypt tells us if the field is private
                fields.push(field);
            }
        }

        return fields;
    }

    /**
     * Returns a validation schema for a given fp_type
     *
     * @param {string} eventId the ID of the event
     * @param {string} fpType the fp type to get the validation schema for
     *
     * @returns {Promise<object>} the validation schema
     *
     * @see https://ajv.js.org/json-schema.html
     */
    async getSchemaForType(eventId, fpType) {
        try {
            const meta = await this.getMetadataForType(eventId, fpType, true);
            const metadata = await this.getMetadataForTypeAsArray(eventId, fpType);
            return metaUtils.getTypeSchema(meta, metadata, fpType);
        } catch (error) {
            console.warn('[MetadataService] Could not determine the validation schema', error.message);
            return {};
        }
    }

    /**
     * Retrieves the targeting metadata for an event.
     *
     * @param {Event} event - The event object.
     * @param {boolean} [includeEmptyState] - Whether to include empty state metadata.
     * @param {object} [emptyState] - The empty state metadata object.
     *
     * @returns {Promise<object>} - The targeting metadata.
     */
    async getTargetingMetadata(event, includeEmptyState = true, emptyState = { value: '*has_field*', label: 'None' }) {
        return await this.getDecoratedMetadataForType(event, 'person', {
            mergeDynamicExtensions: true,
            filterPrivate: true,
            filterPrivateExceptions: ['fp_rsvp_status', 'fp_locale'],
            includeEmptyState,
            emptyState
        });
    }

    /**
     * Gets the decorated metadata for a specific event and fp type.
     *
     * @param {Object} event - The event object.
     * @param {string} fpType - The file path type.
     * @param {Object} options - The options for decorating the metadata.
     * @param {boolean} [options.mergeDynamicExtensions=false] - Whether to merge dynamic extensions.
     * @param {boolean} [options.filterPrivate=false] - Whether to filter private metadata.
     * @param {Array} [options.filterPrivateExceptions=[]] - The exceptions to the private metadata filter.
     * @param {boolean} [options.includeEmptyState=false] - Whether to include empty state.
     * @param {Object} [options.emptyState={ value: '', label: 'None' }] - The empty state object.
     *
     * @returns {Promise<Object>} - The decorated metadata.
     */
    async getDecoratedMetadataForType(event, fpType, {
        mergeDynamicExtensions,
        filterPrivate,
        filterPrivateExceptions,
        includeEmptyState,
        emptyState
    } = {
        mergeDynamicExtensions: false,
        filterPrivate: false,
        filterPrivateExceptions: [],
        includeEmptyState: false,
        emptyState: { value: '', label: 'None' }
    }) {
        const metadata = await this.getMetadataForType(event._id, fpType, mergeDynamicExtensions, filterPrivate, filterPrivateExceptions);
        metaUtils.decorateLocaleFields(event, metadata, includeEmptyState, emptyState);
        return metadata;
    }

    /**
     * Checks if the asset has a specific fingerprint type.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} fpType - The type of fingerprint to check.
     *
     * @returns {boolean} - Returns true if the asset has the specified fingerprint type, otherwise false.
     */
    async isAssetFpType(eventId, fpType) {
        const metadata = await this.getMetadataForType(eventId, fpType);
        return !!metadata._essence;
    }

    /**
     * Checks if a specific feature is available for a given event and file path type.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} fpType - The file path type.
     * @param {string} feature - The feature to check availability for.yp
     * @param {boolean} mergeDynamicExtensions - Whether to merge dynamic extensions.
     *
     * @returns {boolean} - Returns true if the fpType is bulk editable, otherwise false.
     */
    async isFeatureForTypeAvailable(eventId, fpType, feature, mergeDynamicExtensions) {
        const metadata = await this.getMetadataForType(eventId, fpType, mergeDynamicExtensions);
        return metadata.hasOwnProperty(`_${feature}`);
    }

    /**
     * Checks if the given fpType is editable for the specified event.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} fpType - The type of the fp (file path).
     * @param {boolean} mergeDynamicExtensions - Whether to merge dynamic extensions.
     *
     * @returns {boolean} - Returns true if the fpType is bulk editable, otherwise false.
     */
    async isFpTypeDuplicable(eventId, fpType, mergeDynamicExtensions) {
        return await this.isFeatureForTypeAvailable(eventId, fpType, 'duplicate', mergeDynamicExtensions);
    }

    /**
     * Checks if the given fpType is bulk editable for the specified event.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} fpType - The type of the fp (file path).
     * @param {boolean} mergeDynamicExtensions - Whether to merge dynamic extensions.
     *
     * @returns {boolean} - Returns true if the fpType is bulk editable, otherwise false.
     */
    async isFpTypeBulkEditable(eventId, fpType, mergeDynamicExtensions) {
        return await this.isFeatureForTypeAvailable(eventId, fpType, 'bulk_edit', mergeDynamicExtensions);
    }

    /**
     * Determines if the given event and field type are time shiftable.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} fpType - The field type.
     * @param {boolean} mergeDynamicExtensions - Whether to merge dynamic extensions.
     *
     * @returns {boolean} - True if the field type is time shiftable, false otherwise.
     */
    async isFpTypeTimeShiftable(eventId, fpType, mergeDynamicExtensions) {
        const metadata = await this.getMetadataForTypeAsArray(eventId, fpType, mergeDynamicExtensions);
        const timeShiftableFields = metadata.filter(f => f._timeshift);

        return timeShiftableFields.length > 0;
    }

    /**
     * Fetches metadata bit by event ID and fp type.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} fpType - The fp type.
     *
     * @returns {Promise<Object>} A promise that resolves to the metadata bit data.
     */
    async getMetadataBitByFpType(eventId, fpType) {
        const url = this.buildUrl(FETCH_METADATA_BIT_ENDPOINT, { eventId, fpType });
        const { data } = await this.get(url, { withCredentials: true });
        return data;
    }

    /**
     * Saves a metadata bit for a given event.
     *
     * @param {string} eventId - The ID of the event.
     * @param {Record<string,unknown>} bitDoc - The document containing the metadata bit to be saved.
     */
    async saveMetadataBit(eventId, bitDoc) {
        const url = this.buildUrl(SAVE_METADATA_BIT_ENDPOINT, { eventId });
        await this.post(
            url,
            { rows: [bitDoc] },
            {
                withCredentials: true,
                params: { keep_id: true, pretty_field_name: true },
            }
        );
    }

    /**
     * Saves the fingerprint type metadata for a given event.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} fpType - The fingerprint type.
     * @param {Object} metadataByType - The metadata categorized by type.
     * @param {Object} representations - The representations associated with the metadata.
     *
     * @returns {Promise<Object>} - An object indicating whether the metadata was updated and any validation errors.
     */
    async saveFpTypeMetadata(eventId, fpType, metadataByType, representations) {
        const { valid, ...validationErrors } = await this.validateMetadataUpdate(eventId, fpType, metadataByType);
        if (!valid) {
            return { updated: false, ...validationErrors };
        }

        const byType = await this.sanitizeMetadataUpdate(eventId, fpType, metadataByType);
        const cachedMetadata = await this.getMetadata(eventId);

        // can't use allSettled until we switch to 2020. Can't use all because the bit part can fail.
        const metadata = await this.getMetadata(eventId, 0);
        if (cachedMetadata._rev !== metadata._rev) {
            return { updated: false, metadataWasChanged: true };
        }

        // nothing to do in the catch, it's just that the bit doesn't exist
        const bit = await this.getMetadataBitByFpType(eventId, fpType).catch(() => {}) ?? {};

        const finalOverrideBit = metaUtils.getOverrideBitFromDiff(
            eventId,
            fpType,
            byType,
            metadata,
            bit,
            representations,
        );

        await this.saveMetadataBit(eventId, finalOverrideBit);

        // recompile metadtata and refresh cache
        const updatedMetadata = await this.getMetadata(eventId, 0, false);

        return { updated: updatedMetadata[fpType] };
    }

    /**
     * Validates the metadata update for a given event and field type.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} fpType - The type of the field.
     * @param {Object} metadataByType - The metadata to be validated.
     *
     * @returns {Object} An object containing the validation result.
     * @returns {boolean} returns.valid - Indicates if the metadata update is valid.
     * @returns {string[]} [returns.invalidFieldNames] - List of invalid field names, if any.
     * @returns {string[]} [returns.mayNotBePrivate] - List of fields that should not be private, if any.
     */
    async validateMetadataUpdate(eventId, fpType, metadataByType) {
        const original = await this.getMetadataForType(eventId, fpType);
        const originalFields = Object.keys(original);
        const newFields = Object.keys(metadataByType).filter(d => !originalFields.includes(d));

        // validate new field names
        const invalidFieldNames = newFields.filter(d => !metaUtils.isValidFieldName(d));
        if (invalidFieldNames.length) {
            return { valid: false, invalidFieldNames };
        }

        // check fields that may not be private are not
        const mayNotBePrivate = [];
        for (const [fieldName, definition] of Object.entries(metadataByType)) {
            if (metaUtils.shouldNotBePrivate(fieldName) && definition.crypt) {
                mayNotBePrivate.push(fieldName);
            }
        }
        if (mayNotBePrivate.length) {
            return { valid: false, mayNotBePrivate };
        }

        return { valid: true };
    }

    /**
     * Sanitizes the metadata update by setting default values for new boolean fields
     * and marking deleted fields as to be deleted.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} fpType - The type of the metadata.
     * @param {Object} metadataByType - The metadata object containing fields and their definitions.
     *
     * @returns {Object} The sanitized metadata object.
     */
    async sanitizeMetadataUpdate(eventId, fpType, metadataByType) {
        const original = await this.getMetadataForType(eventId, fpType);
        const originalFields = Object.keys(original);
        const newFields = Object.keys(metadataByType).filter(d => !originalFields.includes(d));

        // set default value for new boolean fields
        for (const key of newFields) {
            const definition = metadataByType[key];
            if (definition.kind === 'boolean' && !definition.default) {
                metadataByType[key].default = false;
            }
        }

        // mark deleted fields as to be deleted
        for (const key of originalFields) {
            if (!metadataByType[key]) {
                metadataByType[key] = { toBeDeleted: true };
            }
        }

        return metadataByType;
    }

    /**
     * Retrieves the representation template for a given event type.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} fpType - The type of the event.
     * @param {boolean} [mergeDynamicExtensions=false] - Flag indicating whether to merge dynamic extensions.
     *
     * @returns {Promise<Object>} A promise that resolves to the representation template.
     */
    async getReprTemplateForType(eventId, fpType, mergeDynamicExtensions = false) {
        const mdHash = await this.getMetadataForType(eventId, fpType, mergeDynamicExtensions);
        return mdHash._representation;
    }

    /**
     * Retrieves the representation label for a given item.
     *
     * @param {string} eventId - The ID of the event.
     * @param {Object} doc - The document object containing item details.
     * @param {object} i18n the i18n util
     * @param {boolean} skipEscape - Flag to determine if escaping should be skipped.
     *
     * @returns {Promise<string|null>} - The representation label for the item, or null if not found.
     */
    async getReprLabelForItem(eventId, doc, i18n, skipEscape) {
        const fpType = get(doc, 'fp_type');

        if (!fpType) {
            return null;
        }

        const tmpl = await this.getReprTemplateForType(eventId, fpType);

        if (!tmpl) {
            return null;
        }

        return translateWithContext(tmpl, doc, i18n, skipEscape);
    }
}
