// Utils
import { isEmpty, isString } from 'lodash';
import Markdown from 'marked';

// Constants

/**
 * The default formatter kind used in the application.
 *
 * @constant {string}
 */
const DEFAULT_FORMATTER_KIND = 'default';

/**
 * Regular expression to match Excel datetime format.
 * Format: MM/DD/YYYY HH:MM
 *
 * @constant {RegExp}
 */
const EXCEL_DATETIME_MATCHER_REGEX = /^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2})$/;

/**
 * An array of formatter definitions used for processing various data types.
 * Each formatter is defined as an array where the first element is the formatter type,
 * and the second element is the corresponding processing function.
 *
 * Formatters:
 * - 'html': Processes HTML content using the Markdown function.
 * - 'timestamp': Sanitizes Excel parsed date formats.
 * - 'list': Processes string lists or arrays.
 * - 'nested-object': Processes nested objects.
 * - 'external': Processes external data.
 * - 'boolean': Parses boolean values.
 * - 'number': Parses floating-point numbers.
 * - 'targets-exceptions': Parses targets and exceptions.
 * - 'custom': Processes custom data.
 * - DEFAULT_FORMATTER_KIND: Processes text data, converting non-string values to strings.
 *
 * @type {Array.<Array.<string, function>>}
 */
const FORMATTERS = {
    'html': (html) => {
        if (html === null) {
            html = '';
        }
        return Markdown(html);
    },
    'timestamp': sanitizeExcelParsedDateFormat,
    'list': parseStringListOrArray,
    'nested-object': processNestedObject,
    'external': processExternal,
    'boolean': parseBoolean,
    'number': parseFloat,
    'targets-exceptions': parseTargetsExceptions,
    'custom': processCustom,
    'content-page': processCustom,
    'text': parseText,
    [DEFAULT_FORMATTER_KIND]: parseText,
};

/**
 * Sanitizes a datetime string parsed from Excel to a standard format.
 *
 * @param {string} datetime - The datetime string to be sanitized.
 * @returns {string} - The sanitized datetime string in the format YYYY/MM/DD HH:MM.
 */
export function sanitizeExcelParsedDateFormat(input) {
    if (typeof input !== 'string') return input;
    const match = input.match(EXCEL_DATETIME_MATCHER_REGEX);
    if (!match || match.length <= 5) return input;

    const [, month, day, year, hour, minute] = match;
    return `${year}/${month}/${day} ${hour}:${minute}`;
}

/**
 * Processes a string list or array based on the provided kind options.
 *
 * @param {string|string[]} listStr - The string or array to be processed. If a string, it should be a comma-separated list.
 * @param {Object} kindOptions - Options to determine how to process the list.
 * @param {string} kindOptions.item_kind - The type of items in the list. Can be 'number', 'boolean', or 'nested-object'.
 * @param {Object} eventMetadata - Additional metadata for processing nested objects.
 * @param {string} fieldName - The name of the field being processed.
 *
 * @returns {Array} The processed array with items converted based on the kind options.
 */
export function parseStringListOrArray(listStr, kindOptions, eventMetadata, fieldName) {
    if (['targets', 'exceptions'].includes(fieldName)) {
        return parseTargetsExceptions(listStr, kindOptions, eventMetadata);
    }

    if (!isString(listStr) || !listStr.includes(',')) {
        return listStr;
    }

    const arr = listStr.split(',');

    switch (kindOptions?.item_kind) {
        case 'number':
            return arr.map(parseFloat);
        case 'boolean':
            return arr.map(parseBoolean);
        case 'nested-object':
            return arr.map(item => processNestedObject(item, kindOptions, eventMetadata));
        default:
            return arr;
    }
}

/**
 * Parses a value and returns its boolean representation.
 *
 * @param {string} value - The value to be parsed.
 *
 * @returns {boolean} - The boolean representation of the value.
 *                      Returns `true` for 'true', 'yes', '1' (case insensitive).
 *                      Returns `false` for 'false', 'no', '0', null, or empty string (case insensitive).
 *                      For any other value, returns the boolean equivalent of the value.
 */
export function parseBoolean(value) {
    if (!value || value.length === 0) {
        return false;
    }

    switch (value.toLowerCase()) {
        case 'true': case 'yes': case '1':
            return true;
        case 'false': case 'no': case '0': case null:
            return false;
        default:
            return Boolean(value);
    }
}

/**
 * Parses the given value and returns it as a string.
 *
 * @param {*} value - The value to be parsed.
 *
 * @returns {string} The parsed string value.
 */
export function parseText(value) {
    if (isString(value)) {
        return value;
    } else {
        return String(value);
    }
}

/**
 * Processes the given value based on the provided kind options.
 *
 * @param {*} value - The value to be processed.
 *
 * @returns {*} - The processed value.
 */
export function processCustom(value, kindOptions) {
    if (!kindOptions?.type || kindOptions.type === 'custom') {
        // Return the value as is if no specific parsing type is defined or if it's the same as the current kind (which could cause an infinite loop)
        return value;
    }

    return FORMATTERS[kindOptions.type](value, kindOptions);
}

/**
 * Processes a nested object by applying specific formatting functions to its fields based on provided options.
 *
 * @param {Object} obj - The object to be processed. If the input is not an object or is an array, it is returned as is.
 * @param {Object} kindOptions - Options that define how to process each field of the object.
 * @param {Object} kindOptions.fields - A mapping of field names to their descriptors.
 * @param {Object} eventMetadata - Additional metadata that may be used by the formatter functions.
 *
 * @returns {Object} - A new object with the same keys as the input object, but with values processed by the appropriate formatter functions.
 */
export function processNestedObject(obj, kindOptions, eventMetadata) {
    if (typeof obj !== 'object' || Array.isArray(obj)) {
        return obj;
    }

    const result = {};
    for (const [key, value] of Object.entries(obj)) {
        const fieldDescriptor = kindOptions?.fields?.[key] || Object.values(kindOptions?.fields || {}).find(field => field.field === key);

        if (!fieldDescriptor) {
            result[key] = value;
            continue;
        }

        const kind = fieldDescriptor.kind || DEFAULT_FORMATTER_KIND;
        result[key] = getFormatterForKind(kind)(value, fieldDescriptor.kind_options, eventMetadata);
    }

    return result;
}

/**
 * Processes external data based on the provided kind options and event metadata.
 * If the kind options type is 'targets-exceptions' and the value is an array,
 * it performs special processing using the parseTargetsExceptions function.
 * Otherwise, it returns the value as is.
 *
 * @param {*} val - The value to be processed.
 * @param {Object} kindOptions - Options that determine the kind of processing to be applied.
 * @param {string} kindOptions.type - The type of processing to be applied.
 * @param {Object} eventMetadata - Metadata related to the event.
 *
 * @returns {*} - The processed value or the original value if no special processing is required.
 */
export function processExternal(val, kindOptions, eventMetadata) {
    // don't do any formatting unless we're dealing with targets-exceptions; they require special processing!
    if ((kindOptions.type !== 'targets-exceptions') || !Array.isArray(val)) {
        return val;
    }

    return parseTargetsExceptions(val, kindOptions, eventMetadata);
}

/**
 * Parses and formats a list of target exceptions based on person metadata.
 *
 * @param {Array} val - The list of target exceptions to be parsed.
 * @param {Object} kindOptions - Options for formatting based on kind.
 * @param {Object} personMetadata - Metadata containing person information.
 * @param {Object} eventMetadata.person - Metadata about the person.
 *
 * @returns {Array} The formatted list of target exceptions.
 */
export function parseTargetsExceptions(val, kindOptions, eventMetadata) {
    // dealing with targets-exceptions list. format each mapping according to person metadata
    if (isEmpty(eventMetadata.person)) {
        return val;
    }

    const personMetadata = eventMetadata.person;
    const formattedVal = [];

    for (const targetRule of val) {
        const formattedRule = {};
        for (const key in targetRule) {
            if (!targetRule.hasOwnProperty(key)) {
                continue;
            }

            const value = targetRule[key];
            const personField = personMetadata[key];

            if (isEmpty(personField)) {
                formattedRule[key] = value;
            } else {
                const fieldFormatter = getFormatterForKind(personField.kind);
                formattedRule[key] = fieldFormatter(value, personField.kind_options, eventMetadata);
            }
        }

        formattedVal.push(formattedRule);
    }

    return formattedVal;
}

/**
 * Retrieves the appropriate formatter based on the provided kind.
 *
 * @param {string} kind - The kind of formatter to retrieve. If not provided, defaults to 'default'.
 *
 * @returns {Function} The formatter function corresponding to the provided kind, or the default formatter if the kind is not found.
 */
export function getFormatterForKind(kind) {
    return FORMATTERS[kind] || FORMATTERS[DEFAULT_FORMATTER_KIND];
}


