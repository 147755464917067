// Utils
import BaseService from './base-service';
import { get, debounce } from 'lodash';
import { remapMessages } from 'libs/utils/locales';

// Constants
import { DOC_BY_ID_ENDPOINT } from 'libs/utils/constants';

/**
 * TranslationsService class provides methods to retrieve and manage translations for events.
 *
 * @class TranslationsService
 * @extends BaseService
 */
export default class TranslationsService extends BaseService {
    constructor() {
        super();
        this.getDictionary = debounce(this.getDictionaryUncached.bind(this), 500, { leading: true });
    }

    /**
     * Retrieves the dictionary for a given event.
     *
     * @param {string} eventId - The ID of the event for which to retrieve the dictionary.
     * @param {string[]} [languages=['en']] - The languages to retrieve the dictionary for.
     * @param {number} [maxDuration=600000] - The maximum duration to keep the data cached in milliseconds.
     *
     * @returns {Promise<Object>} A promise that resolves to the dictionary data.
     */
    async getDictionaryUncached(eventId, languages = ['en'], maxDuration = 60 * 5 * 1000) {
        const url = this.buildUrl(DOC_BY_ID_ENDPOINT, { eventId, docId: 'i18n' });
        const { data } = await this.getCached(url, {}, maxDuration);
        const messages = remapMessages({}, data);
        const dictionary = {};

        for (const lang of Object.keys(messages)) {
            if (languages && !languages.includes(lang)) {
                continue;
            }

            dictionary[lang] = messages[lang];
        }

        return dictionary;
    }

    /**
     * Retrieves a translation for a given key and language from the workspace dictionary.
     * If the translation is not found, the key is returned.
     *
     * @param {string} eventId - The ID of the event.
     * @param {string} key - The key for the translation.
     * @param {string} [language=en] - The language code for the translation.
     *
     * @returns {Promise<string>} - A promise that resolves to the translation string.
     */
    async workspaceTranslation(eventId, key, language = 'en') {
        const dictionary = await this.getDictionary(eventId, [language]);
        return get(dictionary, `${language}.${key}`, key);
    }
}
