/**
 * Set of possible options to pass by when manipulating a new document
 * @typedef DocumentOptions
 *
 * @property {boolean} [keepId] whether to keep the ID or allow eventual override
 * @property {boolean} [ignoreAbsentIds] whether to allow auto IDs generation or not
 * @property {boolean} [ignoreProtected] whether to allow protected fields to be overwritten by the incoming document
 * @property {string[]} [fieldsToFillIfEmpty] set of extra field that will be populated with autogenerated ID
 * @property {boolean} [importPreviewMode] ?
 * @property {*} [user] ?
 */

/**
 * Valid options to pass to the bulk edit
 * @typedef BulkEditOptions
 *
 * @property {boolean} [add-to-existing]
 * @property {boolean} [replace-all]
 */

// Utils
import BaseService from './base-service';
import { cloneDeep, flatten, isArray, isEmpty, isObject, isString, map, partial } from 'lodash';
import { getBackstageURL } from 'libs/utils/url';
import { convertDateStringToUTCTimestamp, getBrowserTimezone } from 'libs/utils/time';
import { convertMetadataHashToOrderedList } from 'libs/utils/metadata';
import { v4 } from 'uuid';

// Constants
import { API_BASE_PATH, DOC_BY_ID_ENDPOINT } from 'libs/utils/constants';

/**
 * @const {String} BULK_ACTION_DOCS_ENDPOINT the documents manipulation API endpoint. Interpolations: `{{eventId}}`, `{{fpType}}`.
 * @private
 */
const BULK_ACTION_DOCS_ENDPOINT = `${API_BASE_PATH}/eid/{{eventId}}/data/{{fpType}}`;

/**
 * @const {String} AUTO_GENERATED_VALUE_PREFIX the autogenerated ID prefix
 * @private
 */
const AUTO_GENERATED_VALUE_PREFIX = 'bstg_autogen_';

/**
 * @const {DocumentOptions} OPTS_QUERY_PARAM_MAP mapping of opts to query string params. `null` excludes that opt from the query string.
 * @private
 */
const OPTS_QUERY_PARAM_MAP = {
    keepId: 'keep_id',
    ignoreProtected: 'ignore_protected',
    importPreviewMode: 'import_preview',
    ignoreAbsentIds: null,
    prettyFieldName: 'pretty_field_name',
    user: null // not sure why this comes through but let's consider it an ignore case
};

/**
 * @const {String} CREATE_DOC_ENDPOINT API endpoint for document creation. Interpolations: `{{eventId}}`, `{{fpType}}`.
 * @private
 */
const CREATE_DOC_ENDPOINT = `${API_BASE_PATH}/eid/{{eventId}}/doc/{{fpType}}`;

/**
 * @const {String} UPDATE_DOC_ENDPOINT API endpoint for document update. Interpolations: `{{eventId}}`, `{{fpType}}`, `{{docId}}`.
 * @private
 */
const UPDATE_DOC_ENDPOINT = `${CREATE_DOC_ENDPOINT}/{{docId}}`;

/**
 * @const {String} BULK_EDIT_ENDPOINT API endpoint for bulk update. Interpolations: `{{eventId}}`.
 * @private
 */
const BULK_EDIT_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/bulk-edit`;

/**
 * @const {String} BULK_TIME_SHIFT_ENDPOINT API endpoint for bulk time shift documents. Interpolations: `{{eventId}}`.
 * @private
 */
const BULK_TIME_SHIFT_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/time-shift`;

/**
 * @const {String} BULK_DUPLICATE_ENDPOINT API endpoint for bulk duplicate documents. Interpolations: `{{eventId}}`.
 * @private
 */
const BULK_DUPLICATE_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/duplicate`;

/**
 * @const {String} REF_CHECK_ENDPOINT Reference check endpoint. Interpolations: `{{eventId}}`.
 * @private
 */
const REF_CHECK_ENDPOINT = `${API_BASE_PATH}/checkrefs/{{eventId}}`;

/**
 * @const {String} EXCERPT_BY_TYPE_ENDPOINT API endpoint for fp-typed excerpts. Interpolations: `{{eventId}}`, `{{fpType}}`.
 * @private
 */
const EXCERPT_BY_TYPE_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/document/{{fpType}}/excerpt`;

/**
 * @const {String} DOCS_BY_TYPE_ENDPOINT API endpoint for fp-typed documents. Interpolations: `{{eventId}}`, `{{fpType}}`.
 * @private
 */
const DOCS_BY_TYPE_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/docsbytype/{{fpType}}`;

/**
 * @const {String} GET_ATTENDANCE_QRS_ENDPOINT API endpoint for attendance qrs. Interpolations: `{{eventId}}`.
 * @private
 */
const GET_ATTENDANCE_QRS_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/attendance-qrs`;

/**
 * @const {String} DELETED_DOCS_ENDPOINT API endpoint for deleted documents. Interpolations: `{{eventId}}`.
 * @private
 */
const DELETED_DOCS_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/deleted-documents`;

/**
 * @const {String} RESTORE_DOCS_ENDPOINT API endpoint for restoring documents. Interpolations: `{{eventId}}`.
 * @private
 */
const RESTORE_DOCS_ENDPOINT = `${API_BASE_PATH}/events/{{eventId}}/restore-documents`;

/**
 * @const {String} EXPORT_ENPOINTS API endpoint for exporting things. Interpolations: `{{eventId}}`, `{{exportType}}`.
 * @private
 */
const EXPORT_ENPOINTS = `${API_BASE_PATH}/events/{{eventId}}/export/{{exportType}}`;

/**
 * @const {number} MAX_DOCS_PER_PAGE the maximum number of items to load per page
 * @private
 */
const MAX_DOCS_PER_PAGE = 25;

/**
 * @const {string} DELETE_ATTACHMENT Path for attachment deletion: Interpolations: `{{eventId}}`, `{{docId}}`, `{{attachmentName}}`
 * @private
 */
const DELETE_ATTACHMENT = `${API_BASE_PATH}/events/{{eventId}}/assets/{{docId}}/{{attachmentName}}`;

/**
 * @const {string} PRINTABLE_ASSET_TYPE The fp type for the printable assets library items
 * @private
 */
const PRINTABLE_ASSET_TYPE = 'printable_asset_item';

/**
 * Provides utils for database documents management.
 *
 * @example
 * import DocumentsService from 'libs/services/documents';
 * ...
 * const documents = new DocumentsService();
 */
export default class DocumentsService extends BaseService {

    constructor() {
        super();

        this.setTimezone(getBrowserTimezone());
    }

    get MAX_DOCS_PER_PAGE() {
        return MAX_DOCS_PER_PAGE;
    }

    get PRINTABLE_ASSET_TYPE() {
        return PRINTABLE_ASSET_TYPE;
    }

    /**
     * Sets the timezone for the service instance
     *
     * @param {String} timezone the timezone to set
     */
    setTimezone(timezone) {
        this.timezone = timezone;
    }

    /**
     * Generates a valid ID
     *
     * @return {String} an autogenerated ID
     *
     * @private
     */
    generateAutoGeneratedValueString() {
        const now = Date.now().toString(16).toUpperCase();
        const rnd = Math.random().toString(16).toUpperCase().slice(2); // remove the leading '0.'

        return `${AUTO_GENERATED_VALUE_PREFIX}${now}_${rnd}`;
    }

    /**
     * Ensures that the given document has an `fp_ext_id`
     *
     * @param {Object} doc the document to ensure
     *
     * @return {Object} the enriched document
     *
     * @private
     */
    ensureDocHasFpExtId(doc) {
        return this.ensureDocHasAutoFilledValues(['fp_ext_id'], doc);
    }

    /**
     * Fills eventual missing autogen bstg IDs on the given fields.
     *
     * @param {String[]} fieldKeysToGenerateValuesFor
     * @param {Object} doc the document to ensure
     *
     * @private
     */
    ensureDocHasAutoFilledValues(fieldKeysToGenerateValuesFor, doc) {
        fieldKeysToGenerateValuesFor.forEach(fieldKey => {
            if (isEmpty(doc[fieldKey])) {
                doc[fieldKey] = this.generateAutoGeneratedValueString();
            }
        });

        return doc;
    }

    /**
     * Performs a check on document timestamps and eventually fixes the value
     *
     * @param {String} eventId the ID of the event
     * @param {String} fpType the fp_type of the document
     * @param {object} doc the document to ensure
     * @param {object[]} metadataList
     *
     * @return {Object|undefined} the given document with proper timestamps
     *
     * @private
     */
    ensureDocHasCorrectTimestamps(eventId, fpType, doc, metadataList) {
        if (!doc) {
            return;
        }

        // convert: lists of nested-object
        const listFieldDescriptors = metadataList.filter(descriptor =>
            descriptor.kind === 'list'
            && descriptor.kind_options
            && descriptor.kind_options.item_kind === 'nested-object'
        );

        for (const listField of listFieldDescriptors) {
            const field = listField.field;
            // metadata is within our field descriptor
            const nestedObjectMetadata = convertMetadataHashToOrderedList((listField.kind_options || {}).fields);

            for (const item of Array.from(doc[field] || [])) {
                this.ensureDocHasCorrectTimestamps(eventId, null, item, nestedObjectMetadata);
            }
        }

        // convert: shallow nested-object kinds
        const nestedObjectFieldDescriptors = metadataList.filter(descriptor => descriptor.kind === 'nested-object');

        for (const nestedObjectField of nestedObjectFieldDescriptors) {
            const field = nestedObjectField.field;

            if (!isObject(doc[field])) {
                continue;
            }

            // metadata is within our field descriptor
            const nestedObjectMetadata = convertMetadataHashToOrderedList((nestedObjectField.kind_options || {}).fields);

            this.ensureDocHasCorrectTimestamps(eventId, null, doc[field], nestedObjectMetadata);
        }

        // convert: shallow timestamp kinds in target object
        const timestampFieldDescriptors = metadataList.filter(descriptor => descriptor.kind === 'timestamp');
        const timestampFields = map(timestampFieldDescriptors, 'field');

        for (const field of timestampFields) {
            if (!doc[field] || !isString(doc[field]) || !doc[field].includes(':')) {
                continue;
            }

            doc[field] = convertDateStringToUTCTimestamp(doc[field], this.timezone);
        }

        return doc;
    }

    /**
     * Recursively removes all frameworks inherited properties
     * and all properties that matches garbage check.
     *
     * @param {object} doc the doc to clean
     *
     * @returns {object} the cleansed document
     *
     * @private
     */
    ensureDocHasNoUnwantedProperties(doc) {
        for (const prop in doc) {
            if (this.isForbiddenProperty(prop)) {
                delete doc[prop];

            } else if (isObject(doc[prop])) {
                this.ensureDocHasNoUnwantedProperties(doc[prop]);

            } else if (isArray(doc[prop])) {
                for (const part of doc[prop]) {
                    this.ensureDocHasNoUnwantedProperties(part);
                }
            }
        }

        return doc;
    }

    /**
     * Checks if the given property is allowed to be saved on the DB.
     *
     * @param {string} property the property key to check
     *
     * @returns {boolean} true if the property is blacklisted, false otherwise
     *
     * @private
     */
    isForbiddenProperty(property) {
        if (property === 'isSelected') return true;

        // Angular garbage
        if (property.startsWith('$$')) return true;

        return false;
    }

    /**
     * Tries to generate any permutation of query string passed to a dataloader API route
     *
     * @param {DocumentOptions} opts the options to transform to query string
     *
     * @return {String} the query string
     *
     * @private
     */
    generateOptsQuerySuffix(opts) {
        const q = new URLSearchParams();
        for (const [key, value] of Object.entries(opts)) {
            const newParamKey = OPTS_QUERY_PARAM_MAP[key];
            if (newParamKey === null) {
                continue;
            }
            q.set(newParamKey || key, value);
        }
        return `?${q.toString()}`;
    }

    /**
     * Check if protected fields have been changed
     *
     * @param {Object} originalDoc
     * @param {Object} newDoc
     * @param {Function} [stringifyFn=JSON.stringify]
     *
     * @return {Boolean}
     */
    doProtectedFieldsDiffer(originalDoc, newDoc, stringifyFn = JSON.stringify) {
        const protectedFields = originalDoc ? originalDoc.fp_protected : undefined;

        if (!isArray(protectedFields)) {
            return false;
        }

        return protectedFields.some(field => stringifyFn(originalDoc[field]) !== stringifyFn(newDoc[field]));
    }

    /**
     * Bulk create docs
     *
     * @param {String} eventId the ID of the event
     * @param {String} targetNode the node of the event
     * @param {String} fpType the fp_type of the document
     * @param {Array|Object} [docOrDocs=[]]
     * @param {DocumentOptions} [opts={}]
     *
     * @return {Promise<import('axios').AxiosResponse>} the server response
     */
    async createDocs(eventId, targetNode, fpType, docOrDocs = [], opts = {}) {
        if (!isArray(docOrDocs)) {
            docOrDocs = [docOrDocs];
        }

        if (!opts.ignoreAbsentIds) {
            docOrDocs = docOrDocs.map(this.ensureDocHasFpExtId.bind(this));
        }

        if (!opts.source || docOrDocs.length > 1) {
            opts.source = 'backstage-import';
        }

        // create copies not to mess with the UI
        docOrDocs = docOrDocs.map(cloneDeep);

        if (opts.fieldsToFillIfEmpty && opts.fieldsToFillIfEmpty.length) {
            docOrDocs = docOrDocs.map(partial(this.ensureDocHasAutoFilledValues.bind(this), opts.fieldsToFillIfEmpty));
        }

        const mdList = await this._services.metadata.getMetadataForTypeAsArray(eventId, fpType);
        docOrDocs = docOrDocs.map(doc => this.ensureDocHasCorrectTimestamps(eventId, fpType, doc, mdList));
        docOrDocs = docOrDocs.map(doc => this.ensureDocHasNoUnwantedProperties(doc));

        if (!opts.hasOwnProperty('prettyFieldName')) {
            opts.prettyFieldName = true;
        }
        const path = BULK_ACTION_DOCS_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{fpType}}', fpType);

        const url = getBackstageURL(targetNode, path, this.generateOptsQuerySuffix(opts));

        return this.post(url, { rows: docOrDocs }, { withCredentials: true });
    }

    /**
     * Update a single doc
     *
     * @param {String} eventId the ID of the event
     * @param {String} targetNode the node of the event
     * @param {String} fpType the fp_type of the document
     * @param {Object} doc the document to update
     * @param {DocumentOptions} [opts]
     *
     * @return {Promise<import('axios').AxiosResponse>} the server response
     */
    async updateDoc(eventId, targetNode, fpType, doc = {}, opts = {}) {
        if (!opts.ignoreAbsentIds) {
            doc = this.ensureDocHasFpExtId(doc);
        }
        opts.include_upserted_doc_ids = true;

        if (opts.fieldsToFillIfEmpty && opts.fieldsToFillIfEmpty.length) {
            doc = this.ensureDocHasAutoFilledValues(opts.fieldsToFillIfEmpty, doc);
        }

        const mdList = await this._services.metadata.getMetadataForTypeAsArray(eventId, fpType);
        const originalDoc = doc;
        doc = cloneDeep(doc);
        doc = this.ensureDocHasCorrectTimestamps(eventId, fpType, doc, mdList);
        doc = this.ensureDocHasNoUnwantedProperties(doc);

        let path, method;

        if (doc._id) {
            method = 'put';
            path = UPDATE_DOC_ENDPOINT
                .replace('{{eventId}}', eventId)
                .replace('{{fpType}}', fpType)
                .replace('{{docId}}', doc._id);
        } else {
            method = 'post';
            path = CREATE_DOC_ENDPOINT
                .replace('{{eventId}}', eventId)
                .replace('{{fpType}}', fpType);
        }

        const url = getBackstageURL(targetNode, path, this.generateOptsQuerySuffix(opts));

        const response = await this[method](url, { doc }, { withCredentials: true });

        if (response.data && !isEmpty(response.data.upsertedDocs)) {
            originalDoc._rev = response.data.upsertedDocs[0].rev;
        }
        return response;
    }

    /**
     * Bulk edit documents
     *
     * @param {String} eventId the ID of the event
     * @param {String} fpType the fp_type of the documents
     * @param {Object} doc the document set of modifications to apply to the documents
     * @param {BulkEditOptions} field_options
     * @param {String[]} ids the IDs of the documents to bulk update
     *
     * @return {Promise<object>} the server response
     */
    async bulkEditDocs(eventId, fpType, doc, field_options, ids) {
        if (!doc) { return; }

        const mdList = await this._services.metadata.getMetadataForTypeAsArray(eventId, fpType);
        doc = cloneDeep(doc);
        doc = this.ensureDocHasCorrectTimestamps(eventId, fpType, doc, mdList);
        doc = this.ensureDocHasNoUnwantedProperties(doc);

        const params = {
            doc_ids: ids,
            fp_type: fpType,
            changes: doc,
            field_options,
        };

        const url = BULK_EDIT_ENDPOINT.replace('{{eventId}}', eventId);

        const { data } = await this.post(url, params);
        return data;
    }

    /**
     * Time shift documents
     *
     * @param {String} eventId the ID of the event
     * @param {String} fpType the fp_type of the document
     * @param {Number} seconds number of seconds to shift the documents of
     * @param {String[]} ids the IDs of the documents to time shift update
     *
     * @return {Promise<import('axios').AxiosResponse>} the server response
     */
    timeShiftDocs(eventId, fpType, seconds, ids) {
        const params = {
            doc_ids: ids,
            fp_type: fpType,
            time_shift: seconds,
        };

        const url = BULK_TIME_SHIFT_ENDPOINT.replace('{{eventId}}', eventId);

        return this.post(url, params);
    }

    /**
     * Duplicate documents
     *
     * @param {String} eventId the ID of the event
     * @param {String} fpType the fp_type of the document
     * @param {String[]} ids the IDs of the documents to time shift update
     * @param {String} prefixLabel a string used to prefix the labels of the document titles
     *
     * @return {Promise<import('axios').AxiosResponse>} the server response
     */
    duplicateDocs(eventId, fpType, ids, prefixLabel) {
        const params = {
            doc_ids: ids,
            fp_type: fpType,
            prefix_label: prefixLabel,
        };

        const url = BULK_DUPLICATE_ENDPOINT.replace('{{eventId}}', eventId);

        return this.post(url, params);
    }

    /**
     * Delete documents
     *
     * @param {String} eventId the ID of the event
     * @param {String} targetNode the node of the event
     * @param {String} fpType the fp_type of the document
     * @param {String|Array} fpExtIdOrIds
     * @param {DocumentOptions} [opts]
     *
     * @return {Promise<import('axios').AxiosResponse>} the server response
     */
    deleteDocs(eventId, targetNode, fpType, fpExtIdOrIds, opts = {}) {
        opts.prettyFieldName = true;
        const path = BULK_ACTION_DOCS_ENDPOINT
            .replace('{{eventId}}', eventId)
            .replace('{{fpType}}', fpType);

        const url = getBackstageURL(targetNode, path, this.generateOptsQuerySuffix(opts));

        const params = {
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                fp_ext_ids: flatten([fpExtIdOrIds])
            },
            withCredentials: true
        };

        return this.delete(url, params);
    }

    /**
     * Check if external documents exists
     *
     * @param {String} eventId the ID of the event
     * @param {String[]} fpExtIds
     *
     * @return {Promise<import('axios').AxiosResponse>} the server response
     */
    checkExternalIdReferences(eventId, fpExtIds = []) {
        const url = REF_CHECK_ENDPOINT.replace('{{eventId}}', eventId);
        return this.post(url, fpExtIds);
    }

    /**
     * Get document by id, returns undefined if request fails
     *
     * @param {string} eventId
     * @param {string} docId
     * @param {boolean} [useCache=false]
     * @returns {Promise<object | undefined>}
     */
    async getDocById(eventId, docId, useCache = false) {
        const url = this.buildUrl(DOC_BY_ID_ENDPOINT, { eventId, docId });
        const method = useCache ? this.getCached : this.get;
        const { data } = await method.apply(this, [url]);
        return data;
    }

    /**
     * Loads a list of documents of the specified type
     *
     * @param {string} eventId
     * @param {string} docId
     *
     * @returns {Promise<object | undefined>}
     */
    async getAllDocsByType(eventId, fpType, params = {}) {
        const { limit = MAX_DOCS_PER_PAGE, ...restParams } = params;
        const url = this.buildUrl(DOCS_BY_TYPE_ENDPOINT, { eventId, fpType });
        const { data } = await this.get(url, { params: { limit, ...restParams } });
        return data;
    }

    /**
     * Given an fp type this methods loads the list of
     * corresponding documents.
     *
     * @param {string} eventId the ID of the event
     * @param {string} fpType the type of documents to load
     * @param {object} params
     * @param {string} [params.search] an optional search query
     * @param {number} [params.limit=MAX_DOCS_PER_PAGE] an optional limit
     * @param {string[]} [params.ids] an optional set of ids to load
     * @param {string[]} [params.fp_ext_ids] an optional set of fp ext ids to load
     *
     * @returns {Promise<object[]>} a list of the requested fp typed documents
     */
    async getDocsByType(eventId, fpType, params = {}) {
        const { limit = MAX_DOCS_PER_PAGE, ...restParams } = params;
        const url = this.buildUrl(EXCERPT_BY_TYPE_ENDPOINT, { eventId, fpType });
        const { data } = await this.postCached(url, { limit, ...restParams }, undefined, 5000);
        return data;
    }

    /**
     * Returns a paginated list of attendance qrs.
     *
     * @param {string} eventId the ID of the event
     * @param {object} params
     * @param {number} [params.page=1] the page of assets to load
     * @param {string} [params.search] an optional search query
     * @param {string[]} [params.sort] an optional sort query
     * @param {number} [params.limit=MAX_DOCS_PER_PAGE] the number to item per page to load
     *
     * @returns {Promise<object[]>} a list of attendance-qr documents
     */
    async getAttendanceQrs(eventId, params = {}) {
        const { page = 1, limit = MAX_DOCS_PER_PAGE, search, sort } = params;
        const url = this.buildUrl(GET_ATTENDANCE_QRS_ENDPOINT, { eventId });
        const { data } = await this.get(url, { params: {
            limit,
            skip: (page - 1) * limit,
            search,
            sort
        } });
        return data;
    }

    /**
     * Returns a paginated list of the workspace's deleted
     * documents.
     *
     * @param {string} eventId the ID of the event
     * @param {number} [page=1] the page of assets to load
     * @param {number} [limit=20] the number to item per page to load
     *
     * @returns {Promise<object[]>} The list of deleted documents
     */
    async listDeletedDocs(eventId, page = 1, limit = MAX_DOCS_PER_PAGE) {
        const url = this.buildUrl(DELETED_DOCS_ENDPOINT, { eventId });
        const { data } = await this.get(url, { params: {
            limit,
            skip: (page - 1) * limit
        } });
        return data;
    }

    /**
     * @param {string} eventId
     * @param {string[]} ids
     * @returns {Promise<object>}
     */
    async restoreDeletedDocs(eventId, ids) {
        const url = this.buildUrl(RESTORE_DOCS_ENDPOINT, { eventId });
        const { data } = await this.post(url, { ids });
        return data;
    }

    /**
     * @param {string} eventId
     * @param {string} exportType
     * @param {object} exportData
     * @param {string[]} [exportData.fields]
     * @returns {Promise<object>}
     */
    async exportDocs(eventId, exportType, exportData) {
        const url = this.buildUrl(EXPORT_ENPOINTS, { eventId, exportType });
        const { data } = await this.post(url, exportData);
        return data;
    }

    /**
     * Removes the given attachment from the specified document
     *
     * @param {string} eventId the ID of the event
     * @param {string} docId the ID of the document
     * @param {string} attachmentName the name of the attachment to remove
     *
     * @returns {Promise<Object>} the server response
     */
    async deleteAttachment(eventId, docId, attachmentName) {
        const url = this.buildUrl(DELETE_ATTACHMENT, { eventId, docId, attachmentName });
        const { data } = await this.delete(url);
        return data;
    }

    /**
     * Generates uuid
     * @returns {string}
     */
    generateUuid() {
        return v4();
    }
}
