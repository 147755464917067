import BaseService from './base-service';
import { imagePath } from 'libs/utils/assets';

// Constants
import { ASSET_DOWNLOAD_URL } from 'libs/utils/constants';

/**
 * The number of content per page in the content list
 * @constant {Number} CONTENT_PER_PAGE
 */
const CONTENT_PER_PAGE = 10;

/**
 * A map of supported mime types and its human readable version
 * @constant {Object} SUPPORTED_MIME_TYPES
 */
const SUPPORTED_MIME_TYPES = {
    'video/mp4': 'mp4'
};

/**
 * Base service for content library and content-hubs
 */
export default class BaseContentService extends BaseService {

    /**
     * Getter to expose CONTENT_PER_PAGE from an instantiated service.
     */
    get CONTENTS_ENDPOINT() {
        throw new Error('Not implemented');
    }

    /**
     * Getter to expose CONTENT_PER_PAGE from an instantiated service.
     */
    get CONTENT_ENDPOINT() {
        return `${this.CONTENTS_ENDPOINT}/{{contentId}}`;
    }

    /**
     * Getter to expose CONTENT_PER_PAGE from an instantiated service.
     */
    get CONTENT_THUMBNAIL_ENDPOINT() {
        return `${this.CONTENT_ENDPOINT}/thumbnail`;
    }

    /**
     * Getter to expose CONTENT_PER_PAGE from an instantiated service.
     */
    get CONTENT_PER_PAGE() {
        return CONTENT_PER_PAGE;
    }

    /**
     * Getter to expose SUPPORTED_MIME_TYPES from an instantiated service.
     */
    get SUPPORTED_MIME_TYPES() {
        return SUPPORTED_MIME_TYPES;
    }

    /**
     * Fetches content details
     *
     * @param {string} eventId the ID of the workspace
     * @param {string} contentId
     * @returns {Promise<ContentHubMedia>}
     */
    async getContentDetails(eventId, contentId) {
        const url = this.buildUrl(this.CONTENT_ENDPOINT, { eventId, contentId });
        const { data } = await this.get(url);
        return data;
    }

    /**
     * Gets the url to download an asset
     *
     * @param {string} eventId id of an event (workspace)
     * @param {string} docId id of the source doc
     * @param {string} fileName the name of the asset
     *
     * @returns {string} the download url
     */
    getDownloadUrl(eventId, docId, fileName) {
        return this.buildUrl(ASSET_DOWNLOAD_URL, { eventId, docId, fileName });
    }

    /**
     * Loads the contents for a given page for the content listing
     *
     * @param {string} eventId the ID of the workspace
     * @param {Object} queryParams which kind of content to load
     * @param {number} [queryParams.pageNumber=1] the page number we're looking to display
     * @param {'content-video'|'content-document'} [queryParams.fpType=undefined] which kind of content to load
     * @param {number} [queryParams.limit=CONTENT_PER_PAGE] how many items to load per page
     * @param {object} [queryParams.params={}]] additionak options
     *
     * @returns {Promise<{items: ContentHubMedia[], total: Number}>}
     */
    async loadPaginatedContents(eventId, {
        pageNumber = 1,
        fpType = undefined,
        limit = CONTENT_PER_PAGE,
        params = {}
    } = {}) {
        const url = this.buildUrl(this.CONTENTS_ENDPOINT, { eventId });
        const finalParams = {
            limit,
            skip: (pageNumber - 1) * limit,
            fp_type: fpType,
            ...params
        };
        const { data } = await this.get(url, { params: finalParams });
        return data;
    }

    /**
     * Update mutliple contents with the given data for a workspace
     * @param {string} eventId the ID of the workspace
     * @param {string[]} contentIds
     * @param {Object} targetData
     * @returns {Promise<Object[]>}
     */
    async updateContents(eventId, contentIds, targetData) {
        const url = this.buildUrl(this.CONTENTS_ENDPOINT, { eventId, contentId: '' });

        const { data } = await this.put(url, {
            ids: contentIds,
            properties: targetData
        });
        return data;
    }

    /**
     * Update a single content with the given data for a workspace. Return true if the update occured
     * @param {string} eventId the ID of the workspace
     * @param {string} contentId
     * @param {Object} targetData
     * @returns {Promise<Boolean>}
     */
    async updateContent(eventId, contentId, targetData) {
        const url = this.buildUrl(this.CONTENT_ENDPOINT, { eventId, contentId });

        const { data } = await this.put(url, targetData);
        return data.length && data[0].id === contentId;
    }

    /**
     * Delete a content from a workspace
     * @param {string} eventId
     * @param {string} contentId
     */
    async deleteContent(eventId, contentId) {
        const url = this.buildUrl(this.CONTENT_ENDPOINT, { eventId, contentId });
        await this.delete(url);
    }

    /**
     * Return the url to fetch the thumbnail for a given content within a workspace
     * @param {string} eventId
     * @param {string} contentId
     * @param {boolean} [withoutCache=false]
     * @returns {string} the url for that content
     */
    getContentThumbnailUrl(eventId, contentId, withoutCache = false) {
        let thumbnailUrl = this.buildUrl(this.CONTENT_THUMBNAIL_ENDPOINT, { eventId, contentId });
        if (withoutCache) {
            const fetchTime = Date.now();
            thumbnailUrl += `?rand=${fetchTime}`;
        }
        return thumbnailUrl;
    }

    /**
     * Return the url to fetch the thumbnail for a given content within a workspace. Returns the appropriate default url if needed
     * @param {string} eventId
     * @param {Object} content
     * @param {boolean} [withoutCache=false]
     * @returns {string} the url for that content
     */
    getContentThumbnailOrDefaultUrl(eventId, content, withoutCache = false) {
        if (content.has_thumbnail) {
            return this.getContentThumbnailUrl(eventId, content.id, withoutCache);
        } else {
            return imagePath(`media/${this.getTypeClass(content)}.svg`);
        }
    }

    /**
     * Given content item this method returns its type
     *
     * @param {object} item the item to get the file type of
     *
     * @returns {string} the content item file type
     */
    getTypeClass(item) {
        const type = this.SUPPORTED_MIME_TYPES[item.content_type];
        return type || 'mp4';
    }

    /**
     * Upload a thumbnail to a given content
     * @param {string} eventId
     * @param {string} contentId
     * @param {*} file
     * @returns {Promise<import('axios').AxiosResponse>} the server response
     */
    async saveContentThumbnail(eventId, contentId, file) {
        const url = this.buildUrl(this.CONTENT_THUMBNAIL_ENDPOINT, { eventId, contentId });

        const bodyFormData = new FormData();
        const token = await this.getCsrfToken();
        bodyFormData.set('file', file);
        bodyFormData.set('_csrf', token);

        return this.put(url, bodyFormData, { withCredentials: true });
    }

    /**
     * Delete the thumbnail for a given content
     * @param {string} eventId
     * @param {string} contentId
     * @returns {Promise<import('axios').AxiosResponse>} the server response
     */
    async deleteThumbnail(eventId, contentId) {
        const url = this.buildUrl(this.CONTENT_THUMBNAIL_ENDPOINT, { eventId, contentId });
        return this.delete(url);
    }

}
