"use strict";

// bsMutableTagList

var Bloodhound = window.Bloodhound;
var _require = require('src/slugify-utils'),
  reduceAccentsAndSpecialChars = _require.reduceAccentsAndSpecialChars;
var MutableTagListCtrl = /* @ngInject */function MutableTagListCtrl($scope, JSTagsCollection) {
  // Build JSTagsCollection
  $scope.tags = new JSTagsCollection();

  // Export jsTags options, including our own tags object
  $scope.jsTagOptions = _.extend({
    edit: false,
    tags: $scope.tags
  }, $scope.extraJsTagOptions);

  // Build suggestions array
  var suggestions = $scope.suggestionsList || [];
  // Instantiate the bloodhound suggestion engine

  suggestions = $scope.bloodhoundEngine = new Bloodhound(_.extend({
    datumTokenizer: function datumTokenizer(d) {
      var suggestion = d;
      suggestion = reduceAccentsAndSpecialChars(suggestion);
      return Bloodhound.tokenizers.whitespace(suggestion);
    },
    queryTokenizer: function queryTokenizer(q) {
      // ⚑
      // TODO: is being able to be über specific a good thing?
      // q = reduceAccentsAndSpecialChars(q)
      return Bloodhound.tokenizers.whitespace(q);
    },
    local: suggestions
  }, $scope.extraBloodhoundOptions));

  // Initialize the bloodhound suggestion engine
  suggestions.initialize();

  // Single suggestions dataset
  // I don't know why it has to be called example* but that seems to be baked into jsTag
  $scope.exampleData = _.extend({
    displayKey: _.identity,
    source: suggestions.ttAdapter()
  }, $scope.extraTypeaheadDataSourceOptions);

  // Typeahead options object
  return $scope.exampleOptions = _.extend({
    autoselect: true,
    // preselect first suggestion on enter
    highlight: false,
    hint: false
  }, $scope.extraTypeaheadOptions);
};
MutableTagListCtrl.$inject = ["$scope", "JSTagsCollection"];
module.exports = function () {
  return {
    restrict: 'AE',
    require: 'ngModel',
    scope: {
      eventId: '=',
      suggestionsList: '=',
      extraJsTagOptions: '=',
      extraBloodhoundOptions: '=',
      extraTypeaheadDataSourceOptions: '='
    },
    replace: false,
    // because this results in a template use conflict
    template: '<js-tag js-tag-mode=\'typeahead\' js-tag-options=\'jsTagOptions\'></js-tag>',
    controller: MutableTagListCtrl,
    link: function link(scope, elem, attrs, ngModel) {
      if (attrs.autofocus) {
        setTimeout(function () {
          return elem.find('.jt-tag-new').focus();
        }, 1000); // 1 sec
      }

      // preload the internal tag list with whatever the model contains on first load
      var isInitialTagWatchActive = true; // "once" watcher lol

      scope.$watch(function () {
        return isInitialTagWatchActive && (ngModel.$viewValue !== null ? ngModel.$viewValue.length : undefined);
      }, function (newLen) {
        isInitialTagWatchActive = false;
        if (!newLen || scope.tags.getNumberOfTags() === newLen) {
          return;
        }
        // we already got some tags yo
        return ngModel.$viewValue.forEach(scope.tags.addTag, scope.tags);
      });

      // update model when we have an updated tag list
      scope.$watch(function () {
        return JSON.stringify(scope.tags.tags);
      }, function () {
        if (!_.isNumber(scope.tags.getNumberOfTags())) {
          return;
        }
        var selectedTagPairs = _.chain(scope.tags.tags).map(function (tag, idx) {
          return [idx, tag.value];
        }).value();

        // remove disallowed tags if enabled
        if (attrs.limitSelectionsToSuggestions) {
          // eslint-disable-next-line no-constant-condition
          while (true) {
            if (selectedTagPairs.length) {
              var lastCheckedIdx;
              var iterable = _.clone(selectedTagPairs);
              var _loop = function _loop() {
                  pair = iterable[pairIdx];
                  var matched = false;
                  // "cb will always be invoked once synchronously with suggestions that were available on the client.
                  //  If those suggestions are insufficient (# of suggestions is less than limit) and remote was configured,
                  //  cb may also be invoked asynchronously"

                  scope.bloodhoundEngine.get(pair[1], function (matches) {
                    return matched = matches.some(function (match) {
                      return match === pair[1];
                    });
                  });
                  if (matched) {
                    lastCheckedIdx = pair[0];
                  } else {
                    scope.tags.removeTag(pair[0]);
                    delete selectedTagPairs[pairIdx];
                  }
                },
                pair;
              for (var pairIdx = 0; pairIdx < iterable.length; pairIdx++) {
                _loop();
              }
              selectedTagPairs = _.compact(selectedTagPairs);
              if (selectedTagPairs[0] && lastCheckedIdx === _.last(selectedTagPairs)[0]) {
                break;
              }
            } else {
              break;
            }
          }
        }

        // remove already consumed tags
        if (attrs.disallowDuplicateTagSelections) {
          var tagOccurrenceCounts = _.groupBy(selectedTagPairs, function (idxTagPair) {
            return idxTagPair[1];
          });
          _.pairs(tagOccurrenceCounts).forEach(function (tagOccurrenceCountsPair) {
            if (!((tagOccurrenceCountsPair[1] !== null ? tagOccurrenceCountsPair[1].length : undefined) > 1)) {
              return;
            }
            return _.rest(tagOccurrenceCountsPair[1]).forEach(function (idxTagPair) {
              return scope.tags.removeTag(idxTagPair[0]);
            });
          });
        }
        // set the ngModel viewValue
        return ngModel.$setViewValue(_.uniq(_.pluck(selectedTagPairs, 1)));
      });

      // apply custom input placeholders text
      if (!scope.jsTagOptions.texts) {
        scope.jsTagOptions.texts = {};
      }
      return scope.jsTagOptions.texts.inputPlaceHolder = attrs.inputPlaceholderText || '';
    }
  };
};