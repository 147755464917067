import { clone, difference, flatten, get, isArray, isDate, isNumber, isObject, isString, keys, last, memoize, toPairs, reject, sortBy, values } from 'lodash';

const moment = require('moment');
const Mustache = require('mustache');
const languages = require('src/utils/languages');
const { getFieldPlacement } = require('libs/utils/controls');

/* eslint-disable
    eqeqeq,
    no-cond-assign,
    indent,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
// Filters

let curtailStringFn, everythingAfterFn, uppercaseFirstCharFn, uppercaseFirstCharOfAllWordsFn;


// ###

let isExternalFieldKindPredicate = function(_field) {
    if ((_field != null ? _field.kind : undefined) === 'external') { return true; } else { return false; }
};

let hashCode = function(s) {
    if (!isString(s)) { s = JSON.stringify(s); }
    return s.split('').reduce(function(a, b) {
        a = ((a << 5) - a) + b.charCodeAt(0);
        return a & a;
    }
        , 0);
};

// ###

angular.module('backstage.filters', [])

// ###

    .filter('mustache', () => Mustache.render)

    .filter('reverse', () =>
        items => items.slice().reverse()
    )

    .filter('lookupLocalizedNavStringMapping', () => function(mappingStr, i18n, namespace) {
        let matches;

        if (!Array.from(mappingStr).includes('{')) { return mappingStr; }

        let activatedPersonStr = 'Your Name - Your Company';

        // ⚑
        // TODO: Temporary
        // round 0.5 - replace activatedperson with some kind of placeholder
        mappingStr = mappingStr.replace(/{{#activatedperson}}.+{{\/activatedperson}}/g, activatedPersonStr);

        // round 1 - resolve triple brace enclosed variable names
        if (matches = mappingStr.match(/{{{([^{}]+)}}}/g)) {
            matches.forEach(function(s) {
                let key = s.replace(/[{}]/g, '');
                let resolved = namespace[key];

                return mappingStr = mappingStr.replace(s, resolved);
            });
        }

        // round 2 - resolve spotme.tr keys to i18n
        if (matches = mappingStr.match(/{{#spotme\.tr}}([^{}]+){{\/spotme\.tr}}/g)) {
            matches.forEach(function(s) {
                let key = s.replace(/{{[#/]spotme\.tr}}/g, '');
                // second test is a fix for https://github.com/Spotme/pkg-spotman-menu-editor/issues/7

                if (!Array.from(key).includes('.') && !(i18n[key] != null ? i18n[key].en : undefined)) {
                    return mappingStr.replace(s, key);
                }

                const gotten = get(i18n, `${key}.en`);

                if (gotten) { return mappingStr = mappingStr.replace(s, gotten); }
            });
        }

        return mappingStr;
    })

// ###

    .filter('fieldsByPlacement', ['metadataService', metadataService =>
        memoize(function(fields, placement) {
            if (fields == null) { fields = []; }
            if (placement == null) { placement = 'left'; }
            return fields.filter(field => getFieldPlacement(field) === placement);
        },
        (fields, placement) => placement + JSON.stringify(fields || {}))
    ])

    .filter('onlyOneTargetsExceptionsField', () =>
        memoize(function(fieldsList) {
            let _isFirstTargetsExceptionsField = true;

            return fieldsList.filter(function(descriptor) {
                if ((descriptor.kind !== 'external') || ((descriptor.kind_options != null ? descriptor.kind_options.type : undefined) !== 'targets-exceptions')) { return true; }
                let ret = _isFirstTargetsExceptionsField;

                _isFirstTargetsExceptionsField = false;
                return ret;
            });
        }
            , hashCode)
    )

    .filter('onlyFieldsOfExternalKind', () =>
        memoize(function(fields) {
            if (fields == null) { fields = []; }
            return fields.filter(isExternalFieldKindPredicate);
        }
            , hashCode)
    )

    .filter('withoutFieldsOfExternalKind', () =>
        memoize(function(fields) {
            if (fields == null) { fields = []; }
            return fields.filter(_field => !isExternalFieldKindPredicate(_field));
        }
            , hashCode)
    )

    .filter('withoutHiddenFields', () =>
        function(fields) {
            if (fields == null) { fields = []; }
            if (!isArray(fields)) { return fields; }
            return fields.filter(function(field) {
                if (!isObject(field)) { return true; }
                return !field.hidden; });
        }
    )

// ###

    .filter('withoutFieldHeadingsWithExplicitArrayObjectNotation', function() {
        let FLATTENED_OBJECT_KEY_DELIM = '.';

        return function(list) {
            if (list == null) { list = []; }
            let out = [];
            let map = {};

            list.forEach(function(descriptor) {
                let title;

                if (!Array.from((title = descriptor.title)).includes(FLATTENED_OBJECT_KEY_DELIM)) {
                    map[title] = true;
                    return out.push(descriptor);
                }
                let baseTitle = title.substring(0, title.indexOf(FLATTENED_OBJECT_KEY_DELIM));

                if (map[baseTitle]) { return; }
                let newDescriptor = clone(descriptor);

                newDescriptor.originalTitle = newDescriptor.title;
                newDescriptor.title = baseTitle;
                out.push(newDescriptor);
                return map[baseTitle] = true;
            });
            return out;
        };
    })

// ###

    .filter('toFixed', () =>
        function(num, precision) {
            if (num == null) { num = 0; }
            if (precision == null) { precision = 2; }
            return num.toFixed(precision);
        }
    )

    .filter('humanize', () =>
        function(str) {
            if (!isString(str) || !str) {
                return '';
            }
            return str.replace(/[-_]/g, ' ');
        }
    )

    .filter('capitalize', () =>
        function(str) {
            if (!isString(str) || !str) { return ''; }
            return str[0].toUpperCase() + str.slice(1);
        }
    )

    .filter('limitObjectTo', () =>
        function(obj, limitTo) {
            if (obj == null) { obj = {}; }
            if (limitTo == null) { limitTo = -1; }
            if (!(limitTo > -1)) { limitTo = keys(obj).length; }
            let count = 0;

            return toPairs(obj).reduce(function(memo, pair) {
                if (count++ < limitTo) { memo[pair[0]] = pair[1]; }
                return memo;
            }
                , {});
        }
    )

    .filter('timestampToTimeString', ['timeService', timeService =>
        function(timestamp, timezone, formatStr) {
            if (!isNumber(timestamp)) { return timestamp; }
            return timeService.convertTimestampToHumanForm(timestamp, timezone, formatStr);
        }
    ])

    // for compatiblity with angular 1.2 where date strings were Date() would be converted to timestamps
    // so timestampToTimeString worked in all cases
    .filter('dateToTimeString', ['timeService', timeService =>
        function(date, formatStr) {
            if (!isDate(date)) { return date; }

            return moment(date).format(formatStr);
        }
    ])

    .filter('i18nObjectToString', () =>
        function(strOrObj) {
            if (!isObject(strOrObj) || isArray(strOrObj)) { return strOrObj; }
            let str = '';

            for (let lang in strOrObj) {
                let val = strOrObj[lang];

                if (str.length) { str += ' / '; }
                str += `${lang}: ${val}`;
            }
            return str;
        }
    )

    .filter('i18nFieldValueToString', () =>
        function(strOrObj) {
            let UNTITLED_STR = '(empty)';
            let UNTITLED_I18N_STR = '(no en translation)';

            if (!isObject(strOrObj) || isArray(strOrObj)) { // not an object?
                return strOrObj || UNTITLED_STR;
            }
            if (strOrObj.en) { return strOrObj.en; } else { return UNTITLED_I18N_STR; }
        }
    )

    .filter('humanizeLanguageCode', () => code => (languages.getName(code) || code))

    .filter('filterOutPaxWithFpStatusCancelled', () =>
        function(pax) {
            if (pax == null) { pax = []; }
            if (isArray(pax)) {
                return pax.filter(function(_row) {
                    if (_row.fp_status === 'cancelled') { return false; } else { return true; }
                });
            } else if (isObject(pax)) {
                return keys(pax).reduce(function(memo, key) {
                    if ((pax[key] != null ? pax[key].fp_status : undefined) !== 'cancelled') { memo[key] = pax[key]; }
                    return memo;
                }
                    , {});
            } else {
                throw Error('Invalid type to filter');
            }
        }
    )

    .filter('stripHtmlTagsAndConvertBreaksToNewLines', () =>
        function(html) {
            if (html == null) { html = ''; }
            if (!isString(html)) { return html; }
            return $(`<pre>${html}</pre>`).text();
        }
    )

    .filter('extractAlphaNumerics', () =>
        function(str) {
            if (str == null) { str = ''; }
            let matches = str.match(/[a-z0-9-_]/gi);

            if (isArray(matches)) { return matches.join(''); }
            return '';
        }
    )

    .filter('stripStringToAlphaNumerics', () =>
        function(str) {
            if (str == null) { str = ''; }
            return str.replace(RegExp('[^A-Z0-9]', 'gi'), '');
        }
    )

    .filter('extractMeaningfulNameFromAssetPath', () =>
        function(path, eventId) {
            if (path == null) { path = ''; }
            if ((path.indexOf(`${eventId}/`) === 0) || (path.indexOf('./') === 0) || (path.indexOf('/') === 0)) {
                return path.substring(path.lastIndexOf('/') + 1);
            }
            if (path.lastIndexOf('/') !== -1) {
                return path.substring(path.indexOf('.') + 1, path.lastIndexOf('/'));
            }
            return path;
        }
    )

    .filter('filterOutBlockedNodes', () =>
        function(nodes) {
            if (nodes == null) { nodes = {}; }
            Object.keys(nodes).forEach(function(key) {
                if ((key !== 'localhost') && !Array.from([2, 3]).includes(key != null ? key.length : undefined) && (key !== 'usadmin') && (key !== 'usprod1')) { return delete nodes[key]; }
            });
            return nodes;
        }
    )

    .filter('pathArrayToReadableForm', () =>
        function(path) {
            if (path == null) { path = []; }
            let lastSegment = last(path);
            let stripped = lastSegment.replace('-', ' ');

            return uppercaseFirstCharFn()(stripped);
        }
    )

    .filter('uppercaseFirstChar', (uppercaseFirstCharFn = () =>
        function(str) {
            if (str == null) { str = ''; }
            if (!(str != null ? str.length : undefined)) { return str; }
            return str.charAt(0).toUpperCase() + str.substring(1);
        }
    )
    )

    .filter('replace', () =>
        function(str, search, replacement) {
            if (str == null) { str = ''; }
            if (search == null) { search = ''; }
            if (replacement == null) { replacement = ''; }
            return str.replace(new RegExp(search, 'g'), replacement);
        }
    )

    .filter('uppercaseFirstCharOfAllWords', (uppercaseFirstCharOfAllWordsFn = () =>
        function(str) {
            if (str == null) { str = ''; }
            if (!(str != null ? str.length : undefined)) { return str; }
            let out = '';

            if (~str.indexOf(' ')) {
                str.split(' ').forEach(function(word, idx) {
                    if (idx > 0) { out += ' '; }
                    if ((word !== 'and') && (word !== 'an') && (word !== 'a')) {
                        return out += uppercaseFirstCharFn()(word);
                    } else {
                        return out += word;
                    }
                });
            } else {
                out = uppercaseFirstCharFn()(str);
            }
            return out;
        }
    )
    )

    .filter('pluralizeIfSeveral', () =>
        function(str, listOrCount) {
            if (listOrCount == null) { listOrCount = 0; }
            let count = isArray(listOrCount) ? listOrCount.length : listOrCount;

            if ((count > 1) || (count === 0)) { str += 's'; }
            return str;
        }
    )

    .filter('abbreviateFullUserName', function() {
        let MAX_FULLNAME_CHARS = 34;

        return function(str, maxChars) {
            if (str == null) { str = ''; }
            if (maxChars == null) { maxChars = MAX_FULLNAME_CHARS; }
            if (!(str != null ? str.length : undefined) || (str.length <= maxChars)) { return str; }
            let split = str.split(' ');

            for (let i = 0; i < split.length; i++) {
                let word = split[i];

                if (!(word != null ? word.length : undefined)) { continue; }
                split[i] = word.charAt(0).toUpperCase() + '.';
                str = split.join(' ');
                if (str.length <= maxChars) { break; }
            }
            return str;
        };
    })

    .filter('curtailFullUserName', (curtailStringFn = function() {
        let MAX_FULLNAME_CHARS = 26;

        return function(str, maxChars) {
            if (str == null) { str = ''; }
            if (maxChars == null) { maxChars = MAX_FULLNAME_CHARS; }
            if (!(str != null ? str.length : undefined) || (str.length <= maxChars)) { return str; }
            return `${str.substring(0, maxChars)} …`;
        };
    })
    )
    .filter('curtailFullEventName', curtailStringFn)
    .filter('curtailWithEllipsis', curtailStringFn)

    .filter('extractFirstNameFromFullName', () =>
        function(str) {
            if (str == null) { str = ''; }
            if (!(str != null ? str.length : undefined) || !~str.indexOf(' ')) { return str; }
            return str.split(' ')[0];
        }
    )

    .filter('eventRootInstances', () =>
        function(list) {
        // try roots first
            if (list == null) { list = []; }
            let roots =
            // ignore instances with revoked roles and non-root nodes
            // https://github.com/Spotme/backstage-app/issues/33
            reject(list, evdoc => (evdoc.instanceOf !== undefined) || (evdoc.role === 'revoked'));
            let idmap =
            roots.reduce(function(map, event) {
                map[event._id] = event;
                return map;
            }
                , {});
            // then, for eid where a root was not found, use children as roots
            let childs = difference(list, roots);

            childs.forEach(function(event) {
            // as above, ignore instances with revoked roles
                if (event.role === 'revoked') { return; }
                if (!isString(event.instanceOf) || idmap[event.instanceOf]) { return; }
                return idmap[event.instanceOf] = event;
            });
            return values(idmap);
        }
    )

    .filter('eventRootInstancesCount', ['$filter', $filter =>
        function(list) {
            if (list == null) { list = []; }
            return $filter('eventRootInstances')(list).length;
        }

    ])

    .filter('eventInstancesByPeriods', () =>
        function(list, periodOrPeriods) {
            if (list == null) { list = []; }
            if (periodOrPeriods == null) { periodOrPeriods = 'all'; }
            return flatten([periodOrPeriods])
                .reduce(function(filteredList, period) {
                    let bounds;
                    let maxtime = 8640000000000;
                    let curtime = moment().unix();

                    switch (period) {
                        case 'past':
                            bounds = {
                                // any start
                                startLower: 0,
                                startUpper: maxtime,
                                // 0 < end < curtime
                                endLower: 0,
                                endUpper: curtime
                            };
                            break;
                        case 'current':
                            bounds = {
                                // 0 < start < current
                                startLower: 0,
                                startUpper: curtime,
                                // current < end < inf
                                endLower: curtime,
                                endUpper: maxtime
                            };
                            break;
                        case 'future':
                            bounds = {
                                // current < start < inf
                                startLower: curtime,
                                startUpper: maxtime,
                                // 0 < end < inf
                                endLower: 0,
                                endUpper: maxtime
                            };
                            break;
                        default: return list;
                    }
                    let eventSetForPeriod = list.filter(function(event) {
                        let startTime;

                        if (!(startTime = event.description != null ? event.description.start_time : undefined)) {
                            if (period === 'past') { return true; }
                            return false;
                        }
                        let endTime = event.description.end_time;
                        let startCond = (startTime > bounds.startLower) && (startTime < bounds.startUpper);
                        let endCond = (endTime > bounds.endLower) && (endTime < bounds.endUpper);

                        return startCond && endCond;
                    });

                    return filteredList.concat(eventSetForPeriod);
                }
                    , []);
        }
    )

    .filter('onlyTestInstances', () =>
        function(list) {
            if (list == null) { list = []; }
            return reject(list, ev => ev.instanceOf === undefined);
        }
    )

    .filter('rejectRevokedEventPermissions', () =>
        function(list) {
            if (list == null) { list = []; }
            return reject(list, function(_perm) {
                let roleField = __guard__(_perm != null ? _perm.role : undefined, x => x.role) || (_perm != null ? _perm.role : undefined);

                return roleField === 'revoked';
            });
        }
    )

    .filter('paxTimelineActivityDocs', () =>
        list =>
            reject(list, doc => !['person', 'actrecord', 'console.log'].includes(doc.fp_type))

    )

    .filter('paxTimelineActivityIcon', ['$sce', function($sce) {
        let trust = $sce.trustAsHtml;

        return function(__unused, activityItem) {
            if (activityItem == null) { activityItem = {}; }
            switch (activityItem.fp_type) {
                case 'person': return trust('<i class="icon-edit"></i>');
                case 'actrecord': return trust('<i class="icon-tablet"></i>');
                case 'console.log': return trust('<i class="icon-envelope"></i>');
            }
        };
    }
    ])

    .filter('paxTimelineActivityToBlurb', ['$sce', function($sce) {
        let trust = $sce.trustAsHtml;

        return function(participantFullName, activityItem) {
            if (activityItem == null) { activityItem = {}; }
            let out = '';

            out += (() => { switch (activityItem.fp_type) {
            // when 'console.log' then "was #{activityItem.status} a mail<span class='hidden-phone'>: #{curtailStringFn() activityItem.message.subject, 40}</span>"
                case 'person': return trust('Participant record updated');
                case 'actrecord': return trust(`${activityItem.device_type} device activated (${activityItem.status || '?'})`);
                case 'console.log': return trust(`Mail ${activityItem.status || 'queued'}<em class='hidden-phone'>: ${curtailStringFn()(activityItem.message.subject, 60)}</em>`);
                } })();
            return out;
        };
    }
    ])

    .filter('everythingBefore', () =>
        function(str, token) {
            if (!str || !token || !~str.indexOf(token)) { return str; }
            return str.substring(0, str.indexOf(token));
        }
    )

    .filter('everythingAfter', (everythingAfterFn = () =>
        function(str, token) {
            if (!str || !token || !~str.indexOf(token)) { return str; }
            return str.substring(str.indexOf(token) + 1);
        }
    )
    )

    .filter('startsWith', () =>
        (list, matcher) =>
            list.filter(function(str) {
                if (!isString(str)) { return false; }
                return str.indexOf(matcher) === 0; })

    )

    .filter('orderHelpContentAlphaPageKeys', function() {
        let _everythingAfterFn = everythingAfterFn();

        return function(list) {
            let tok = '-'; // the splitter token

            return sortBy(list, function(key) {
                let part = _everythingAfterFn(key, '/');

                if (!(part.indexOf(tok) > -1)) { return part; }
                let slice = part.split(tok)[0];

                return parseInt(slice) || slice;
            });
        };
    })

    .filter('interpolate', ['version', version =>
        text => String(text).replace(/%VERSION%/g, version)

    ])

    .filter('appInstallLink', ['$rootScope', $rootScope =>
        code => `${$rootScope.APP_INSTALL_SHORTLINK_PREFIX}/spotme?code=${code}`
    ])

    .filter('brandedAppInstallLink', ['$rootScope', $rootScope =>
        function(brandingId, pod) {
            if (pod == null) { pod = false; }
            return `${$rootScope.APP_INSTALL_SHORTLINK_PREFIX}/${brandingId}`;
        }
    ])

    .filter('qrCodeUrl', [() =>
        function(data, size) {
            if (size == null) { size = '100x100'; }
            return `https://api.qrserver.com/v1/create-qr-code/?size=${size}&data=${encodeURIComponent(data)}`;
        }
    ])

    .filter('appStoreUrl', [() =>
        id => `https://itunes.apple.com/app/id${id}`
    ])

    .filter('googlePlayUrl', [() =>
        id => `https://play.google.com/store/apps/details?id=${id}`
    ])

/** Converts a backend time to human readable */
    .filter('timestampToHuman', () => function(timestamp) {
        return moment(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss');
    })

    .filter('duration', () => function(diff) {
        return moment.duration(diff).humanize();
    })

    .filter('username', () => function(str) {
        if (typeof str !== 'string') {
            return '';
        }

        return str.replace('org.couchdb.user:', '');
    })

    .filter('maybeStringify', () => function(obj) {
        if (typeof obj === 'string') {
            return obj;
        }

        return JSON.stringify(obj, null, 4);
    })

    .filter('bytesToHuman', () => bytes => {
        const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let size = bytes;
        let kiloByte = 1000; // SI
        let unit = -1;

        if (Math.abs(size) < kiloByte) {
            return size + ' B';
        }

        do {
            unit += 1;
            size = size / kiloByte;
        } while (Math.abs(size) > kiloByte && units[unit]);

        return size.toFixed(1) + ' ' + units[unit];
    })

    .filter('slugify', () => text => {
        text = typeof text === 'string' || text.toString ? text.toString() : '';

        return text
            .toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/[^\w-]+/g, '')
            .replace(/--+/g, '-')
            .replace(/^-+/, '')
            .replace(/-+$/, '');
    })

    .filter('eventTimeSpan', ['timeService', timeService => function(startUnix, endUnix, timezone) {
        if (!isNumber(startUnix) || !isNumber(endUnix)) {
            return startUnix + '-' + endUnix;
        }

        endUnix -= 3600;

        var start = timeService.convertTimestampToHumanForm(startUnix, timezone, 'D MMMM YYYY');
        var startSplit = start.split(' ');
        var end = timeService.convertTimestampToHumanForm(endUnix, timezone, 'D MMMM YYYY');
        var endSplit = end.split(' ');

        if (startSplit[2] !== endSplit[2]) {
            // Not equal years
            return start + '-' + end;
        } else if (startSplit[1] !== endSplit[1]) {
            // Not equal months
            return startSplit[1] + ' ' + startSplit[0] + ' - ' + endSplit[1] + ' ' + endSplit[0] + ', ' + endSplit[2];
        } else if (startSplit[0] !== endSplit[0]) {
            // Not equal days

            return startSplit[1] + ' ' + startSplit[0] + ' - ' + endSplit[0] + ', ' + startSplit[2];
        }

        // One day event
        return startSplit[1] + ' ' + startSplit[0] + ', ' + startSplit[2];
    }])

    .filter('i18n', ['i18nService', (i18nService) =>
        (key, langs) => i18nService.i18n(key, langs)
    ]);

function __guard__(value, transform) {
    return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
