"use strict";

// add default asset essence to metadataEssence registry

var AssetEssenceFactory = /* @ngInject */function AssetEssenceFactory(eventService, assetsService) {
  var eventId = eventService.getCurrentEvent()._id;
  var imageFormats = ['.png', '.jpg', '.jpeg', '.gif'];
  return {
    initForm: function initForm(metadataForTypeArray) {
      if (!metadataForTypeArray.find(function (field) {
        return field.field === 'fp_ext_id';
      })) {
        metadataForTypeArray.unshift({
          field: 'fp_ext_id',
          label: 'Unique Identifier',
          tooltip: 'This is also known as the `fp_ext_id`, no whitespaces allowed',
          validations: {
            required: true,
            pattern: {
              regex: /^[a-zA-Z0-9_\-\.]+$/
            }
          },
          placeholder: 'Inferred from filename when left blank'
        });
      }
    },
    getItemCreationVerb: function getItemCreationVerb() {
      return 'Upload';
    },
    getTooltipRepresentation: function getTooltipRepresentation(fpType, fpExtId) {
      if (imageFormats.indexOf(fpExtId) === -1) {
        return;
      }
      var url = assetsService.assetUrl(eventId, fpExtId);
      return "<img src=\"".concat(url, "\" style=\"max-width:200px\"/>");
    },
    getBatchUploadTemplateUrl: function getBatchUploadTemplateUrl() {
      return '/src/angular/components/file-management/' + 'batch-file-management/default-upload-modal.html';
    },
    getBatchUploadControllerName: function getBatchUploadControllerName() {
      return 'NewBatchDataUploadCtrl';
    },
    getPreviewTemplateUrl: function getPreviewTemplateUrl() {
      return '/static/partials/crud-views/asset-essence-preview.html';
    },
    getPreviewControllerName: function getPreviewControllerName() {
      return 'AssetEssencePreviewCtrl';
    }
  };
};
AssetEssenceFactory.$inject = ["eventService", "assetsService"];
angular.module('backstage.services').run(/* @ngInject */["blueprintService", function (blueprintService) {
  var appModule = blueprintService.lazyAppModule;
  appModule.metadataEssence('asset', AssetEssenceFactory);
}]);