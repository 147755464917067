"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
var TimeUtils = _interopRequireWildcard(require("libs/utils/time"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var DEFAULT_FORMATTING_FORMAT = 'YYYY/MM/DD HH:mm';
var TimeService = /* @ngInject */function TimeService(DEFAULT_TIMEZONE_IDENTIFIER) {
  var timeService = {
    getAllZones: TimeUtils.getAllZones,
    /**
     * Return the representation of provide unix timestamp in targeted timezone
     * @param {Number} timestamp
     * @param {String} tzIdentifier optional
     * @param {String} formatStr optional
     * @return {String}
     */
    convertTimestampToHumanForm: function convertTimestampToHumanForm(timestamp) {
      var tzIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_TIMEZONE_IDENTIFIER;
      var formatStr = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DEFAULT_FORMATTING_FORMAT;
      return TimeUtils.convertTimestampToHumanForm(timestamp, tzIdentifier, formatStr);
    },
    /**
     * In object format of timestamp fields as specificed in the metadata
     * @param {Object} obj
     * @param {Object} metadataFields
     * @param {String} tzIdentifier optional
     */
    convertTimestampsToHumanFormInObject: function convertTimestampsToHumanFormInObject(obj, metadataFields) {
      var tzIdentifier = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DEFAULT_TIMEZONE_IDENTIFIER;
      return TimeUtils.convertTimestampsToHumanFormInObject(obj, metadataFields, tzIdentifier);
    },
    /**
     * Try to convert a date as string in given timezone to unix timestamp
     * @param {String} dateStr
     * @param {String} tzIdentifier optional
     * @param {String} format optional
     * @return {Number}
     */
    convertDateStringToUTCTimestamp: function convertDateStringToUTCTimestamp(dateStr) {
      var tzIdentifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_TIMEZONE_IDENTIFIER;
      var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DEFAULT_FORMATTING_FORMAT;
      return TimeUtils.convertDateStringToUTCTimestamp(dateStr, tzIdentifier, format);
    },
    /**
     * From with given format to a long version of the date
     * @param {String} dateStr
     * @param {String} format optional
     * @return {String}
     */
    convertDateStringToFullLocaleString: function convertDateStringToFullLocaleString(dateStr) {
      var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_FORMATTING_FORMAT;
      return TimeUtils.convertDateStringToFullLocaleString(dateStr, format);
    }
  };
  return timeService;
};
TimeService.$inject = ["DEFAULT_TIMEZONE_IDENTIFIER"];
TimeService.$inject = ["DEFAULT_TIMEZONE_IDENTIFIER"];
angular.module('backstage.services').factory('timeService', TimeService);