"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
var _attachments = require("libs/utils/attachments");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var _require = require('lodash'),
  get = _require.get,
  isBoolean = _require.isBoolean,
  isEmpty = _require.isEmpty;
module.exports = /* @ngInject */["$route", "$routeParams", "$scope", "metadataService", "eventService", "bulkUpdateService", "appScriptService", "crudItems", "clipboardService", "customActionButtonsService", "essenceManager", "userService", function ($route, $routeParams, $scope, metadataService, eventService, bulkUpdateService, appScriptService, crudItems, clipboardService, customActionButtonsService, essenceManager, userService) {
  var eventId, getAssetAttachmentContentType;
  var event = eventService.decorateScope(eventId = $routeParams.eventId);
  var modelId = $routeParams.modelId;
  var currentRouteDescriptor = ($route.current || {}).$$route;
  if (!currentRouteDescriptor) {
    return;
  }
  var crudOpts = currentRouteDescriptor.crudOpts || {};
  var modelFpType = $scope.modelFpType = crudOpts.fpType;
  var modelIconClass = ((currentRouteDescriptor.blueprintMetadata || {}).module || {}).icon;
  $scope.modelIconClass = modelIconClass;
  var metadataForType = metadataService.getCachedMetadataForTypeAsArray(eventId, modelFpType);
  var allRequiredFields = metadataForType.filter(function (field) {
    return get(field, 'validations.required');
  }).map(function (field) {
    return field.field;
  });

  // add context for roles
  var _iterator = _createForOfIteratorHelper(metadataForType),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var field = _step.value;
      if (Array.isArray(field.roles)) {
        field.roles = field.roles.map(function (role) {
          if (role.orgRole) {
            return _objectSpread({
              orgId: event.ownerId
            }, role);
          } else if (role.eventRole) {
            return _objectSpread({
              eventId: eventId
            }, role);
          } else {
            return role;
          }
        });
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  metadataForType = userService.filterByRoles(metadataForType);

  // filter out fields hidden for users invited by accredited link without proper event role
  if (userService.isInvitedByLink(event)) {
    var forbiddenFieldTypes = ['external', 'nested-object', 'list', 'custom'];
    metadataForType = metadataForType.filter(function (field) {
      return !get(field, 'hideForInvited') && !(get(field, 'is_custom_field') && forbiddenFieldTypes.includes(get(field, 'kind')));
    });
  }
  var metadataFieldsToFillIfEmpty = metadataService.findFieldsWithFlag(metadataForType, 'generate_value_if_empty');

  // only let one targets or exceptions filter through because the single widget caters for both
  $scope.fields = metadataService.filterDuplicateTargetsExceptionsFields(metadataForType);
  var modelList = (crudItems || {}).data;
  // Some hack since this controller was initially designed to expect a list of items instead of a single element...
  var isArray = modelList.fp_type === undefined;
  var model = isArray ? _.findWhere(_.compact(modelList), {
    _id: modelId
  }) : modelList;
  var originalModel;
  function setModel(doc) {
    model = doc;
    $scope.model = doc;
    originalModel = _.clone(doc);
    completeSetup();
  }
  if (!model) {
    BSTG.services.documents.getDocById(eventId, modelId).then(setModel);
  } else {
    setModel(model);
  }
  function completeSetup() {
    // check if all required fields are shown to user or set in original model
    var hiddenRequiredFields = _.difference(allRequiredFields, metadataForType.map(function (f) {
      return f.field;
    }));
    $scope.hasHiddenRequiredFields = hiddenRequiredFields.some(function (field) {
      return isEmpty(originalModel[field]);
    });
    $scope.clipboard = new clipboardService($scope.model.fp_ext_id);
    $scope.customActionButtons = customActionButtonsService.init(currentRouteDescriptor.customActionButtons || {}, eventId, model);
    $scope.isActionButtonsPanelShown = !_.isEmpty($scope.customActionButtons);
    essenceManager.getPreviewElements(model).then(function (previewElements) {
      $scope.previewElements = previewElements;
    });
    $scope.saveModel = function () {
      var model = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      $scope.saveInProgress = true;

      // the main form is not perceiving the errors from the child forms
      // and therefore the submission was being done even if there were
      // validation errors. doesn't seem easy to overcome this overcomplicated
      // form, so now we are validating again for the required fields before submitting
      var requiredFields = _.chain($scope.fields).filter(function (field) {
        return get(field, 'validations.required');
      }).map(function (field) {
        return [field.field, field];
      }).object().value();
      var someAreInvalid = Object.keys(requiredFields).some(function (field) {
        if (['timestamp', 'number'].includes(requiredFields[field].kind)) {
          var _$scope$model;
          /**
           * Because of the way Rome.JS handles the input, even if kind timestamp is set,
           * a string is returned. In this case we still need to check if it's at least populated.
           *
           * @see https://github.com/bevacqua/rome
           */
          if (typeof ((_$scope$model = $scope.model) === null || _$scope$model === void 0 ? void 0 : _$scope$model[field]) === 'string') {
            return $scope.model[field].length === 0;
          } else {
            return !Number.isFinite($scope.model[field]);
          }
        } else if (requiredFields[field].kind === 'boolean') {
          return !isBoolean($scope.model[field]);
        } else {
          return isEmpty($scope.model[field]);
        }
      });
      if (!_.isEmpty($scope.form.$error) || someAreInvalid) {
        $scope.saveInProgress = false;
        $scope.setFlashMessage('The item could not be saved because some fields are invalid.', 'danger');
        return;
      }
      var ignoreProtectedFields;
      var opts = {
        fieldsToFillIfEmpty: metadataFieldsToFillIfEmpty
      };
      if (bulkUpdateService.doProtectedFieldsDiffer(originalModel, model)) {
        ignoreProtectedFields = opts.ignoreProtected = confirm('You have made changes in protected fields. ' + 'These data were set by the user. Overwrite these?');
      }
      return bulkUpdateService.updateDoc(eventId, event.node, modelFpType, model, opts).then(function (response) {
        if (_.isFunction(model.afterSave)) {
          return model.afterSave().then(function () {
            return response;
          });
        }
        if (currentRouteDescriptor.onAfterModelSaveAppScript) {
          var data = response.data;
          return appScriptService.callAppScript(currentRouteDescriptor.onAfterModelSaveAppScript, {
            model_id: !_.isEmpty(data.upsertedDocs) ? data.upsertedDocs[0].id : model._id,
            changed: !_.isEmpty(data.upsertedDocs)
          }).then(function () {
            return response;
          });
        }
        return response;
      }).then(function (response) {
        $scope.setFlashMessage('This item has been saved.');

        // take care of our view of protected field value state
        if (originalModel.fp_protected) {
          originalModel.fp_protected.forEach(function (field) {
            if (ignoreProtectedFields) {
              // update original model with new protected field values
              return originalModel[field] = model[field];
            } else {
              // restore values back to their originals
              return model[field] = originalModel[field];
            }
          });
        }
        $scope.saveInProgress = false;
        return response;
      }, function (err) {
        var message = 'The item could not be saved.';
        if (err.data && typeof err.data.error === 'string') {
          message = err.data.error;
        } else if (typeof err.error === 'string') {
          message = err.error;
        }
        var parsedErrors = message.match(/fp_ext_id:[^:]*:(.*)/);
        if (parsedErrors) {
          message = parsedErrors[1];
        }
        $scope.saveInProgress = false;
        $scope.setFlashMessage(message, 'danger');
      });
    };
    $scope.shouldShowImageAssetPreview = function (model) {
      var contentType = getAssetAttachmentContentType(model);
      var t = contentType.substring(0, contentType.indexOf('/'));
      return t === 'image';
    };
    $scope.getLabelForModel = function (model) {
      var m = model;
      var l = metadataService.getReprLabelForItem(eventId, m, true);
      return l || m.name || m.title || m.subject || m.fp_ext_id || m._id || '(untitled)';
    };
    $scope.getIdealAssetDownloadFilename = function (fpAssetPath) {
      return fpAssetPath.substring(fpAssetPath.indexOf('.') + 1, fpAssetPath.indexOf('/')) + fpAssetPath.substring(fpAssetPath.lastIndexOf('.'));
    };
    getAssetAttachmentContentType = $scope.getAssetAttachmentContentType = function (model) {
      var attachments = (0, _attachments.getAttachmentNames)(model);
      return ((0, _attachments.getAttachmentMetadata)(model, attachments[0]) || {}).content_type;
    };
  }
}];