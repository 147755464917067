"use strict";

angular.module('backstage.services').factory('orgService', /* @ngInject */["USER_QUERY_ENDPOINT", "USER_SERVICE_ENDPOINT", "ORG_SERVICE_ENDPOINT", "$q", "$rootScope", "$http", function (USER_QUERY_ENDPOINT, USER_SERVICE_ENDPOINT, ORG_SERVICE_ENDPOINT, $q, $rootScope, $http) {
  // # # # #
  // public
  // # # # #

  var orgService = {
    ORG_ID_PREFIX: 'org.couchdb.user:',
    getNameFromId: function getNameFromId(orgId) {
      return orgId.replace(orgService.ORG_ID_PREFIX, '');
    },
    getOrgIdFromOrgName: function getOrgIdFromOrgName(orgName) {
      return orgService.ORG_ID_PREFIX + orgName;
    },
    getAllOrgs: function getAllOrgs() {
      return $http.get(ORG_SERVICE_ENDPOINT, {
        withCredentials: true
      });
    },
    getOrgByName: function getOrgByName(orgName) {
      return $http.get("".concat(ORG_SERVICE_ENDPOINT, "/").concat(orgName), {
        withCredentials: true
      });
    },
    getAllOrgMemberUsers: function getAllOrgMemberUsers(orgName) {
      return $http.get("".concat(ORG_SERVICE_ENDPOINT, "/").concat(orgName, "/users"), {
        withCredentials: true
      });
    },
    getAllOrgEvents: function getAllOrgEvents(orgName) {
      return window.BSTG.services.org.getAllOrgEvents(orgName);
    },
    getAllOrgContentHubs: function getAllOrgContentHubs(orgName) {
      return window.BSTG.services.org.getAllOrgContentHubs(orgName);
    },
    getOrgMemberEmails: function getOrgMemberEmails(orgName, emailsToExclude) {
      if (!emailsToExclude) {
        emailsToExclude = [];
      }
      return orgService.getAllOrgMemberUsers(orgName).then(function (response) {
        var orgMemberEmails = _.chain(response.data).pluck('email').without(emailsToExclude).uniq().value();
        return orgMemberEmails;
      });
    },
    getAllOrgNamesForUser: function getAllOrgNamesForUser(user) {
      return _.compact(((user || {}).roles || []).map(function (permission) {
        if (!permission || permission.type !== 'account_permission') {
          return;
        }
        return permission.org_id.substring(permission.org_id.indexOf(':') + 1);
      }));
    },
    getAllOrgMemberEmailsForAllUserOrgs: function getAllOrgMemberEmailsForAllUserOrgs(user, includeCurrentUser) {
      var fetchOrgMemberEmailsPromises = orgService.getAllOrgNamesForUser(user).map(function (orgName) {
        var emailsToExclude = includeCurrentUser ? [] : [user.email];
        return orgService.getOrgMemberEmails(orgName, emailsToExclude);
      });
      return $q.all(fetchOrgMemberEmailsPromises).then(function (responses) {
        return _.uniq(Array.prototype.concat.apply([], responses));
      });
    },
    isMatchesOrgPermission: function isMatchesOrgPermission(roleToMatch) {
      var org = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return org.role === roleToMatch;
    },
    addOrgUserPermission: function addOrgUserPermission(orgId, userName, access) {
      if (!access) {
        access = 'member';
      }
      if (!_.isString(orgId)) {
        return $q.reject();
      }
      if (orgId.indexOf(orgService.ORG_ID_PREFIX) !== 0) {
        orgId = orgService.ORG_ID_PREFIX + orgId;
      }
      var descriptor = {
        id: orgId,
        access: access
      };
      return $http.post("".concat(USER_QUERY_ENDPOINT, "/").concat(userName, "/orgs"), descriptor);
    },
    toggleOrgUserPermission: function toggleOrgUserPermission(orgId, userName, access) {
      return orgService.toggleOrgUserPermissionPath(orgId, userName, access, 'toggle-permission');
    },
    toggleOrgUserPermissionPath: function toggleOrgUserPermissionPath(orgId, userName, access, path) {
      if (!_.isString(orgId)) {
        return $q.reject();
      }
      if (orgId.indexOf(orgService.ORG_ID_PREFIX) !== 0) {
        orgId = orgService.ORG_ID_PREFIX + orgId;
      }
      var descriptor = {
        id: orgId,
        access: access
      };
      return $http.post("".concat(USER_SERVICE_ENDPOINT, "/").concat(userName, "/").concat(path), descriptor, {
        withCredentials: true
      });
    },
    createOrg: function createOrg(orgParams) {
      if (!orgParams) {
        orgParams = {};
      }
      return $http.post(ORG_SERVICE_ENDPOINT, orgParams, {
        withCredentials: true
      }).then(function (_ref) {
        var dbRes = _ref.data;
        return dbRes;
      });
    },
    removeMember: function removeMember(orgName, userId) {
      var url = "".concat(ORG_SERVICE_ENDPOINT, "/").concat(orgName, "/remove-user");
      var postData = {
        user_id: userId
      };
      return $http.post(url, postData, {
        withCredentials: true
      });
    },
    clearPhone: function clearPhone(org, userId) {
      var url = "".concat(ORG_SERVICE_ENDPOINT, "/").concat(org, "/users/").concat(userId, "/phone");
      return $http.delete(url, {
        withCredentials: true
      });
    },
    getCreationOptions: function getCreationOptions(orgName, audienceType) {
      return window.BSTG.services.org.getCreationOptions(orgName, audienceType).then(
      // wrap in $q to respect angularjs digest cycle
      function (result) {
        return $q.resolve(result);
      }, function (err) {
        return $q.reject(err);
      });
    }
  };
  return orgService;
}]);