"use strict";

var _lodash = require("lodash");
var _url = require("libs/utils/url");
function _toArray(r) { return _arrayWithHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
// helpers

function getPreferredTranslation(trData, languages, key) {
  if (!trData) {
    return key;
  }
  if (!(0, _lodash.isObject)(trData) || (0, _lodash.isArray)(trData)) {
    return trData;
  }
  var langKey, tr;
  for (var n = languages.length, i = 0; i < n; i++) {
    langKey = languages[i];
    tr = trData[langKey];
    if (tr && (0, _lodash.isString)(tr)) {
      return tr;
    }
  }
  var fallback = (0, _lodash.values)(trData)[0];
  if ((0, _lodash.isString)(fallback)) {
    return fallback;
  }
  return key;
}
var I18nService = /* @ngInject */function I18nService($q, $http, $rootScope, eventService, bulkUpdateService) {
  var I18N_BIT_DOC_ID = 'i18n-i18n-bit';
  var I18N_BIT_DOC_TYPE = 'i18n-bit';
  var OVERRIDE_I18N_BIT_ID = 'bstg-i18n-bit';
  var OVERRIDE_I18N_BIT_PRIORITY = 'highest';
  var OVERRIDE_I18N_BIT_OWNER = 'private';
  var cache = {};
  var languages = ['en'];
  var I18N = {};
  var notifyOfUpdate = function notifyOfUpdate(event) {
    return i18nService.retrieveCompiledI18n(event).then(function (i18n) {
      return $rootScope.$emit('i18n:update', i18n);
    });
  };

  // # # # #
  // public
  // # # # #

  var i18nService = {
    init: function init() {
      eventService.getEvent((0, _url.getCurrentEventIdFromUrl)(), {}).then(function (event) {
        languages = event.languages;
        i18nService.retrieveCompiledI18n(event).then(function (i18n) {
          return I18N = i18n;
        });
      });
    },
    compileI18n: function compileI18n(eventId) {
      return $http.post("/api/v1/eid/".concat(eventId, "/compile/i18n"));
    },
    retrieveCompiledI18n: function retrieveCompiledI18n(event) {
      return eventService.getEventDocById(event, 'i18n').then(function (resp) {
        return resp.data;
      });
    },
    fetchOrGenerateI18nBit: function fetchOrGenerateI18nBit(event) {
      return eventService.getEventDocById(event, I18N_BIT_DOC_ID);
    },
    fetchOverrideI18nBit: function fetchOverrideI18nBit(event) {
      return eventService.getEventDocById(event, OVERRIDE_I18N_BIT_ID).then(function (data) {
        return data.data;
      }, function () {
        return {
          _id: OVERRIDE_I18N_BIT_ID,
          fp_bit_priority: OVERRIDE_I18N_BIT_PRIORITY,
          fp_owner: OVERRIDE_I18N_BIT_OWNER
        };
      });
    },
    saveI18nBit: function saveI18nBit(event, i18nBit) {
      return bulkUpdateService.updateDoc(event._id, event.node, I18N_BIT_DOC_TYPE, i18nBit, {
        keepId: true
      });
    },
    updateOverrideI18nBit: function updateOverrideI18nBit(event, i18nBit) {
      return bulkUpdateService.createDocs(event._id, event.node, I18N_BIT_DOC_TYPE, i18nBit, {
        keepId: true
      });
    },
    updateOverrideNavBitWithSingleEdit: function updateOverrideNavBitWithSingleEdit(event, i18nKey, i18nValue) {
      return i18nService.fetchOverrideI18nBit(event).then(function (i18nOverrideBit) {
        (0, _lodash.set)(i18nOverrideBit, i18nKey, i18nValue);
        return i18nService.updateOverrideI18nBit(event, i18nOverrideBit).then(function () {
          return i18nService.compileI18n(event.id);
        }).then(function () {
          return notifyOfUpdate(event);
        });
      });
    },
    transformPathSegmentsWithHyphensToUseBracketNotation: function transformPathSegmentsWithHyphensToUseBracketNotation(path) {
      // Some package use that, so it has to stay here for now.
      // convert dot.separated_paths.with-hyphen.keys to use the["bracket"]["notation"]
      var _path$split = path.split('.'),
        _path$split2 = _toArray(_path$split),
        first = _path$split2[0],
        rest = _path$split2.slice(1);
      if (rest.length === 0) {
        return "".concat(first);
      }
      return "".concat(first, "[\"").concat(rest.join('"]["'), "\"]");
    },
    getMetadataForI18nEditor: function getMetadataForI18nEditor(i18nHash) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        allowMultiline: false
      };
      var kind = options.allowMultiline ? 'text-multiline' : 'i18n-text';
      var mdList = _.keys(i18nHash).reduce(function (list, lang) {
        list.push({
          field: lang,
          label: lang.toUpperCase(),
          kind: kind
        });
        return list;
      }, []);
      if (_.isEmpty(mdList)) {
        mdList.push({
          field: 'en',
          label: 'EN',
          kind: kind
        });
      }
      return mdList;
    },
    /**
     * Translate key in current event i18n
     *
     * @param {string} key translation key
     * @param {Array} [langs] optional list of language to use
     * @return {string}
     */
    i18n: function i18n(key, langs) {
      if (!key) {
        return '';
      }
      if (!(0, _lodash.isString)(key)) {
        throw 'i18n: key should be a string';
      }
      if (!langs || !(0, _lodash.isArray)(langs)) {
        langs = languages;
      }
      var cacheKey = key + '-' + langs.join(',');
      if (cache[cacheKey]) {
        return cache[cacheKey];
      }
      var tr = (0, _lodash.get)(I18N, key);
      if (!tr) {
        return key;
      }
      cache[cacheKey] = getPreferredTranslation(tr, langs, key);
      return cache[cacheKey];
    }
  };
  return i18nService;
};
I18nService.$inject = ["$q", "$http", "$rootScope", "eventService", "bulkUpdateService"];
I18nService.$inject = ["$q", "$http", "$rootScope", "eventService", "bulkUpdateService"];
angular.module('backstage.services').factory('i18nService', I18nService);