import { SUSPENDED_ROUTE } from 'libs/utils/constants';

export default {
    modules: {
        welcome: {
            type: 'global',
            title: 'Dashboard',
            label: 'blueprint.modules.welcome',
            icon: 'icon-gauge',
            order: 1,
            tabbed: ['default', 'templates'],
            controllers: {
                default: {
                    title: 'Dashboard',
                    label: 'blueprint.modules.welcome',
                    route: '/',
                    componentName: 'EventsList',
                },
                templates: {
                    title: 'Templates',
                    label: 'blueprint.modules.templates',
                    route: '/templates',
                    componentName: 'TemplatesList',
                }
            }
        },
        organisations: {
            type: 'global',
            title: 'Organizations',
            label: 'blueprint.modules.organisations',
            icon: 'icon-suitcase',
            order: 4,
            controllers: {
                list: {
                    order: 0,
                    title: 'My memberships',
                    route: '/orgs',
                    controller: 'ListOrgsCtrl',
                    templateUrl: '/static/partials/crud-views/list-orgs.html'
                },
                view: {
                    title: 'Manage organization',
                    route: '/org/:orgName/:section?/:tab?',
                    controller: 'OrgViewCtrl',
                    templateUrl: '/static/partials/crud-views/view-org.html',
                    hideNavUnlessActive: true
                },
                create: {
                    title: 'Create an organization',
                    route: '/orgs/new',
                    controller: 'NewOrgCtrl',
                    templateUrl: '/static/partials/crud-views/create-org.html',
                    permission: 'global_actions.create_org'
                },
                entitlementsMappings: {
                    title: 'Entitlement Product Mappings',
                    route: '/orgs/entitlement/product-mappings',
                    templateUrl: '/static/partials/crud-views/entitlement-product-mappings.html',
                    permission: 'entitlements_realms.write_org_entitlements_mappings'
                }
            }
        },
        webinars: {
            type: 'global',
            title: 'Webinars',
            label: 'blueprint.modules.webinars',
            icon: 'icon-rocket',
            order: 1,
            tabbed: ['default', 'templates'],
            controllers: {
                default: {
                    order: 1,
                    title: 'Webinars',
                    label: 'blueprint.modules.webinars',
                    route: '/webinars',
                    componentName: 'WebinarsList',
                },
                templates: {
                    order: 2,
                    title: 'Templates',
                    label: 'blueprint.modules.templates',
                    route: '/webinars/templates',
                    componentName: 'WebinarTemplates',
                },
            },
            permission: 'global_actions.create_webinar',
            featureFlag: 'show_studio_express'
        },
        editTemplate: {
            type: 'module',
            category: 'webinars',
            controllers: {
                default: {
                    label: 'blueprint.modules.editTemplate',
                    route: '/webinars/templates/:templateId',
                    componentName: 'WebinarTemplateEditor',
                }
            },
            permission: 'global_actions.create_webinar',
            featureFlag: 'show_studio_express'
        },
        content_hubs: {
            type: 'global',
            title: 'Content hubs',
            label: 'blueprint.modules.content_hubs',
            icon: 'icon-folder',
            order: 3,
            controllers: {
                default: {
                    route: '/content-hubs',
                    componentName: 'ContentHubsList',
                }
            }
        },
        suspended: {
            type: 'service',
            title: 'suspended',
            label: 'suspended',
            icon: 'icon-gauge',
            order: 23,
            controllers: {
                default: {
                    route: SUSPENDED_ROUTE,
                    componentName: 'Suspended',
                    hideNavItem: true,
                }
            }
        },
        dashboard: {
            title: 'Dashboard',
            icon: 'icon-home',
            order: 1,
            controllers: {
                default: {
                    route: '/event/:eventId',
                    componentName: 'EventDashboard',
                }
            }
        },
        users: {
            type: 'global',
            title: 'Your Profile',
            label: 'blueprint.modules.users',
            icon: 'icon-user',
            order: 4,
            tabbed: ['myProfile', 'apiTokens', 'changePassword'],
            controllers: {
                myProfile: {
                    title: 'My profile',
                    label: 'blueprint.modules.users',
                    route: '/users/me',
                    componentName: 'UserProfile',
                    hideNavItem: true,
                    order: 2,
                },
                apiTokens: {
                    title: 'API Tokens',
                    label: 'blueprint.modules.apiTokens',
                    route: '/users/tokens',
                    componentName: 'APITokens',
                    hideNavItem: true,
                    order: 1,
                },
                changePassword: {
                    title: 'Change password',
                    label: 'blueprint.modules.change_password',
                    route: '/users/change-password',
                    componentName: 'ChangePassword',
                    hideNavItem: true,
                    order: 3,
                }
            }
        },
        userManagement: {
            type: 'global',
            title: 'User accounts',
            label: 'blueprint.modules.userManagement',
            icon: 'icon-group',
            order: 5,
            controllers: {
                create: {
                    title: 'Create an account',
                    route: [
                        '/users/new',
                        '/users/new/:eventId',
                        '/users/new/:eventId/:role'
                    ],
                    hideNavItem: true,
                    controller: 'NewUserCtrl',
                    templateUrl: '/static/partials/crud-views/create-user.html',
                    // autorization is managed at controller level
                    order: 1
                },
                secretAddPermission: {
                    title: 'Secret team manipulator form',
                    route: '/users/add-to-team',
                    controller: 'QuickAddEventTeamMemberCtrl',
                    templateUrl: '/static/partials/crud-views/add-user-team-permission.html',
                    permission: 'global_actions.toggle_role',
                    order: 2
                },
                manage: {
                    title: 'Backstage user roles',
                    route: '/users/manage',
                    componentName: 'UserManagement',
                    permission: 'global_actions.toggle_role',
                    order: 3
                },
            }
        },
        apps: {
            type: 'global',
            title: 'Apps',
            label: 'blueprint.modules.apps',
            icon: 'icon-mobile-phone',
            order: 8,
            permission: 'app-brandings.manage_apps',
            controllers: {
                list: {
                    order: 0,
                    title: 'My Apps',
                    route: '/apps',
                    controller: 'AppsListCtrl',
                    templateUrl: '/static/partials/apps/list-apps.html',
                    roles: [{
                        isSpotme: true
                    }, {
                        globalRole: 'app_publisher'
                    }]
                },
                edit: {
                    title: 'App details',
                    route: '/app/:id',
                    controller: 'EditAppCtrl',
                    templateUrl: '/static/partials/apps/edit.html',
                    hideNavUnlessActive: true,
                    roles: [{
                        isSpotme: true
                    }, {
                        globalRole: 'app_publisher'
                    }]
                },

                publishingRequests: {
                    title: 'Publishing requests',
                    route: '/apps/admin/requests',
                    controller: 'PublishingRequestsListCtrl',
                    templateUrl: '/static/partials/apps/list-publishing-requests.html',
                    roles: [{
                        globalRole: 'app_publisher'
                    }]
                },
                editRequest: {
                    title: 'Publishing request',
                    route: '/apps/admin/request/:id',
                    controller: 'EditPublishingRequestCtrl',
                    templateUrl: '/static/partials/app/request.html',
                    hideNavUnlessActive: true,
                    roles: [{
                        globalRole: 'app_publisher'
                    }]
                },

                publishingAccounts: {
                    title: 'Publishing accounts',
                    route: '/apps/admin/accounts',
                    controller: 'PublishingAccountsListCtrl',
                    templateUrl: '/static/partials/apps/list-publishing-accounts.html',
                    roles: [{
                        globalRole: 'app_publisher'
                    }]
                },
                editPublishingAccount: {
                    title: 'View account',
                    // Would not be needed anymore with new single init, but ...
                    // "a" in "a:id" is there because Backstage's router as soon as it sees a
                    // 32-chars long path segment in the URL assumes it's an event ID
                    // and it immediately tries to fetch an event doc... which will fail
                    // and we will be presented with a login screen
                    route: '/apps/admin/account/a:id',
                    controller: 'PublishingAccountEditCtrl',
                    templateUrl: '/static/partials/crud-views/view-generic.html',
                    hideNavUnlessActive: true,
                    roles: [{
                        globalRole: 'app_publisher'
                    }]
                },

                listPushCertificates: {
                    title: 'Push certificates',
                    route: '/apps/admin/push-certificates',
                    controller: 'PushCertificatesListCtrl',
                    templateUrl: '/static/partials/apps/list-push-certificates.html',
                    roles: [{
                        globalRole: 'app_publisher'
                    }]
                }
            }
        },

        // BASE EVENTS ROUTES

        eventTeam: {
            type: 'setting',
            order: 1,
            title: 'Team',
            label: 'blueprint.modules.eventTeam',
            controllers: {
                default: {
                    route: '/event/:eventId/team',
                    componentName: 'TeamMembers',
                }
            }
        },
        eventSettings: {
            type: 'setting',
            order: 0,
            title: 'General',
            label: 'blueprint.modules.eventSettings',
            controllers: {
                general: {
                    order: 1,
                    route: '/event/:eventId/settings',
                    title: 'General',
                    componentName: 'WorkspaceSettings',
                    routeOptions: {
                        navEditors: {
                            activation: {
                                title: 'Activation',
                                editorTitle: 'Activation settings',
                                editor: 'navActivationEditorForm',
                                navs: {
                                    actstoryboard_config: {
                                        navId: 'actstoryboard_config'
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        'document-editor': {
            title: 'Developer tools',
            icon: 'icon-database',
            controllers: {
                exportHistory: {
                    order: 6,
                    title: 'Copy History',
                    route: '/event/:eventId/copy/history',
                    controller: 'EventCopyHistory',
                    templateUrl: '/static/partials/events/event-copy/history.html'
                },
                copyHistoryDetails: {
                    order: 7,
                    title: 'Copy Report',
                    route: '/event/:eventId/copy/history/:reportId',
                    controller: 'EventCopyHistoryDetails',
                    templateUrl: '/static/partials/events/event-copy/history-details.html',
                    hideNavUnlessActive: true
                }
            },
            hideNavUnlessUserGlobalPermission: 'developer'
        },
        'metadata': {
            hideNavItem: true,
            type: 'setting',
            order: 2,
            title: 'Metadata',
            label: 'blueprint.modules.metadata',
            permission: 'node_crud_realms.modify_event_doc',
            controllers: {
                default: {
                    route: [
                        '/event/:eventId/metadata',
                        '/event/:eventId/metadata/:type'
                    ],
                    controller: 'GenericMetadataEditorCtrl',
                    templateUrl: '/static/partials/crud-views/manage-metadata-for-type.html'
                }
            }
        },
        'metadata-new': {
            type: 'setting',
            order: 2,
            title: 'Metadata',
            label: 'blueprint.modules.metadata',
            controllers: {
                default: {
                    route: [
                        '/event/:eventId/metadata-new',
                        '/event/:eventId/metadata-new/:type'
                    ],
                    componentName: 'MetadataEditor',
                }
            }
        },
        features: {
            type: 'setting',
            order: 6,
            title: 'Features',
            label: 'blueprint.modules.features',
            controllers: {
                default: {
                    title: 'Packages',
                    route: '/event/:eventId/features/packages',
                    controller: 'EventPackagesManagerCtrl',
                    templateUrl: '/static/partials/features/package-list.html',
                }
            }
        },
        accredited_links: {
            type: 'setting',
            title: 'Accredited Links',
            order: 7,
            label: 'blueprint.modules.accredited_links',
            tabbed: ['default'],
            controllers: {
                default: {
                    name: 'all',
                    label: 'blueprint.modules.accredited_links',
                    route: '/event/:eventId/permalinks',
                    componentName: 'AccreditedLinks',
                }
            }
        },
        marketplace: {
            title: 'Marketplace',
            label: 'blueprint.modules.marketplace',
            controllers: {
                default: {
                    name: 'all',
                    label: 'marketplace.categories.all',
                    route: '/event/:eventId/marketplace',
                    componentName: 'Marketplace',
                    searchParams: {}
                },
                content_and_logistics: {
                    name: 'content_and_logistics',
                    label: 'marketplace.categories.content_and_logistics',
                    route: '/event/:eventId/marketplace',
                    componentName: 'Marketplace',
                    searchParams: { category: 'content_and_logistics' }
                },
                integration: {
                    name: 'integration',
                    label: 'marketplace.categories.integration',
                    route: '/event/:eventId/marketplace',
                    componentName: 'Marketplace',
                    searchParams: { category: 'integration' }
                },
                live: {
                    name: 'live',
                    label: 'marketplace.categories.live',
                    route: '/event/:eventId/marketplace',
                    componentName: 'Marketplace',
                    searchParams: { category: 'live' }
                },
                networking: {
                    name: 'networking',
                    label: 'marketplace.categories.networking',
                    route: '/event/:eventId/marketplace',
                    componentName: 'Marketplace',
                    searchParams: { category: 'networking' }
                },
                installed: {
                    name: 'installed',
                    label: 'marketplace.categories.installed',
                    route: '/event/:eventId/marketplace',
                    componentName: 'Marketplace',
                    searchParams: { category: 'installed' }
                }
            }
        },
        'theme-editor': {
            title: 'Theme',
            icon: 'icon-palette',
            category: 'design',
            label: 'blueprint.modules.themeEditor',
            controllers: {
                theme: {
                    order: 1,
                    title: 'Theme',
                    templateUrl: '/static/partials/theme-editor/theme-editor.html',
                    controller: 'ThemeEditorCtrl'
                },
                graphics: {
                    order: 2,
                    title: 'Graphics',
                    templateUrl: '/static/partials/theme-editor/theme-assets-graphics.html',
                    controller: 'ThemeAssetsCtrl'
                },
                wizard: {
                    order: 3,
                    title: 'Wizard',
                    templateUrl: '/static/partials/theme-editor/theme-wizard.html',
                    controller: 'ThemeWizardCtrl',
                    hideNavUnlessActive: true,
                    omitNavLinkHref: true
                }
            }
        },
        'home-editor': {
            title: 'Home',
            icon: 'icon-home',
            category: 'design',
            label: 'blueprint.modules.home_editor',
            controllers: {
                home: {
                    order: 1,
                    title: 'blueprint.modules.home_editor',
                    route: '/event/:eventId/home',
                    componentName: 'GenericPreferencesEditor',
                    hideIfEmpty: true
                }
            }
        },
        support: {
            category: 'other',
            name: 'support-tools',
            permission: 'node_crud_realms.restore_deleted_docs',
            tabbed: ['default'],
            controllers: {
                default: {
                    order: 6,
                    label: 'support.deleted_docs.title',
                    route: '/event/:eventId/deleted-docs',
                    componentName: 'DeletedDocs',
                }
            }
        }
    }
};
