"use strict";

module.exports = /* @ngInject */["$routeParams", "$scope", "eventService", "metadataService", "modalService", function ($routeParams, $scope, eventService, metadataService, modalService) {
  var eventId;
  $scope.REPRESENTATION_INPUT_PLACEHOLDER_TEXT = 'e.g. {{name}}, {{location}} ({{tag}})';
  $scope.ITEM_KIND_ITEM_KINDS = ['text', 'number', 'choice-list', 'nested-object'];
  var ADD_A_CATEGORY_META_CATEGORY = $scope.ADD_A_CATEGORY_META_CATEGORY = '_ADD_A_CATEGORY_';

  // # # # #

  var event = eventService.decorateScope(eventId = $routeParams.eventId);
  $scope.allKindsOptions = metadataService.getKindOptions();
  var typeDirtyFlags = $scope.typeDirtyFlags = {};
  var metadata = $scope.metadata = metadataService.getCachedMetadata(eventId);
  var metadataTypes = $scope.metadataTypes = _.keys(metadata).filter(function (type) {
    return type.indexOf('_') !== 0 && type.indexOf('fp_') !== 0;
  });
  $scope.metadataTypeOptions = metadataService.getTypeRepresentations(metadataTypes, metadata);

  // preselect the person type nav pill by default
  var selectedType = $scope.selectedType = $routeParams.type || 'person';
  $scope.externalTypes = $scope.metadataTypeOptions.concat([{
    id: 'targets-exceptions',
    text: 'Targets/Exceptions'
  }, {
    id: 'hosted_doc',
    text: 'Hosted Doc'
  }, {
    id: 'gfx',
    text: 'Graphics (gfx)'
  }]);

  // init the list
  var metadataForTypes = $scope.metadataForTypes = {};
  var categoriesForTypes = $scope.categoriesForTypes = {};
  metadataTypes.forEach(function (type) {
    // for each type
    // load fields
    var _fields = metadataForTypes[type] = metadataService.getCachedMetadataForTypeAsArray(eventId, type);
    // load categories

    return categoriesForTypes[type] = _.uniq(_.compact(_.pluck(_fields, 'category')).concat(metadataService.FIELD_CATEGORY_CATCHALL));
  });
  var kindWatcher = function kindWatcher(descriptor) {
    return function (newKind, oldKind) {
      if (newKind !== oldKind) {
        if (newKind !== 'external' && descriptor && descriptor.kind_options) {
          delete descriptor.kind_options.ensure_referential_integrity;
        }
        descriptor.is_custom_field = true;
        descriptor.default = null;
        if (newKind === 'hidden') {
          descriptor.kind_options = descriptor.kind_options || {};
          descriptor.kind_options.hidden = false;
        } else if (oldKind === 'hidden') {
          var _descriptor$kind_opti;
          (_descriptor$kind_opti = descriptor.kind_options) === null || _descriptor$kind_opti === void 0 || delete _descriptor$kind_opti.hidden;
          if (_.isEmpty(descriptor.kind_option)) {
            delete descriptor.kind_options;
          }
        }
      }
    };
  };
  $scope.$watch('selectedType', function (newValue) {
    // we'll monitor each field's `kind` for changes
    metadataForTypes[newValue].forEach(function (descriptor) {
      if (descriptor._editable === false) {
        return;
      }
      $scope.$watch(function () {
        return descriptor.kind;
      }, kindWatcher(descriptor), true);
    });
  });

  // we use this snapshot to get the difference between the saved and current set of fields when saving
  // (to purge renamed fields in the override bit)
  var savedFieldNamesForTypes = null;
  var refreshSavedFieldNamesForTypes = function refreshSavedFieldNamesForTypes() {
    return savedFieldNamesForTypes = _.pairs(metadataForTypes).reduce(function (obj, pair) {
      obj[pair[0]] = _.pluck(pair[1], 'field');
      return obj;
    }, {});
  };
  refreshSavedFieldNamesForTypes();
  var setDirtyFlagForField = $scope.setDirtyFlagForField = function (fieldDescriptor, type) {
    if (!type) {
      return;
    }
    if (fieldDescriptor) {
      fieldDescriptor.dirty = true;
    }
    typeDirtyFlags[type] = true;
    $scope.setNavigationWarningMessage('You have unsaved changes here. Discard them?');
  };

  // attach dirty flag listeners to default props in metadata descriptors for selected type
  // this is so that changes to defaults are caught and raise a dirty flag
  metadataForTypes[selectedType].forEach(function (fieldDescriptor) {
    return $scope.$watch(function () {
      return fieldDescriptor.default;
    }, function (newDefault, oldDefault) {
      if (!oldDefault && !newDefault) {
        return;
      }
      if (_.isEqual(oldDefault, newDefault)) {
        return;
      }
      // ⚑
      // TODO: Figure out why we need these exceptions for certain kinds where we come here here unnecessarily on init
      if (fieldDescriptor.kind === 'timestamp' && (_.isUndefined(oldDefault) || oldDefault === '')) {
        return;
      }
      if (['list', 'nested-object', 'external'].includes(fieldDescriptor.kind) && _.isUndefined(oldDefault)) {
        return;
      }
      return setDirtyFlagForField(fieldDescriptor, selectedType);
    }, true);
  });

  // # # # #

  $scope.reprAtWhoOptions = {
    at: '{',
    data: _.map(angular.copy(metadataForTypes[selectedType]), function (type) {
      type.searchKey = "".concat(type.field, " ").concat(type.label);
      return type;
    }),
    searchKey: 'searchKey',
    displayTpl: '<li>${label} <small>${field}</small></li>',
    insertTpl: '${atwho-at}{${field}}}'
  };

  // # # # #

  $scope.onFieldChange = function (metadataField, type) {
    metadataField.type = type;
    // get all field names
    var fieldNames = _.chain($scope.getCategoryGroupedMetadataFieldsForType()).map(function (cat) {
      return cat.fields;
    }).flatten().map(function (field) {
      return field.field;
    }).value();
    $scope.setDirtyFlagForField(metadataField, type);
    // make sure every field is unique
    $scope.invalidMetadata = fieldNames.length !== _.uniq(fieldNames).length;
  };
  var lastCategoryGroupedMetadataFields = null;
  var resetLastCategoryGroupedMetadataFields = function resetLastCategoryGroupedMetadataFields() {
    return lastCategoryGroupedMetadataFields = null;
  };
  $scope.getCategoryGroupedMetadataFieldsForType = function () {
    if (lastCategoryGroupedMetadataFields) {
      return lastCategoryGroupedMetadataFields;
    }
    var fields = metadataService.getCategoryGroupedMetadataFields(metadataForTypes[selectedType]);
    fixOrder(fields);
    return lastCategoryGroupedMetadataFields = fields;
  };
  $scope.getKindOptionsValues = _.memoize(function (kindValues, kindValuesOrder) {
    return metadataService.getChoiceOptionsOrderedList({
      values: kindValues,
      values_order: kindValuesOrder
    }).filter(function (option) {
      return _.isString(option.label);
    }).map(function (option) {
      option.label = option.label === option.value ? option.label : "".concat(option.label, " (key='").concat(option.value, "')");
      return option;
    });
  },
  // hash function. As a new array would be returned each time,
  // memoize to avoid diggest loops
  function (kindValues, kindValuesOrder) {
    return _.chain(kindValues).keys().map(function (key) {
      return "".concat(key, "-").concat(kindValues[key], "-").concat(kindValuesOrder && kindValuesOrder[key] || '');
    }).sortBy().join(':').value();
  });
  $scope.pullCategoryUp = function (list, category, type) {
    var _previous, erstwhileField, targetField;
    if (!(_previous = category.previous)) {
      return;
    }
    if (!(targetField = _.first(category.fields))) {
      return;
    }
    if (!(erstwhileField = _.first(_previous.fields))) {
      return;
    }
    var oldOrder = targetField.order; // swap 'em

    targetField.order = erstwhileField.order;
    erstwhileField.order = oldOrder;
    setDirtyFlagForField(targetField, type);
    resetLastCategoryGroupedMetadataFields();
  };
  $scope.pushCategoryDown = function (list, category, type) {
    var _next, erstwhileField, targetField;
    if (!(_next = category.next)) {
      return;
    }
    if (!(targetField = _.first(_next.fields))) {
      return;
    }
    if (!(erstwhileField = _.first(category.fields))) {
      return;
    }
    var oldOrder = targetField.order;
    targetField.order = erstwhileField.order;
    erstwhileField.order = oldOrder;
    setDirtyFlagForField(targetField, type);
    resetLastCategoryGroupedMetadataFields();
  };
  var fixOrder = function fixOrder(list) {
    return list.sort(function (a, b) {
      return a.order - b.order;
    }).map(function (a, index) {
      a.order = index;
      return a;
    });
  };
  $scope.pullItemUp = function (list, item) {
    var places = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
    fixOrder(list);
    list = _.sortBy(list, 'order');
    var idx = list.indexOf(item);
    if (idx === 0) {
      return;
    }
    // shift up (item above gets this order slot)
    var prevItem = list[idx - places];
    var oldOrder = item.order;
    var newOrder = prevItem.order;
    item.order = newOrder;
    prevItem.order = oldOrder;
    resetLastCategoryGroupedMetadataFields();
  };
  $scope.pushItemDown = function (list, item) {
    fixOrder(list);
    list = _.sortBy(list, 'order');
    var idx = list.indexOf(item);
    if (idx === list.length - 1) {
      return;
    }
    // shift down (item below gets this order slot)
    var nextItem = list[idx + 1];
    var oldOrder = item.order;
    var newOrder = nextItem.order;
    item.order = newOrder;
    nextItem.order = oldOrder;
    resetLastCategoryGroupedMetadataFields();
  };
  $scope.editChoiceList = function (field) {
    if (!field.kind_options) {
      field.kind_options = {};
    }
    _.defaults(field.kind_options, {
      values: {},
      values_order: {}
    });
    var _field$kind_options = field.kind_options,
      values = _field$kind_options.values,
      values_order = _field$kind_options.values_order;
    var txt = metadataService.getChoiceOptionsOrderedList({
      values: values,
      values_order: values_order
    }).reduce(function (txt, option) {
      // in case there is a _to_prune
      if (!_.isString(option.label)) {
        return txt;
      }
      var line = option.label === option.value ? option.value : "".concat(option.label, " (key='").concat(option.value, "')");
      return txt ? txt + '\n' + line : line;
    }, '');
    field.textEdit = txt || ' ';
  };
  $scope.saveEditChoiceList = function (type, field) {
    var rows = field.textEdit.split('\n');
    var values = {},
      values_order = {},
      cnt = 0;
    rows.forEach(function (row) {
      var match = row.match(/^(.+?)(?:\(key='(.+)'\))?$/);
      if (match) {
        var key = (match[2] || match[1]).trim();
        var value = match[1].trim();
        values[key] = value;
        values_order[key] = cnt++;
      }
    });
    // keep the same logic as before this change, if a key is removed, then set it to null
    // if we dont do that, then a _to_prune will be addedd, and this might have some side effects.
    Object.keys(field.kind_options.values).forEach(function (key) {
      if (!(key in values)) {
        values[key] = null;
        values_order[key] = null;
      }
    });
    field.kind_options.values = values;
    field.kind_options.values_order = values_order;
    field.textEdit = null;
    typeDirtyFlags[type] = true;
  };
  $scope.discardEditChoiceList = function (field) {
    field.textEdit = null;
  };
  $scope.removeMetadataField = function (type, field) {
    if (!field) {
      return;
    }
    field.toBeDeleted = !field.toBeDeleted;
    typeDirtyFlags[type] = true;
  };
  $scope.addMetadataFieldInType = function (type) {
    var _ts;
    resetLastCategoryGroupedMetadataFields();
    var descriptor = {
      // it has a field name so that repeater tracking doesn't explode
      field: "NEW_".concat(metadataForTypes[type].length),
      created: _ts = new Date().getTime(),
      order: _ts,
      // because that'll be high
      is_custom_field: true,
      kind_options: {},
      kind: 'text'
    };
    metadataForTypes[type].push(descriptor);
    $scope.$watch(function () {
      return descriptor.kind;
    }, kindWatcher(descriptor), true);
    return typeDirtyFlags[type] = true;
  };
  $scope.showAddCategoryPromptForType = function (type) {
    var newCategoryName;
    if (!(newCategoryName = prompt('Enter the new category name'))) {
      return;
    }
    // add to categories list (to make this selectable in the dropdown)
    categoriesForTypes[type].push(newCategoryName);
    return newCategoryName;
  };
  $scope.handleCategoryChange = function (type, metadataField, newCategoryName) {
    $scope.setDirtyFlagForField(metadataField, type);
    resetLastCategoryGroupedMetadataFields();
    if (newCategoryName === ADD_A_CATEGORY_META_CATEGORY) {
      var givenCategoryName;
      if (givenCategoryName = $scope.showAddCategoryPromptForType(type)) {
        metadataField.category = givenCategoryName;
      } else {
        delete metadataField.category;
      }
    }
  };
  $scope.saveMetadata = function (type, metadataFieldList) {
    if (!typeDirtyFlags[type]) {
      return;
    }

    // delete old fields that have since been renamed during our session
    var saved = savedFieldNamesForTypes[type];
    var ours = _.pluck(metadataFieldList, 'field');
    var newFields = _.difference(ours, saved);
    var forbiddenFieldNames = ['id', '_id', 'is_team_member'];
    var fieldsWithForbiddenNames = newFields.filter(function (field) {
      return forbiddenFieldNames.includes(field);
    });
    if (fieldsWithForbiddenNames.length) {
      return modalService.openAlert({
        title: 'Unsupported field names',
        message: "Field name is forbidden to use. Please rename: ".concat(fieldsWithForbiddenNames.join(', '))
      });
    }
    var fieldsStaringWithNumber = _.filter(ours, function (field) {
      return !isNaN(parseInt(field[0], 10));
    });
    if (fieldsStaringWithNumber.length) {
      modalService.openAlert({
        title: 'Unsupported field names',
        message: "Field name cannot start with a number. Please rename: ".concat(fieldsStaringWithNumber.join(', '))
      });
      return;
    }
    var fieldsStartingWithFp = newFields.filter(function (field) {
      return field.startsWith('fp_');
    });
    if (fieldsStartingWithFp.length) {
      return modalService.openAlert({
        title: 'Unsupported field names',
        message: "Field name cannot start with \"fp_\". Please rename: ".concat(fieldsStartingWithFp.join(', '))
      });
    }
    var fieldsStartingWithUnderscore = newFields.filter(function (field) {
      return field.startsWith('_');
    });
    if (fieldsStartingWithUnderscore.length) {
      return modalService.openAlert({
        title: 'Unsupported field names',
        message: "Field name cannot start with \"_\". Please rename: ".concat(fieldsStartingWithUnderscore.join(', '))
      });
    }
    var fieldsWithInvalidChars = newFields.filter(function (field) {
      return !/^[\w\-]*$/.test(field);
    });
    if (fieldsWithInvalidChars.length) {
      return modalService.openAlert({
        title: 'Unsupported field names',
        message: "Field name cannot contain spaces or special characters. Please rename: ".concat(fieldsWithInvalidChars.join(', '))
      });
    }
    var fieldsThatCannotBePrivate = ['mailertarget1', 'mailertarget2', 'mailertarget3'];
    var ourFieldsThatCannotBePrivate = _.chain(metadataFieldList).filter(function (field) {
      return fieldsThatCannotBePrivate.includes(field.field) && field.dirty && field.crypt;
    }).pluck('field').value();
    var newFieldsThatCannotBePrivate = newFields.filter(function (field) {
      return fieldsThatCannotBePrivate.includes(field) && field.crypt;
    });
    var allFieldsThatCannotBePrivate = ourFieldsThatCannotBePrivate.concat(newFieldsThatCannotBePrivate);
    if (allFieldsThatCannotBePrivate.length) {
      return modalService.openAlert({
        title: 'Field can\'t be private',
        message: "The field ".concat(allFieldsThatCannotBePrivate.join(', '), " can't be private.")
      });
    }
    _.difference(saved, ours).forEach(function (field) {
      // this is an override so we are effectively adding the field back in a deleted state
      metadataFieldList.push({
        field: field,
        toBeDeleted: true,
        is_custom_field: true
      });
    });
    if (newFields.length) {
      _.each(newFields, function (newField) {
        var fieldToSetDefault = _.find(metadataFieldList, function (field) {
          return field.field === newField && field.kind === 'boolean' && !field.default;
        });
        // set default value to new boolean fields
        if (fieldToSetDefault) {
          fieldToSetDefault.default = false;
        }
        if (newField.kind === 'hidden') {
          newField.kind_options = newField.kind_options || {};
          newField.kind_options.hidden = false;
        }
      });
    }
    // never seve the editing data back to the DB
    metadataFieldList.forEach(function (field) {
      if (field.textEdit) {
        field.textEdit = null;
      }
    });

    // we get the existing override metadata-bit so that we can run through the diffing routine
    // prerequisites for function call: existing metadata override bit, compiled metadata
    var reprTemplates = {
      _representation: metadata[type]._representation,
      _representation_line2: metadata[type]._representation_line2,
      _representation_line3: metadata[type]._representation_line3,
      _representation_image: metadata[type]._representation_image
    };
    $scope.saveInProgress = true;
    return metadataService.saveMetadataFieldsToOverride(eventId, event.node, type, metadataFieldList, reprTemplates, metadata).then(function () {
      $scope.saveInProgress = false;
      $scope.setNavigationWarningMessage(null);
      refreshSavedFieldNamesForTypes();
      typeDirtyFlags = $scope.typeDirtyFlags = {};
      metadata = $scope.metadata = metadataService.getCachedMetadata(eventId);
      return $scope.setFlashMessage('Your changes have been saved.');
    }, function () {
      // error
      $scope.saveInProgress = false;
      return $scope.setFlashMessage('An error occurred while saving the metadata.', 'danger');
    });
  };
  $scope.isChoiceEditorSectionShownForField = function (metadataField) {
    var fieldKind = metadataField.kind;
    var fieldKindOptions = metadataField.kind_options || {};
    return fieldKind === 'choice' || fieldKind === 'choice-list' || fieldKind === 'list' && fieldKindOptions.item_kind === 'choice-list';
  };
  $scope.isExternalEditorSectionShownForField = function (metadataField) {
    return metadataField.kind === 'external';
  };
  $scope.isUngroupedCategory = function (category) {
    return category.category === 'others';
  };
  return $scope.getTrackByForField = function () {
    var field = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return field.field + field.created + field.isDeleted;
  };
}];