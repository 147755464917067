"use strict";

function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
module.exports = /* @ngInject */["$filter", function ($filter) {
  return {
    restrict: 'E',
    templateUrl: '/static/partials/apps/components/bundle-identifier-editor.html',
    scope: {
      branding: '='
    },
    link: function link(scope) {
      scope.vm = {
        model: {}
      };
      var capitalize = $filter('capitalize');
      var buildFields = function buildFields() {
        return scope.vm.fields = _.chain(scope.branding.binary_configurations).map(function (types, platform) {
          if (!_.isObject(types)) {
            return;
          }
          return _.map(types, function (config, type) {
            var key = "".concat(platform, "_").concat(type);
            var platformLabel = platform === 'ios' ? 'iOS' : platform;
            var typeLabel = type.length === 3 ? type.toUpperCase() : capitalize(type);
            if (platform === 'android' && type === 'standard') {
              typeLabel = '';
            }
            if (platform === 'ios') {
              // note: there is a special unicode character after the quotes
              // U+200C aka &zwnj; aka zero-width non-joiner, this prevents BSTG
              // from rendering 'iOS' as 'IOS'
              platformLabel = "\u200C".concat(platformLabel);
            }
            scope.vm.model[key] = config.bundle_id;
            return {
              field: "".concat(platform, "_").concat(type),
              label: "".concat(platformLabel, " ").concat(typeLabel)
            };
          });
        }).compact().flatten().value();
      };
      scope.$watch('branding', buildFields);
      scope.$watch('vm.model', function (model) {
        if (!model) {
          return;
        }
        var config = scope.branding.binary_configurations;
        return _.each(model, function (value, id) {
          var _Array$from = Array.from(id.split('_')),
            _Array$from2 = _slicedToArray(_Array$from, 2),
            platform = _Array$from2[0],
            type = _Array$from2[1];
          if (!config[platform]) {
            config[platform] = {};
          }
          if (!config[platform][type]) {
            config[platform][type] = {};
          }
          return config[platform][type] = {
            bundle_id: value
          };
        });
      }, true);
    }
  };
}];