/** @module Collections */

import { cloneDeep, isNumber, isObject, isNil } from 'lodash';
import countryList from 'country-list';

/**
 * An object that defines the order of JavaScript primitive types.
 * The keys are the names of the primitive types, and the values are their corresponding order.
 *
 * @constant {Object.<string, number>}
 * @property {number} undefined - The order value for the undefined type.
 * @property {number} boolean - The order value for the boolean type.
 * @property {number} number - The order value for the number type.
 * @property {number} bigint - The order value for the bigint type.
 * @property {number} string - The order value for the string type.
 * @property {number} object - The order value for the object type.
 * @property {number} symbol - The order value for the symbol type.
 * @property {number} function - The order value for the function type.
 */
const PRIMITIVE_TYPES_ORDER = {
    'undefined': 0,
    'boolean': 1,
    'number': 2,
    'bigint': 3,
    'string': 4,
    'object': 5,
    'symbol': 6,
    'function': 7
};

/**
 * This method compares the string value of the given members at the given key
 * with the right locale.
 *
 * @param {String} key the key of the object to compare `a` and `b` member.
 * @param {Object} a the first member to compare
 * @param {Object} b the second member to compare
 *
 * @return {Number} -1 if `a` is bigger, 1 if `a` is smaller or 0 if `a` and `b` are equal.
 */
export function localeCompareByKey(key, a, b) {
    if (!key) throw new Error('key must be specified');
    if (!a[key] && !b[key]) return 0;
    if (!a[key]) return 1;
    if (!b[key]) return -1;
    return a[key].localeCompare(b[key]);
}

/**
 * This method compares the numeric value of the given members at the given key.
 *
 * @param {String} key the key of the object to compare `a` and `b` member.
 * @param {Object} a the first member to compare
 * @param {Object} b the second member to compare
 * @param {String} [alt] an optional key to use in case of equality of the two members
 *
 * @return {Number} -1 if `a` is bigger, 1 if `a` is smaller or 0 if `a` and `b` are equal.
 */
export function compareByKey(key, a, b, alt) {
    if (a[key] === b[key]) {
        if (alt) {
            if (isNumber(a[alt])) {
                return compareByKey(alt, a, b);
            } else {
                return localeCompareByKey(alt, a, b);
            }
        } else {
            return 0;
        }
    }
    return a[key] > b[key] ? 1 : -1;
}

/**
 * This method compares the numeric or string value of the given members at the given key.
 *
 * @param {String} key the key of the object to compare `a` and `b` member.
 * @param {Object} a the first member to compare
 * @param {Object} b the second member to compare
 *
 * @return {Number} -1 if `a` is bigger, 1 if `a` is smaller or 0 if `a` and `b` are equal.
 */
export function compareByKeyTypeWise(key, a, b) {
    if (isNumber(a[key])) {
        return compareByKey(key, a, b);
    } else {
        return localeCompareByKey(key, a, b);
    }
}

/**
 * Given an object or an array of objects this methods will lookup for `order` property, and
 * returns an array of sorted elements. If `order` field is not found, then
 * the `fallbackProperty` key will be used (which by default is `title`).
 *
 * NOTE 1: if `order` field is any type besides number, then the `fallbackProperty` is used.
 * NOTE 2: if `fallbackProperty` is not present on the object, then the element will be put at the end of the collection.
 * NOTE 3: if the given collection is an object and preserveKey is true, the key of the item is stored under `__key`
 *
 * @param {Object|Object[]} collection the collection to sort
 * @param {String} [fallbackProperty='title'] an optional key to use in case of equality of the two members
 * @param {boolean} [preserveKey=false] if true and the collection is an object, the key of the object is stored under `__key`
 *
 * @return {Object[]} array of sorted items.
 */
export function sortByOrder(collection, fallbackProperty = 'title', preserveKey = false) {
    const ordered = [];
    const unordered = [];
    const unsortable = [];

    if (isObject(collection)) {
        if (preserveKey) {
            collection = cloneDeep(collection);
            Object.entries(collection).forEach(([key, value]) => value.__key = key);
        }

        collection = Object.values(collection);
    }

    for (const item of collection) {
        if (isNumber(item.order)) {
            ordered.push(item);
        } else if (item.hasOwnProperty(fallbackProperty)) {
            unordered.push(item);
        } else {
            unsortable.push(item);
        }
    }

    // Sort all modules that declare an `key`, and if they have the same
    // `key`, sort them by `alt`
    ordered.sort((a, b) => compareByKeyTypeWise('order', a, b));

    // Sort all members that do not declare any `key` by `alt`
    unordered.sort((a, b) => compareByKeyTypeWise(fallbackProperty, a, b));

    return [...ordered, ...unordered, ...unsortable];
}

/**
 * Gets the list of supported countries in a format suitable
 * for selection.
 *
 * @returns {object[]} a collection suitable for selection
 */
export function getCountryListForSelect() {
    countryList.overwrite([{
        code: 'TW',
        name: 'Taiwan'
    }]);
    return countryList.getNames()
        .map(c => ({
            identifier: c,
            text: c
        }))
        .sort((a, b) => localeCompareByKey('text', a, b));
}

/**
 * Gets the list of supported countries in a format suitable
 * for selection.
 *
 * @returns {{value:string,label:string}[]} a collection suitable for selection
 */
export function getCountryListWithCodeForSelect() {
    countryList.overwrite([{
        code: 'TW',
        name: 'Taiwan'
    }]);
    return countryList.getData()
        .map(({ code, name }) => ({
            value: code,
            label: name,
        }))
        .sort((a, b) => localeCompareByKey('label', a, b));
}

/**
 * Starting from a set of "select" values, this method
 * transforms it into valid `kind_options`
 *
 * @param {object[]} values the select option values to transform
 *
 * @returns {object[]} a collection suitable for `kind_option` values
 */
export function kindOptionsFromSelect(values) {
    return values.map(v => ({ [v.identifier]: v.text }));
}

/**
 * Sorts a collection of objects by a property of any type in ascending or descending order.
 *
 * @param {Array} collection - The collection to be sorted.
 * @param {string} property - The property to be used for sorting.
 * @param {'asc'|'desc'} [direction='asc'] - The direction of the sort, either 'asc' for ascending or 'desc' for descending.
 *
 * @returns {Array} The sorted collection.
 */
export function sortByPropOfAnyType(collection, property, direction = 'asc') {
    const sorting = direction.toLowerCase() === 'asc' ? 1 : -1;
    return collection.sort((a, b) => {
        const aValue = property && typeof a === 'object' ? a[property] : a;
        const bValue = property && typeof b === 'object' ? b[property] : b;
        return compareByAnyType(aValue, bValue) * sorting;
    });
}

/**
 * Compares two values of any type and returns a number indicating their relative order.
 *
 * @param {*} a - The first value to compare.
 * @param {*} b - The second value to compare.
 *
 * @returns {number} - Returns 0 if values are equal, -1 if `a` is less than `b`, and 1 if `a` is greater than `b`.
 *
 * @example
 * compareByAnyType(5, 10); // -1
 * compareByAnyType('apple', 'banana'); // -1
 * compareByAnyType(true, false); // -1
 * compareByAnyType(null, 'value'); // -1
 * compareByAnyType('value', undefined); // 1
 */
export function compareByAnyType(a, b) {
    if (a === b) return 0;
    if (isNil(a)) return -1;
    if (isNil(b)) return 1;

    const aType = typeof a;
    const bType = typeof b;

    if (aType === bType) {
        if (aType === 'string') return a.localeCompare(b);
        if (aType === 'number') return compareNumbers(a, b);
        if (aType === 'boolean') return a ? -1 : 1;
        if (aType === 'object') return compareObjects(a, b);
    }

    if (!PRIMITIVE_TYPES_ORDER[aType] || !PRIMITIVE_TYPES_ORDER[bType]) {
        throw new Error(`Unrecognized type: ${aType} or ${bType}`);
    }

    return (PRIMITIVE_TYPES_ORDER[aType] - PRIMITIVE_TYPES_ORDER[bType]);
}

/**
 * Compares two numbers and returns a value indicating their relative order.
 *
 * @param {number} a - The first number to compare.
 * @param {number} b - The second number to compare.
 * @returns {number} - Returns 0 if both numbers are NaN, 1 if only `a` is NaN,
 *                     -1 if only `b` is NaN, or the difference between `a` and `b`.
 */
export function compareNumbers(a, b) {
    if (isNaN(a) && isNaN(b)) return 0;
    if (isNaN(a) || a > b) return 1;
    if (isNaN(b) || a < b) return -1;

    return 0;
}

/**
 * Compares two objects of the same type and returns a number indicating their relative order.
 *
 * @param {Object} a - The first object to compare.
 * @param {Object} b - The second object to compare.
 *
 * @returns {number} - A negative number if `a` is less than `b`, zero if they are equal, or a positive number if `a` is greater than `b`.
 *
 * The comparison is based on the following rules:
 * - If both objects are of type `Date`, they are compared by their time values.
 * - If both objects are of type `Array`, they are compared by their lengths.
 * - If both objects are of type `Object`, they are compared by their keys and, if equals, their values.
 * - If the objects are of different types, they are compared by their constructor names.
 */
export function compareObjects(a, b) {
    const aClass = a.constructor.name;
    const bClass = b.constructor.name;

    if (aClass === bClass) {
        if (aClass === 'Date') return compareNumbers(a.getTime(), b.getTime());
        if (aClass === 'Array') return a.length - b.length;
        if (aClass === 'Object') {
            const aValue = JSON.stringify(a).length;
            const bValue = JSON.stringify(b).length;
            return compareNumbers(aValue, bValue);
        }
    } else {
        return aClass.localeCompare(bClass);
    }
}

/**
 * Creates an observable array that notifies a listener on mutations.
 *
 * @param {Object} params - The parameters for creating the observable array.
 * @param {Array} params.target - The target array to observe.
 * @param {Function} params.listener - The listener function to call on array mutations.
 * @returns {Proxy} A proxy object that wraps the target array and notifies the listener on mutations.
 *
 * @example
 * const array = [];
 * const listener = ({ target, value, method }) => {
 *     console.log(`Array method ${method} called with value:`, value);
 * };
 * const observableArray = createObservableArray({ target: array, listener });
 * observableArray.push(1); // Logs: Array method push called with value: 1
 */
export function createObservableArray({ target, listener }) {
    const handler = {
        get(target, prop, receiver) {
            if (!['pop', 'push', 'shift', 'splice', 'unshift'].includes(prop)) {
                return Reflect.get(target, prop, receiver);
            }

            return (value) => {
                let result = value;
                switch (prop) {
                    case 'pop':
                        result = target.pop();
                        break;
                    case 'push':
                        target.push(value);
                        break;
                    case 'shift':
                        result = target.shift();
                        break;
                    case 'splice':
                        target.splice(...value);
                        break;
                    case 'unshift':
                        target.unshift(value);
                        break;
                }

                listener({ target, value: result, method: prop });
                return result;
            };
        }
    };

    return new Proxy(target, handler);
}
